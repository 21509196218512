.invoiceSection{
  background: white;
  box-shadow: 0 0 10px rgba(1, 130,166,0.1);
  color: #043544;
  width: 49%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  padding: 15px;
  cursor: pointer;
  float: left;
  margin-bottom: 15px;
  font-size: 12px;
}
  
.invoiceItem{
  
  background: white;
  box-shadow: 0 0 10px rgba(1, 130,166,0.1);
  color: #043544;
  width: 49%;
  margin-right: 50%;
  text-align: left;
  cursor: pointer;
  float: left;
  margin-top: 5px;
  font-size: 12px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 2px;
}

.invoiceDetails{
  background: rgba(255, 255, 255, 0.2);
  color: #043544;
  width: 100%;
  text-align: left;
  font-style: italic;
  float: left;
  margin-top: 5px;
  font-size: 12px;
  padding: 10px;
  padding-top: 5px;
  border-radius: 2px;
}

.invoiceItem:hover{
  box-shadow: 0 0 3px rgba(1, 130,166,0.4);
}

#invoicesZoneTitle{
  box-shadow: 0 0 10px rgba(1, 130,166,0.1);
  color: #043544;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  width: 50%;
  margin-right: 50%;
  float: left;
  text-align: left;
}

#invoicesZoneTitleLabel{
  width: 50%;
  float: left;
}
#invoiceGraphWrapper{
  width: 100%;
  float: left;
}
#invoicesZoneTitleAction{
  width: 50%;
  text-align: right;
  padding-right: 10px;
  float: left;
}

#invoicesZoneTitle:hover{
  color: #43BA73;
  background: rgba(255, 255, 255, 0.1)
}

.invoiceInput{
  font-size: 11px;
  padding:3px;
  padding-left: 5px;
  border: 0.5px solid white;
  background: white;
  width: 100%;
  margin-bottom: 4px;
  height: 24px;
  color: #191970;
}

.invoiceName{
  width: 25%;
  float: left
}

.invoiceAmount{
  width: 25%;
  text-align: right;
  font-weight: bold;
  float: right
}

.invoiceRole{
  width: 20%;
  float: left
}

.invoiceEmail{
  width: 35%;
  float: left
}

.invoiceMobile{
  width: 15%;
  float: left
}

.invoiceInputLabel{
  
  font-style: italic;
  font-size: 11px;
  padding-top: 5px;
}

#invoiceActions{
  width: 100%;
  float: left;
  padding-top: 10px;
}

#invoiceTitle{
  width: 100%;
  float: left;
  font-style: normal;
  color: white;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}
#invoiceGraphTitle{
  width: 100%;
  float: left;
  font-style: normal;
  color: white;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 10px;
}

#invoiceDataSide{
  width: 48%;
  margin-right: 2%;
  float: left;
}

#invoiceGraphSide{
  width: 50%;
  float: left;
}

#invoiceDataWrapper{
  width: 49%;
  margin-right: 1%;
  float: left;
  background: #00253D;
  border-radius: 2px;
  color: white;
  margin-bottom: 2px;
  padding: 3px;
  font-size: 11px;
}

#invoiceDataLabel{
  font-style: italic; 
  font-weight: normal; 
  width: 70%;
  float: left;
}

#invoiceDataData{
  font-weight: bold; 
  text-align: right; 
  color: rgb(0, 140, 186);
  width: 30%;font-style: normal;
  float: left;
}

#invoiceDataWrapperMain{
  width: 49%;
  margin-right: 1%;
  float: left;
  background: #00253D;
  border-radius: 2px;
  color: white;
  margin-bottom: 2px;
  padding: 3px;
  font-size: 11px;
}


#invoiceDataLabelMain{
  font-style: normal;  
  width: 70%;
  float: left;
}

#invoiceDataDataMain{
  font-weight: bold; font-style: normal;
  text-align: right; 
  width: 30%;
  float: left;
}

.invoiceAction{
  width: 31%;
  float: left;
  margin-left: 1%;
  border: 0.5px solid rgba(67, 186, 115, 1);
  text-align: center;
  font-style: normal;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 1%
}


@media screen and (max-width: 768px) {

  .invoiceItem{
    width: 97%;
    margin-left: 1.5%;
    margin-bottom: 5px;
  } 
  #invoiceDataSide{
    width: 100%;
    margin-right: 0%;
  }
  #invoiceDataWrapper{
    width: 100%;
    margin-right: 0%;
  }
  
  #invoiceDataWrapperMain{
    width: 100%;
    margin-right: 0%;
  }
  #invoiceGraphSide{
    width: 100%;
  }
  
  

}