#payrollLargeWrapper{

  width: 70%;
  float: left;
}
#payrollSmallWrapper{

  width: 29%;
  margin-right: 1%;
  float: left;
}
#payrollDetailsTitle{
  text-align: left;
  padding-left: 10px;
  color: #FFD700;
  font-weight: normal;
  font-size: 13px;
  padding-bottom: 5px;
}
#payrollDetailsNumber{
  text-align: left;
  padding-left: 10px;
  color: #FFD700;
  font-weight: normal;
  font-size: 13px;
  padding-bottom: 5px;
  width: 10%;
  margin-right: 90%;
  float: left;
}
#payrollDetailsNumberData{
  width: 90%;
  font-size: 13px;
  padding-left: 10px;
  padding-bottom: 10px;
  text-align: left;
  float: left;
}
#payrollDetailsSubTitle{
  
  text-align: left;
  padding-left: 10px;
  color: #FFD700;
  width: 100%;
  float: left;  
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  padding-top: 5px;
}
#payrollAgent{
  background: #00253D;
  width: 100%;
  padding-top: 2.5px;
  float: left;
}
#payrollContractList{
  background: #00253D;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  float: left;
}
.payrollContractItem{
  width: 100%;
  font-weight: 300;
  font-size: 11px;
  color: rgb(0, 140, 186);
  padding-bottom: 5px;
  float: left;
}
.payrollTarget{
  width: 88%;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
}
.payrollTargetCommision{
  width: 10%;
  float: left;  
}
.payrollContractNumber{
  width: 15%;
  padding-left: 10px;
  float: left;
}
.payrollContractAmount{
  width: 12%;
  float: left;
}
.payrollContractDeal{
  width: 20%;
  float: left;
}
.payrollContractDate{
  width: 17%;
  float: left;
}
.payrollContractTitle{
  width: 100%;
  font-style: italic;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left;
}

.payrollAgentWrapper{
  width: 50%;
  text-align: left;
  padding-left: 10px;
  float: left;
}
#payrollAgentLabel{
  width: 100%;
  padding-top: 5px;
  font-style: italic;
  float: left;
}
#payrollAgentData{
  width: 100%;
  font-weight: 300;
  color: rgb(0, 140, 186);
  padding-bottom: 5px;
  float: left;
}
.fullLine{
  width: 100% !important;
}

.targetLine{
  width: 100%;
  float: left;
}