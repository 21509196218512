
#contract{
  margin-top: 0px;
}
#contractSection{
  width: 33.33%;
  float: left;
}

.contractNavigator{
  width: 100%;
  float: left;
  margin-top: 0px;
  font-size: 11px !important;
  font-weight: bold;
  color: white;
}

.alignedContract{
  margin-left: 1.5% !important;
}

.topWrapper{
  margin-top: 75px;
}

#graphWrappers{
 
  font-size: 12px;
  width: 97%;
  float: left;
  margin-left: 1%;
  margin-bottom: 0px;
}

#contractSummary{
  background: rgba(255, 255, 255, 0.15);
  width: 90%;
  height: 330px;
  border-radius: 2px;
  font-size: 12px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}

#contractShadow{
  background: rgba(255, 255, 255, 0.3);
  width: 90%;
  height: 330px;
  font-weight: 400;
  color: rgb(255,215,0);
  font-size: 25px;
  padding: 15px;
  border-radius: 3px;
  padding-top: 105px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

#contractShadow:hover{
  box-shadow: 0 0 3px rgba(255, 255,255,1);
}
#contractShadowHalf{
  background: rgba(255, 255, 255, 0.3);
  width: 90%;
  margin-bottom: 15px; 
  font-weight: normal;
  font-size: 14px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
#contractShadowHalf:hover{
  box-shadow: 0 0 3px rgba(255, 255,255,1);
  background: rgba(255, 255, 255, 0.2);
  color: rgb(255,215,0);
}

#contractBottomSection{
  width: 48%;
  float: left;
  margin-left: 1.5%;
}
#contractSubSection{
  background: rgba(255, 255, 255, 0.15);
  width: 100%;
  float: left;
  margin-top: 10px;
  text-align:left;
  padding-left: 15px;
  border-radius: 2px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: white;
  cursor: pointer
}
#contractSubSection:hover{
  background: rgba(255, 255, 255, 0.20);
}
.ActiveSubSection{
  background: rgba(255, 255, 255, 0.25) !important;
  color: rgb(255,215,0) !important;

}
#contractSelectorWrapper{
  width: 9%; 
  float: right;
}
.contractSelector{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
	border: 0.5px solid rgba(255, 255, 255, 0.3);;
  color: white;
  width: 100%;
  float: left;
  font-size: 11px;
  height: 35px;
  text-align: center;
  font-weight: normal;
}
.big{ 
  font-size: 14px;
  font-weight: normal;
 }
.cLastDate{
  margin-left: 0% !important;
  width: 7.5% !important;
}
.cStatus{
  margin-left: 0% !important;
  width: 12.5% !important;
}
.cDeals{
  margin-left: 7.5% !important;
  width: 12.5% !important;
}
.cNextDate{
  margin-left: 0% !important;
  width: 9% !important;
}

.redPictureFlag{
  border: 0.5px solid red;
  font-weight: normal;
  font-style: italic
}

#contractActionTitle{
  text-align: left;
  margin-bottom: 15px;  
  color: rgb(255,215,0);
  font-size: 16px;
}

#contractEditTitle{
  text-align: left;
  margin-bottom: 2px;  
  margin-top: 15px;
  width: 100%;
  float: left;
  color: white;
  font-size: 18px;
}
#contractEditWarning{
  text-align: left;
  margin-bottom: 2px;  
  margin-top: 5px;
  width: 100%;
  float: left;
  color: white;
  font-style: italic;
  font-size: 12px;
}
#contractWriteOffTitle{
  text-align: left;
  margin-top: 15px;  
  width: 70%;
  background: rgba(255, 255, 255, 0.9);
  float: left;
  color: rgba(220,20,60, 1);;
	border: 1px solid rgba(220,20,60, 1);
  font-size: 12px;
  font-weight: bolder;
  padding: 5px;
  padding-top: 7px;
}

#writteOff{
	background: rgba(220,20,60, 0.95);
	color: white;
  margin-top: 15px;  
	border:1px solid rgba(220,20,60, 1);
	text-align: center;
  width: 30%;
  font-size: 12px;
  padding: 5px;
  padding-top: 7px;
	cursor: pointer
}
#writteOff:hover{
	background: rgba(220,20,60, 1);
}

#unlockContract{
	background: rgba(0,139,139.95);
	color: white;
  margin-top: 15px;  
	border:1px solid rgba(0,139,139, 1);
	text-align: center;
  width: 30%;
  font-size: 12px;
  padding: 5px;
  padding-top: 7px;
	cursor: pointer
}
#unlockContract:hover{
	background: rgba(0,139,139, 1);
}

#contractStatus{
  color: rgb(255,215,0);
  width: 60%;
  float: left;
  font-size: 20px;
  text-align: left;
  margin-bottom: 0px;
}

#contractPct{
  background: rgba(255, 255, 255, 0.95);
  color: #26455A;
  font-weight: bold;
  font-size: 20px;  width: 40%;
  border-radius: 2px;
  float: left;
  margin-bottom: 7px;
  text-align: center;
}

#linkedToBox{
  color: white;
  background: rgba(255, 255, 255, 0.15);
  font-size: 14px;
  cursor: pointer;
  padding: 7px;  
  margin-top: 5px;
  font-weight: normal;
  width: 100%;
  float: left;
  margin-bottom: 5px;
  text-align: center;
}
#linkedToBox:hover{
  box-shadow: 0 0 5px rgba(255, 255,255,1);
}
#contractTitle{
  width: 100%;
  float: left;
  color: white;
  padding-top: 10px;
  text-decoration: underline;
  text-align: left;
  font-style: italic;
  font-weight: normal
}

.contractInput{
  border: 0.5px solid white;
  height: 35px;
  border-radius: 3px;
  font-size: 12px;
}

.contractXSInput{
  border: 0.5px solid white;
  height: 35px;
  width: 50%;
  border-radius: 2px;
  float: left;
  font-size: 12px;
}

#editSpace{
  width: 50%;
  float: left;
  margin-top: -15px;
  padding-bottom: 15px;
}

.contractEditInput{
  font-size: 11px;
  padding:3px;
  padding-left: 10px;
  background: white;
  border: 1px solid white;
  width: 90%;
  height: 24px;
  color: #191970;
}
#bonusEditInput{
  width: 40%;
  float: left;
  padding-bottom: 5px;
  padding-top: 15px;
}
#editUnlock{
  font-size: 11px;
  padding:3px;
  text-align: center;
  background: #1a3b51;
  border: 1px solid #1a3b51;
  width: 75%;
  margin-left: 12.5%;
  border-radius: 2px;
  cursor: pointer;
  height: 24px;
  color: white;
}
#editUnlock:hover{
  background: #50697A;
  border: 1px solid #50697A;
  color: #FFD700;
}
.editUnlockActive{
  background: white !important;
  color: #1a3b51 !important;
  font-weight: bold !important;
  border: 1px solid white !important;
}
#bonusEditLabel{
  width: 20%;
  float: left;
  padding-bottom: 5px;
  padding-top: 15px;
}
#bonusEditLabel2{
  width: 20%;
  float: left;
  padding-bottom: 15px;
  font-style: italic;
  padding-top: 20px;
}
#bonusSend{
  height: 25px;
  padding:0px;
  width: 100%;
  float: left;
  font-size: 12px;
  background: #008B8B;
  border: 1px solid transparent;
  color: white;
}
#contractEditLabel{
  width: 30%;
  float: left;
  font-style: italic;
  padding-top: 8.5px;
}
#contractFull{
  width: 100%;
  float: left;
}

#contractLabel{
  width: 28%;
  float: left;
  padding-top: 5px;
} 

#contractLabelLong{
  width: 70%;
  float: left;
}

#contractLabelShort{
  width: 7%;
  padding-top: 10px;
  font-style: italic;
  float: left;
}


#contractTeaserWrapper{
  width: 48%;
  float: left;
  margin-right: 2%;
  margin-bottom: 10px;
}

#contractTeaser{
  border-radius: 1px;
  background: rgba(255, 255, 255, 0.15);
  width: 100%;
  float: left;
  font-weight: normal;
  padding: 5px;
  font-size: 11px;
  border-radius: 3px;
  float: left;
  height: 150px;
  text-align: right;
  cursor: pointer;
}
#contractTeaser:hover{
box-shadow: 0 0 3px rgba(255, 255,255,1);
}

#cTNumber{
 width: 100%;
 float: left;
 padding: 5px;
 padding-top: 10px;
 font-weight: bold;
 background: rgba(255, 255, 255, 0.075);
 border-radius: 2px;
 margin-bottom: 5px;
 font-size: 12px;
 height: 35px;
 text-align: center;
}
#cTLabel{
  width: 35%;
  text-align: left;
  padding-top: 2px;
  font-style: italic;
  height: 20px;
  padding-left: 5px;
  float: left;
}
#cDLabel{
  width: 20%;
  text-align: left;
  padding-top: 2px;
  font-style: italic;
  height: 20px;
  padding-left: 5px;
  float: left;
}
#cTName{
  width: 65%;  
  padding-right: 5px;
  padding-top: 2px;
  height: 20px;  
  float: left;
}
#cTDeal{ 
  padding-right: 5px;
  height: 20px;
  padding-top: 2px;
  width: 80%;
  float: left;
}
#cTStatus{
  padding-right: 5px;
  height: 20px;
  padding-top: 2px;
  width: 65%;
  float: left;
}
#cTProgress{
  padding-right: 5px;
  height: 20px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.075);
  padding-top: 2px;
  width: 50%;
  margin-left: 15%;
  float: left;
}

#ctApprove{
  background: #008B8B;
  width: 48%;
  margin-left: 0.5%;
  margin-right: 1.5%;
  float: left;
  font-weight: bold;
  margin-top: 5px;
  border-radius: 2px;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
}
#ctReject{
  width: 48%;
  margin-left: 1.5%;
  
  background: rgb(220,20,60);
  float: left;
  font-weight: bold;
  border-radius: 2px;
  margin-top: 5px;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
}

.contractGraph{
  background: rgba(255, 255, 255, 0.1);
  margin-top: 0px;
}

.contractGraphWrapper{
  padding: 15px
}

.contractGraphTitle{ 
  color: white;
  background: rgba(255, 255, 255, 0.05);
  text-align: left;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important
}

#contractRejectWarning{
  width: 100%;
  float: left;
  font-size: 12px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  #editSpace{
    width: 100%;
    padding-bottom: 30px;
  }
  
  #contractTeaserWrapper{
    width: 95%;
    margin-left: 2.5%;
  }

  #contractSection{
    width: 100%;
    margin-bottom: 5px;
    float: left;
  }
  #contractBottomSection{
    width: 96%;
    margin-left: 2%;
  }
  #contractSummary{
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }

  .topWrapper{
    margin-top: 25px;
  }
}