.agentInvoiceItem{
  width: 99%;
  margin-left: 1%;
  float: left;
  text-align: left;
  padding: 5px;
  margin-top: 10px;
  font-weight: normal;
  background: rgba(255, 255, 255, 0.1);
  color: #00253D;
  font-size: 11px;
  padding-top: 10px;
}

.agentInvoiceItemTitle{
  font-size: 15px;
  margin-left: 0.5%;
  padding-left: 5px;
  width: 64%;
  float: left;
  color: white;
  padding-bottom: 5px;
}

.agentInvoiceItemStatus{
  
  font-size: 13px;
  margin-right: 0.5%;
  padding: 2px;
  font-style: italic;
  padding-right: 5px;
  width: 28%;
  float: left;
  text-align: right;
  color: white;
  margin-bottom: 5px;
}
.agentInvoiceItemDetailsL{
  font-size: 12px;
  margin-left: 0.5%;
  width: 90%;
  float: left;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.agentInvoiceWrapper{
  width: 32.33%;
  margin-right: 0.5%;
  margin-left: 0.5%;
  font-size: 11px;
  background: #00253D;
  color: white;
  margin-bottom: 5px;
  padding: 5px;
  float: left;
}
.agentInvoiceWrapperFull{
  width: 100%;
  background: #00253D;
  color: white;
  margin-bottom: 5px;
  padding: 5px;
  float: left;
}
.agentInvoiceSuperWrapper{
  width: 32.33%;
  margin-right: 0.5%;
  margin-left: 0.5%;
  float: left;
}

#noPrevItem{
  font-size: 12px;
  padding-top: 10px;
}

.itemPaid{
  background: rgba(255, 255, 255, 0.1) !important;
}

.itemToPay{
  background: rgba(255, 75, 75, 0.836) !important
}
.itemToPayData{
  color: white !important;
}
.agentInvoiceWrapperLong{
  width: 65.65%;
  margin-right: 0.5%;
  margin-left: 0.5%;
  
  background: #00253D;
  color: white;
  height: 87px;
  margin-bottom: 5px;
  padding: 5px;
  float: left;
}

.agentInvoiceItemLabel{
  font-style: italic
}
.agentInvoiceItemData{
  font-weight: normal;
	color: rgb(0, 140, 186)
}

#agentInvoiceActionWrapper{
  width: 10%;
  float: left;
  padding: 5px;
}
.agentInvoiceDelete{
  font-size: 12px;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  background: rgb(220,20,60, 0.9);
  color: white;
  width: 100%;
  margin-top: 5px;
  float: top;
}
.agentInvoiceEdit{
  font-size: 12px;
  padding: 3px;
  text-align: center;
  cursor: pointer;
  margin-top: -5px;
  border: 0.5px solid rgb(220,20,60, 0.9);
  color: white;
  width: 100%;
  float: top;
}

#agentInvoiceEditMode{
  width: 90%;
  float: left;
}
#agentInvoiceFull{
  width: 100%;
  float: left;
}

@media screen and (max-width: 768px) {

  
  
}