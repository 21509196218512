#welcome_login{
  margin-bottom:150px;
  margin-top:50px;
  width: 300px;
  height:300px;
  margin-left:auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  text-align: center;
  padding-top: 75px;
  font-size: 25px;
  color: #00253D;
  font-weight: bold;
  cursor:pointer
}
#welcome_login:hover{
  border: 0.5px solid white;
}
.welcome_img{
  margin-top:25px;
  margin-bottom:25px;
  width: 110px;
  margin-left:auto;
  margin-right: auto;
}

#welcome{
  padding-top:20px;
  font-size: 40px;
}

#successfulAction{
  border:0.5px solid white;
  color: white;
  float: left;
  padding: 10px;
  border-radius: 3px;
  width: 150px;
  margin-top: 15px;

}
#failedAction{
  border: 0.5px solid rgba(220,20,60, 1);
  color: rgba(220,20,60, 1);
  float: left;
  padding: 15px;
  text-align: center;
  margin-top: 15px;
}