#senderZone{
  padding-top: 15px;
  margin-left: 0.25%;
  width: 85%;
  float: left;
}
#senderWrapper{
  margin-left: 1.5%;
  width: 20%;
  padding-bottom: 15px;
  float: left;
}
#senderTitle{
  background: rgba(255, 255, 255, 0.1);;
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 0px;
}


#selectedMessageText{
  width: 78.5%;
  color: white;
  height: 50px;
  float: left;    
  padding: 5px;
  padding-left: 15px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 15px;
  text-align: left
}


#senderTitle:hover{
  color: rgb(255,215,0) !important;
}
.activeStep{
  background: rgba(255, 255, 255, 0.2) !important;
  color: rgb(255,215,0) !important;
}

#sendGroupMessage{
  margin-left: 1.5%;
  width: 20%; 
  height: 50px;
  line-height: 5px;
  float: left;    
  padding: 5px;
  font-size: 14px;
  background: #008B8B;
  color: white;
  border: 0.5px solid #008B8B;
  border-radius: 3px;
  float: left;
}
#sendGroupMessageStandBy{
  margin-left: 1.5%;
  width: 20%; 
  height: 50px;
  line-height: 5px;
  float: left;    
  padding: 5px;
  font-size: 14px;
  background: transparent;
  color: white;
  border: 0.5px solid white;
  border-radius: 3px;
  float: left;
}

#templateCode{

  padding: 5px;
  width: 20%;
  float: left;
}

#template{
  margin-left: 1.5%;
  width: 80%;
  margin-right: 18.5%;
  margin-bottom: 2px;
  border-radius: 3px;
  padding: 5px;
  float: left;
  text-align: left;
  font-weight: normal;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.10);
  
}
#templateText{
  padding: 5px;
  width: 75%;
  float: left;
}
#templateSelect{
 
  padding: 5px;  
  text-align: center; 
  width: 5%;
  float: left;
}
#templateSelect:hover{
  color: rgb(255,215,0);
}

#selectedMessage{
  width: 20%;
  margin-left: 1.5%;
  background: rgba(255, 255, 255, 0.2);
  color: rgb(255,215,0);
  padding: 5px;
  float: left;
  text-align: left;
  margin-bottom: 5px;
  border-radius: 3px;
  padding-left: 10px;
  font-weight: 300;
  font-size: 12px;
}

#selectedFilter{
  width: 90%;
  margin-left: 1.5%;
  padding-left: 5px;
  float: left;
  text-align: left
}

#selectClientsSection{
  width: 97%;
  margin-left: 1.5%;
  float: left;
  padding-top:15px;
  padding-bottom: 15px;
}

@media screen and (max-width: 768px) {
  #senderWrapper{
    width: 47%;
  }
  #senderZone{
    margin-left: 0%;
    width: 100%;
}

}