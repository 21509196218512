.agentTargetInputLabel{
  margin-top: 10px;
  font-weight: normal;
  font-style: italic;
  width: 25%;
  float: left;
  font-size: 12px;
  margin-bottom: 5px;
}
#targetWarning{
  width: 100%;
  float: left;
  font-style: italic;
  color: white;
  text-align: left;
  font-size: 11px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: normal;
  padding-left: 5px;
}
#agentTargetOption{
  margin-top: 0px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  font-weight: normal;
  margin-right: 1%;
  border: 0.25px solid white;
  font-style: italic;
  width: 24%;
  float: left;
  font-size: 11px;
  margin-bottom: 5px;
}
#agentTargetEmptyOption{
  margin-top: 0px;
  padding: 10px;
  text-align: center;
  font-weight: normal;
  margin-right: 1%;
  border: 0.25px solid transparent;
  font-style: italic;
  width: 24%;
  float: left;
  font-size: 11px;
  margin-bottom: 5px;
}
.last{
  margin-right: 24% !important;
}
.activeTOption{
  color: rgba(255,215,0, 1) !important;
  background: rgba(255, 255, 255, 0.1);
  border: 0.25px solid rgba(255, 255, 255, 0.1) !important;
  font-style: normal !important;;
  font-weight: bold !important;;
}

.agentTargetInput{
    border: 0.5px solid rgba(255, 255, 255, 0.1) !important;
    height: 35px !important;  
    margin-bottom: 5px; 
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255,215,0, 1);
    width: 24%;
    margin-right: 50%;
    float: left;
    font-size: 12px;
}
.agentTargetInputShort{
    border: 0.5px solid rgba(255, 255, 255, 0.1) !important;
    height: 35px !important;  
    margin-bottom: 5px; 
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255,215,0, 1);
    width: 24%;
    float: left;
    font-size: 12px;
}

.agentTargetInputLong{
  border: 0.5px solid rgba(255, 255, 255, 0.1) !important;
  height: 35px !important;  
  margin-bottom: 5px; 
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255,215,0, 1);
  width: 49%;
  float: left;
  font-size: 12px;
}
.agentTargetUnit{
    margin-top: 10px;
    font-weight: normal;
    font-style: italic;
    padding-left: 5px;
    width: 45%;
    float: left;
    font-size: 12px;
}
.agentTargetInputTitle{
    margin-top: 10px;
    font-weight: normal;
    width: 100%;
    float: left;
    border-bottom: 2px solid white;
    padding-bottom: 5px;
    font-size: 16px;
    margin-bottom: 10px;
}
.agentTargetInputExpl{
    font-weight: normal;
    font-style: italic;
    font-size: 13px
}

.addTargets{
    width: 20%;
    text-align: left;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: normal;
    padding-left: 15px;
    background: #314F63;
    cursor: pointer;
    float: left;
  }
  .addTargets:hover{
    color: rgba(255,215,0, 1);
    background: #516A7B
  }

  .createItem{
    width: 35%;
    margin-left: 1%;
    text-align: left;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: normal;
    padding-left: 15px;
    background: #314F63;
    cursor: pointer;
    float: left;
  }

  .createItem:hover{
    color: rgba(255,215,0, 1);
    background: #516A7B
  }
