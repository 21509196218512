.assetsTitle{
  font-size: 11px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  width: 98%;
  float: left;
}

.assetItem{
  width: 98%;
  float: left;
  font-weight: 200;
  background: rgba(255, 255, 255, 0.1);
  font-size: 11px !important;  border-top: 0.5px solid #00253D;
  border-radius: 2px;
  color: white;
}
.assetItem:hover{
  background: rgba(255, 255, 255, 0.135);
}
#assetsEntity{
  width: 8%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;  
}
#assetsNumber{
  width: 9.5%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;  
}
#assetsSmall{
  width: 7.75%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#assetsInstockSince{
  width: 7%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#assetsSecondHand{
  width: 2%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#assetsProduct{
  width: 15%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#assetsMedium{
  width: 14%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#assetsExtraSmall{
  width: 8%;
  float: left;
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 5px;
}
#assetsAction{
  width: 8%;
  float: right;
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 5px;
}
#assetsList{
  width: 98.5%;
  margin-left: 1.5%;
  margin-top: 5px;
  float: left;
}

.lightList{
  width: 99%;
  margin-top: 0px;
  float: left;
}

#assetsSmallLight{
  width: 18%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

#assetsExtraSmallLight{
  width: 10%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}


#assetsTiny{
  width: 2%;
  float: left;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .assetsTitle{
    width: 100%;
    font-size: 11px !important;
    font-weight: 300;
  }
  .assetItem{
    width: 100%;
    font-weight: 300;
    font-size: 10px !important;
  }
  #assetsSmall{
    width: 15%;
  }
  #assetsMedium{
    width: 20%;
  }
  #assetsExtraSmall{
    width: 8%;
  }
  #assetsList{
    width: 98%;
    margin-left: 1%;
    margin-top: 15px;
  }
  #assetsNumber{
    width: 30%;
  } 
  #assetsProduct{
    width: 30%;
  }

}
