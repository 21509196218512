.groupsTitle{
  font-size: 11px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  width: 100%;
  float: left;
}

.groupItem{
  width: 100%;
  float: left;
  font-weight: 200;
  font-size: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  margin-bottom: 1px;
  color: white;
}
.groupItem:hover{
  background: rgba(255, 255, 255, 0.135);
}

#groupsNumber{
  width: 9%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#groupsName{
  width: 12%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}

#groupsData{
  width: 9%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}

#groupsDate{
  width: 10%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#groupsProgress{
  
  width: 10%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#groupsList{
  width: 97%;
  margin-left: 1.5%;
  margin-top: 5px;
  margin-right: 20%;
  float: left;
  font-size: 11px;
}

.groupsExtraSmall{
  width: 3%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}

@media screen and (max-width: 768px) {

  #groupsList{
    width: 98%;
    margin-left: 1%;
    margin-top: 15px;
    margin-right: 1%;
  }

}