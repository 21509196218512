#impactPage{
  width: 97%;
  margin-left: 1.25%;
  float: left;
}
#impactMenu{
  width: 15%;
  float: left;
}
#impactMenu_item{
  text-align: left;
  background: rgba(255, 255, 255, 0.2);
  padding: 5px;
  padding-left: 15px;
  margin-bottom: 2px;
  cursor: pointer;
}
.active_impactMenu{
  background: rgb(0, 139, 139) !important;
}
#impactMain{
  width: 85%;
  padding: 15px;
  float: left;
}

.sdgWrapper{
  width: 10%;
  float: left;
}
.impactPageWrapper{
  width: 98.75%;
  float: left;
  margin-left: 1.25%;
}
.sdgData{
  width: 89%;
  float: left;
}

#impactGraphWrapper{
  background: rgba(255, 255, 255, 0.15);
  width: 48%;
  float: left;
  min-height: 250px;
  border-radius: 2px;
  padding: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-left: 1%;
  margin-right: 1%;
}

#impactGraph{
  float: left;
  width: 100%;
}
#impactGraphTitle{
  float: left;
  width: 70%;
  font-size: 14px;
  font-weight: 300;
  margin-top: -15px;
  margin-bottom: 15px;
}
#impactGraphTitleTotal{
  float: left;
  width: 30%;
  text-align: right;
  padding-right: 15px;
  margin-top: -15px;
  font-size: 14px;
  font-weight: bold;
}

#impactError{
  text-align: center;
  font-weight: 300
}

#impactErrorLogo{
  padding: 25px;
  font-size: 42px;
}
#impactErrorInfo{
  font-weight: 300;
  padding-bottom: 40px;
  font-style: italic
}

@media screen and (max-width: 768px) {
  .sdgData{
    width: 100%;
  }
  .sdgWrapper{
    width: 33%;
    margin-right: 66%;
    margin-left: 1%;
    margin-bottom: 10px;
  }
  
#impactGraphWrapper{
  background: rgba(255, 255, 255, 0.15);
  width: 98%;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}
}
