.dealsTitle{
  font-style: italic;
  padding-top: 0px !important;
}

.dealItem{
  width: 100%;
  float: left;
  font-weight: 200;
  text-align: left !important;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 0.5px solid #00253D;
  border-radius: 2px;
  font-size: 11px;
  color: white;
}
.dealItem:hover{
  background: rgba(255, 255, 255, 0.135);
}
#dealDetailHalf{
  margin-left: 50%;
  width: 50%;
  float: left;
}
#dealsSmall{
  width: 12%;
  float: left;
  text-align: center;
  padding-bottom: 7px;
  padding-top: 7px;
}
#dealsProduct{
  width: 20%;
  text-align: center;
  padding-left: 10px;
  float: left;
  padding-bottom: 7px;
  padding-top: 7px;
}
#dealsEntity{
  width: 11%;
  float: left;
  text-align: center;
  padding-bottom: 7px;
  padding-top: 7px;  
}
#dealsName{
  width: 30%;
  float: left;
  text-align: center;
  padding-bottom: 7px;
  padding-top: 7px;
}
#dealsExtraSmall{
  width: 7%;
  float: left;
  padding-bottom: 7px;
  padding-top: 7px;
  font-style: italic;
  text-align: center;
}
#dealsExtraSmall2{
  width: 4%;
  float: left;
  padding-bottom: 7px;
  padding-top: 7px;
  text-align: center;
}
#dealsList{
  width: 66%;
  margin-left: 1.5%;
  margin-top: 5px;
  float: left;
  font-size: 12px;
}
.dealDetails{
  width: 98%;
  float: left;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 1%;
  margin-bottom: 5px;
  background: #00253D;
}

#dealDetailLabel{
  width: 16.5%;
  float: left;
  text-align: left;
  padding: 2px;
  padding-left: 15px;
}
#dealDetailLabelLong{
  width: 40%;
  float: left;
  text-align: left;
  padding: 2px;
  padding-left: 15px;
}
#dealDetail{
  width: 20%;
  float: left;
  text-align: left;
  padding: 2px;
  padding-left: 15px;
  color: rgb(0, 140, 186);
}
#dealDetailLabelShort{
  width: 10%;
  float: left;
  text-align: left;
  padding: 2px;
  padding-left: 15px;
}
#dealDetailShort{
  width: 16.5%;
  float: left;
  text-align: left;
  padding: 2px;
  padding-left: 15px;
  color: rgb(0, 140, 186);
}
#dealDetailDate{
  width: 25%;
  float: left;
  text-align: left;
  padding: 2px;
  padding-left: 15px;
  color: rgb(0, 140, 186);
}
#dealDetailLonger{
  width: 38.5%;
  float: left;
  text-align: left;
  padding: 2px;
  padding-left: 15px;
  color: rgb(0, 140, 186);
}


.dealDetailHistory{
  width: 50%;
  float: left;
  text-align: left !important;
  color: rgb(0, 140, 186);
}
#dealDetailHistoryLabel{
  width: 75%;
  float: left;
  text-align: right;
  padding-right: 10px;
  color: white
}
#dealDetailHistoryGraphic{
  width: 21.5%;
  margin-left: 1%;
  margin-right: 2.5%;
  text-align: center;
  float: left;
  cursor: pointer;
  color: #FFD700;
}
#dealDetailHistoryGraphic:hover{
  color: #008B8B;
}


#dealSection{
  width: 100%;
  float: left;
}

#dealsMonths{
  width: 50%;
  float: left;
  margin:0px;
}
.allowed{
  width: 47.5%;
  padding-left: 15px;
  margin-right: 2.5%;
  font-weight: 200;
  margin-top: 5px;
  float: left;
}
#allowedNumber{
  width: 20%;
  float: left;
}
#allowedRole{
  width: 20%;
  float: left;
}
#allowedName{
  width: 60%;
  float: left;
}

.allowedPopUp{
  width: 47.5%;
  margin-right: 2.5%;
  font-weight: 200;
  margin-top: 5px;
  padding-left: 5px;
  float: left;
}
#allowedNumberPopUp{
  width: 30%;
  float: left;
}
#allowedNamePopUp{
  width: 70%;
  float: left;
}

.auxPricing{
  width: 85%;
  padding-left: 15px;
  margin-right: 15%;
  color: rgb(0, 140, 186);
  font-weight: 200;
  margin-top: 5px;
  float: left;
}
.fullAux{
  width: 100%;
  padding-left: 0px;
  margin-right: 0%;
}
.fullAux{
  margin-bottom: 5px;
}
#auxPricingRef{
  width: 10%;
  float: left;
}
#auxPricingLabel{
  color: white;
  padding-right: 10px;
}
#auxPricingName{
  width: 40%;
  padding-left: 7px;
  font-style: italic;
  float: left;
}
#auxPricingCost{
  width: 25%;
  text-align: left;
  float: left;
  font-style: italic;
}
.auxPricingAction{
  width: 10%;
  text-align: center  !important;
  color: white !important;
  cursor: pointer  !important;
  float: left;
}
.auxPricingAction:hover{
  color: #FFD700  !important
}
.auxPricingGuarantee{
  width: 100%;
  padding-left: 15px;
  color: rgb(0, 140, 186);
  font-weight: 200;
  margin-top: 5px;
  margin-bottom: 5px;
  float: left;
}
#auxPricingGuarantee{
  width: 17%;
  text-align: left;
  float: left;
}

#auxPricingGuranteeLabel{
  width: 16%;
  color: white;
  float: left;
}
#auxPricingGuranteeParts{
  padding-right: 7px;
  padding-left: 7px;
}

@media screen and (max-width: 768px) {
  #dealsList{
    width: 98%;
    margin-left: 1%;
    margin-top: 15px;
  }
  #dealsProduct{
    width: 20%;
    padding-left: 5px;
  }
  #dealsName{
    width: 40%;
    padding-left: 5px;
  }
  #dealsExtraSmall2{
    width: 5.25%;
  }
  #dealDetailLabel{
    width: 25%;
  }
  #dealDetailShort{
    width: 25%;
  }
  .allowed{
    width: 100%;
  }
  .allowedPopUp{
    width: 100%;
    margin-right:0%;
  }
}