#questionYesWrapper{
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  float: left;  
}
#questionYesLabel{
  width: 90%;
  font-weight: 200;
  font-style: italic;
  font-size: 12px;
  padding-top: 5px;
  float: left;  
}
.questionLabel{
  width: 33%;
  font-weight: 200;
  padding-top: 5px;
  font-style: italic;
  font-size: 12px;
  float: left;  
}
.questionInput{
  width: 67%;
  float: left;
  border: 0.5px solid white;
  background: rgba(255, 255, 255, 0.1);
  border: transparent 0.5px solid;
  color: white;
  height: 28px;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px;
  padding-left: 10px;
  margin-bottom: 2px;
}

.possibleAnswerLeft{
  font-size: 12px;
  padding-top: 6px;
  font-weight: 200;
  height: 26px;
  padding-left: 15px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
  margin-right: 0.5%;
  margin-left: 33%;
  width: 27.75%;
  float: left;
}

.conditionalAnswerLeft{
  font-size: 12px;
  padding-top: 6px;
  font-weight: 200;
  height: 26px;
  padding-left: 7.5px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.1);
  margin-right: 0.25%;
  margin-left: 33%;
  width: 18.5%;
  float: left;
}
.answerHeader{
  background: rgba(255, 255, 255, 0.001) !important;
  padding-left: 5px;
  font-style: italic !important;
}
.answerFullHeader{
  background: rgba(255, 255, 255, 0.001) !important;
  font-style: italic !important;
  width: 66% !important;
  margin-left: 0%;
}

.addAnswer{
  border: 0.5px solid transparent;
  font-size: 12px;
  background: #1A3B51;
  margin-right: 0.5%;
  margin-left: 33%;
  width: 27.75%;
  border: rgba(255, 255, 255, 0.1) 0.5px solid;
  height: 28px;
  border-radius: 3px;
  color: white;
  float: left
}
.addQuestion{
  width: 100%;
  float:left;
}
.conditionalAnswer{
  border: 0.5px solid transparent;
  font-size: 12px;
  background: #1A3B51;
  margin-right: 0.25%;
  margin-left: 33%;
  width: 18.5%;
  border: rgba(255, 255, 255, 0.1) 0.5px solid;
  height: 28px;
  border-radius: 3px;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  float: left
}
.localAnswer{
  margin-left: 0.25%;
  margin-right: 0.75%;
}
.condA{
  margin-left: 0% !important;
  margin-right: 0.25% !important;
}
.fullAnswer{
  width: 56%;
  margin-right: 1%;
}
.addQuestionAction{
  width: 10%;
  float: left;  
  padding-top: 6px;
  font-size: 11px;
  height: 30px;
  cursor: pointer;
  border-radius: 3px;  
  height: 26px;
  background: rgba(255, 255, 255, 0.1);
  border: 0px solid transparent;
  color: white;
  margin-bottom: 2px;
  margin-top: 1px;
  text-align: center
}
.addQuestionAction:hover{
  color: #FFD700;
  background: rgba(255, 255, 255, 0.15);
}
#questionRemoveLabel{
  float: left;
  width: 90%;
  font-size: 13px;
  margin-top: 15px;
  color: red;
}
#questionDelete{
  float: left;
  cursor: pointer;
  width: 10%;
  font-size: 13px;
  margin-top: 15px;
  color: red;
  text-align: center;
}
#questionYes{
  width: 10%;
  float: left;
  border-radius: 3px;
  font-size: 11px;
  padding-top: 6px;
  font-weight: 200;
  height: 26px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 12x;
  margin-bottom: 2px;
  cursor: pointer;
  text-align: center;
}
.questionTrue{
  background: rgb(0,139,139)!important;
}
#possibleAnswer{
  font-size: 12px;
  padding-bottom: 2px;
  width: 100%;
  float: left;
}
#possibleAnswerRight{
  width: 10%;
  float: left;
  font-size: 12px;
  padding-top: 4px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
#possibleAnswerRight:hover{
  color: red
}
#questionsList{
  width: 100%;
  float: left;
}

#questionItem{
  width: 100%;
  font-weight: normal;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-top: 5px;
  font-size: 12px;
  float: left;
  text-align: left;
}
#questionItem:hover{
  background: rgba(255, 255, 255, 0.1) !important;
}
#questionsListTitle{
  width: 92.5%;
  float: left;
  color: #FFD700;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 5px;
  font-style: italic
}
#questionsListAction{
  width: 5%;
  padding-bottom: 5px;
  text-align: center;
  cursor: pointer;
  float: left;
}

#questionsListAction:hover{
  color: rgb(0,139,139)!important;
}

#questionsListActionPause{
  width: 5%;
  padding-top: 5px;
  text-align: center;
  cursor: pointer;
  float: left;
}

#questionsListActionPause:hover{
  color: rgb(0,139,139)!important;
}
#questionQuestion{
  width: 69%;
  font-weight: 200;
  padding-left: 10px;;
  float: left;
}
#questionCategory{
  width: 10%;
  background: rgba(255, 255, 255, 0.3);
  font-weight: 200;
  font-size: 10px;
  border-radius: 2px;
  padding: 2px;
  float: left;
  text-align: center;
}
#questionConditional{
  width: 5%;
  float: left;
  font-style: italic;
  font-size: 11px;
  color: #FFD700;
}
#questionOptional{
  width: 5%;
  float: left;
  font-style: italic;
  font-size: 11px;
  color: #FFD700;
}
#questionHelper{
  font-weight: 200;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 10px;
  width: 100%;
  float: left
}
#questionWarning{
  
  color: #FFD700;
  font-weight: 200;
  font-size: 12px;
  margin-bottom: 5px;
  padding-top: 5px;
  width: 100%;
  float: left;
}
#editQuestion{
  width: 3%;
  text-align: center;
  float: left;
}
#moveQuestion{
  width: 3%;
  text-align: center;
  float: left;
}


@media screen and (max-width: 768px) {

  #questionsListTitle{
    width: 85%;
    margin-bottom: 5px;
  }
  #questionsList{
    width: 98.5%;
  }
  #editQuestion{
    width: 4.5%;
  }
  #moveQuestion{
    width: 5.25%;
  }
  #questionQuestion{
    width: 68%;
  }
  
}