.messagesTitle{
  font-size: 11px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  width: 100%;
  text-align: left;
  float: left;
}

.messageItem{
  width: 100%;
  float: left;
  font-weight: 200;
  text-align: left !important;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  font-size: 10.75px;
  padding: 4px;  
  border-top: 0.5px solid #00253D;
  border-radius: 2px;
  color: white;
  text-align: left;
  
}
.messageItem:hover{
  background: rgba(255, 255, 255, 0.135);
}


#messagesDateLarge{
  width: 9%;
  float: left;
  padding: 5px;
}
#messagesDate{
  
  width: 11%;
  float: left;
  padding: 5px;
}
#messageCost{
  width: 8%;
  float: right;
  padding: 5px;
  padding-left: 10px;
}
#messagesStatus{
  width: 13%;
  float: left;
  padding: 5px;  
}
#messagesStatusSmall{
  width: 6%;
  float: left;
  padding: 5px;
}
#messagesSmall{
  width: 19%;
  float: left;
  padding: 5px;
}
#messagesMedium{
  width: 17%;
  float: left;
  padding: 5px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: left !important;
}
#messagesExtraLarge{
  width: 53%;
  float: left;
  padding: 5px;
}
#deliveryDate{
  width: 6%;
  float: left;
  padding: 5px;
}
#messagesLarge{
  width: 59%;
  float: left;
  padding: 5px;
  padding-right: 15px;
}
#messagesExtraSmall{
  width: 9%;
  float: left;
  text-align: center;
  padding: 5px;
}
#messagesList{
  width: 100%;
  margin-top: 0px;
  float: left;
  font-size: 11px;
}

#messagesLongList{
  width: 97%;
  margin-left: 1.5%;
  font-size: 11px;
  float: left;
  margin-top: 5px;
}
.sendM{
  width: 100%;
  float: left;
  font-weight: normal;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 11px;
  padding: 5px;
  text-align: left;
}

.sendM:hover{
  background: rgba(255, 255, 255, 0.15);
}

#messageDetails{
  background: #00253D;
  padding: 5px;
  font-weight: normal;
  font-size: 11px;
  width: 100%;
  float: left;
  text-align: left;
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  padding-top:5px;
}
#messageDetailsWrapper{
  width: 100%;
  float: left;
}
#messageDetailsL{
  width: 15%;
  float: left;
  font-style: italic
}
#messageDetailsD{
  width: 85%;
  padding-top: 3px;
  padding-left: 3px;
  padding-bottom: 3px; 
  color: rgb(0, 140, 186);
  float: left;
}
#messageDetailsBottom{
  width: 100%;
  float: left;
}
#messageDetailsTop{
  width: 100%;
  text-align: center;
  float: left;
}
#messageDetailsAction{
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
  font-size: 10px;
  cursor: pointer;
  padding: 5px;
  background: rgba(255, 255, 255, 0.25);
  width: 100px;
}
#messageDetailsAction:hover{
  background: rgba(255, 255, 255, 0.30);
  color: #FFD700;
}