
.dealDetailsInput{
	border: 0.5px solid white;
	background: white;
	color: #1A3B51;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 2px;
  padding-top: 7px;
	padding-left: 5px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}
.dealDetailsInputChooser{
	border: 0.5px solid white;
	background: white;
	color: #1A3B51;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 2px;
  padding-top: 6px;
	padding-left: 5px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}
.dealDetailsInputHalf{
	border: 0.5px solid white;
	background: white;
	color: #1A3B51;
  width: 49%;
  margin-left: 1%;
  margin-bottom: 10px;
  border-radius: 2px;
  padding: 0px;
	padding-left: 5px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}
.dealEditInput{
	border: 0.5px solid white;
	background: white;
	color: #1A3B51;
	width: 100%;
  margin-bottom: 10px;
  border-radius: 2px;
  padding: 0px;
	padding-left: 5px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}
.small{
  width: 45% !important
}

.contractSyncItem{
  width: 9%;
  margin-right: 1%;
  float: left;
  font-weight: 200;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
  padding: 3px;
  text-align: center
}

#contractSyncList{
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
  float: left;
}

#contractSyncListTitle{
  padding-left: 5px;
  padding-top: 15px;
  font-size: 13px;
  width: 100%;
  float: left;
}

#contractSyncWarning{
  color: red;
  padding-left: 5px;
  padding-bottom: 15px;
  font-size: 13px;
  width: 100%;
  float: left;
}

.dealCreate{
  background: rgba(255, 255, 255, 0.00);
  width: 100%;
  float: left;
  font-size: 12px;
  margin-bottom: 15px;
  margin-top: -20px;
}
#dealPricingWarning{
  width: 100%;
  float: left;
  font-style: italic;
  padding-left: 5px;
  padding-top: 5px;
}
#dealTitle{
  font-size: 16px;
  cursor: pointer;
  width: 33%;
  float: left;
  padding: 10px;
  text-align: left;
  color: white;
  background: #00253D;
}
#dealTitle:hover{
  color: rgb(255,215,0);
}
.activeDealTitle{
  font-weight: bold;
  background: rgb(255,215,0) !important;
  color: #00253D !important;
}

.guaranteeLabel{
  margin-right: 3%;
  width: 20%; 
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
  font-weight: 200;
  float: left;
}
.guaranteeLabelLong{
  width: 40%; 
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
  font-weight: 200;
  float: left;
}

.guaranteeInput{
  background: rgba(255, 255, 255, 0.05);
  border-radius: 3px;
  height: 30px;
  margin-top: 0px;
  color: white;
  margin-bottom: 10px;
  font-size: 12px;
  padding: 0px;
  text-align: center;
  border: 0.5px solid transparent;
  width: 17%;
  float: left;
}

#addGuaranteeAction{
  width: 50%;
  float: left;
  margin-top: 10px;
  margin-right: 50%;
}

#formSectionTitle{
 	color: rgb(0,139,139);
	padding: 5px;
	padding-left: 10px;
	border-bottom: 0.5px solid rgb(0,139,139);
	margin-bottom: 10px;
}

#newDealAction{
  width: 100%;
  margin-top: 15px;
	float: left;
	text-align: left
}

#newDealLabel{
  width: 30%;
  float: left;
  text-align: left;
  font-style: italic;
  padding-top: 5px;
  padding-left: 5px;
  font-weight: 200
}

#newDealDes{
  width: 100%;
  float: left;
  text-align: left;
  padding: 5px;
  font-style: italic;
  font-weight: normal
}

#newDeal{
	width: 70%;
	float: left;
}

#newDealHalf{
  width: 34.5%;
	float: left;
}
.dealComment{
  padding-top: 5px !important;
  font-style: italic !important;
}
.dealCreateLeft{
  margin-right: 1%;
}

#newDealComment{
	width: 12.5%;
	float: left;
  padding-top: 7px;	
  font-weight: 200;
  padding-left: 10px;
}
#newDealCommentLong{
  width: 11.5%;
  text-align: left;
  padding-left: 15px;
	float: left;
  padding-top: 7px;	
  margin-bottom: 17px;
  font-weight: 200
}
#newDealCommentLonger{
  width: 29%;
  text-align: left;
  padding-left: 15px;
	float: left;
  padding-top: 7px;	
  margin-bottom: 17px;
  font-weight: 200
}

.selectedAgent{
  background: rgba(255, 255, 255, 0.05) !important
}

#longComment{
  width: 44%;
  text-align: left;
  padding-left: 5px;
  padding-top: 10px;
  font-style: italic;
  font-size: 11px;
	float: left;
  margin-bottom: 15px;
  font-weight: 200
}

#newDealWithComment{
  width: 20%;
  font-weight: normal;
	float: left;
}

#monthWrapper{
  width: 100%;
  float: left;
  padding-top:5px;	
	padding-left: 5px;
  font-weight: normal;
  text-align: center;
  font-size: 10px;

}
#month{
  width: 8.25%;
  float: left;
  padding-top: 2px;
	padding-bottom: 2px;
	margin-bottom: 20px;
  border: 0.5px solid white;
  cursor: pointer;
}
#monthViewer{
  width: 8.25%;
  float: left;
  padding-top: 2px;
	padding-bottom: 2px;
	margin-bottom: 1px;
  border: 0.5px solid white;
  cursor: pointer;
}
.selectedMonth{
  background: rgb(0,139,139) !important;
  color: #00253D !important;
}

#flexLabel{
	width: 55%;
	float: left;
	text-align: left;
}
#flexInput{
	width: 45%;
	float: left;
}
#flexMonths{
	width: 55%;
	float: left;
}
#flexPricing{
	width: 45%;
	float: left;
}
#monthAction{
	text-align: center;
	padding: 5px;
	cursor: pointer;
	width: 50%;
	float: left;
	background: #FFD700
}
#monthReponse{
	text-align: center;
	padding: 5px;
	width: 50%;
	float: left;
}

#dealWhite{
	
  width: 30%;
  float: left;
	margin-bottom: 10px;
	padding-left: 10px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
}

#dealEdit{
	width: 80%;
	float: left;
}
#dealEditL{
  width: 20%;
  float: left;
  padding-top: 7px;
  font-weight: 300;
  font-size: 12px;
}
#dealEditLleft{
  width: 17.5%;
  margin-left: 2.5%;
  float: left;
  padding-top: 7px;
  font-weight: 300;
  font-size: 12px;
}
#dealEditShort{
  width: 30%;
	float: left;
}
#dealEditShorter{
  width: 20%;
	float: left;
}
.dealEditInputLabel{
  width: 20%;
  font-size: 11px;
  font-style: italic;
  padding-top: 7px;
  padding-left: 7px;
  float: left;
}
#dealEditVersionLabel{
	width: 50%;
  float: left;
  padding-top: 7px;
  font-weight: 300;
  font-size: 12px;
}
#dealEditVersion{
	width: 50%;
  height: 30px;
  font-size: 20px;
  text-align: right;
  float: left;
}

#dealEditDisclaimerL{
  margin-top: -15px;
	width: 100%;
  float: left;
  font-weight: 300;
  text-align: center;
  border-radius: 2px;
  padding: 3px;
  background: #FFD700;
  color: #00253D;
  font-size: 12px;
}
#dealEditDisclaimerD{
  width: 100%;
  float: left;
  padding-top: 5px;
  padding-bottom: 15px;
  font-style: italic;
  font-weight: 300;
  text-align: left;
  font-size: 12px;
}

.dealHistoryTitle{
  width: 100%;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.05);
  padding: 5px;
  padding-left: 10px;
  float: left;
}
.dealHistoryItem{
  width: 100%;
  font-weight: 200;  
  font-size: 12px;
  padding: 5px;
  color: rgb(0, 140, 186);
  padding-left: 10px;
  float: left;
}
#dealHistoryItemVersion{
  width: 10%;
  float: left;
}
#dealHistoryItemDate{
  width: 15%;
  float: left;
}
#dealHistoryItemCost{
  width: 12%;
  float: left;
}

#newGroupLabel{
  width: 25%;
  float: left;
  text-align: left;
  font-style: 'italic';
  padding-top:7px;
  padding-left: 10px;
  font-weight: 200
}

#newGroup{
	width: 75%;
	float: left;
}
#newGroupLabelSmall{
  width: 15%;
  float: left;
  text-align: left;
  padding-top:7px;
  padding-left: 10px;
  font-weight: 200
}
#newGroupTwoThird{
	width: 35%;
	float: left;
}

#newGroup{
	width: 75%;
	float: left;
}

#newGroupQuarter{
  width: 25%;
	float: left;
}

#newGroupHalf{
  width: 50%;
  float: left;
  padding-left: 5px;
  margin-top: -1px;
  margin-bottom: 10px;
  font-weight: 200;font-style: italic
}

#groupDealOption{
  width: 49%;
  margin-right: 1%;
  float: left;
  color: white !important;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.05);
  padding: 7px;
  border-radius: 3px;
  padding-left: 5px;
  margin-bottom: 2px;
  font-weight: 200;
}
#groupDealOption:hover{
  color: #FFD700 !important;
  background: rgba(255, 255, 255, 0.10);
}
.selectedDeal{
  color: #FFD700 !important;
  font-weight: bold !important;
  background: rgba(255, 255, 255, 0.10) !important;
}

#restrictDeal{
  margin-top: 0px;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
  font-weight: normal;
  margin-right: 1%;
  border: 0.25px solid white;
  font-style: italic;
  width: 24%;
  float: left;
  font-size: 11px;
  margin-bottom: 5px;
}


.restricted{
  color: rgba(255,215,0, 1) !important;
  background: rgba(255, 255, 255, 0.1);
  border: 0.25px solid white !important;
  font-style: normal !important;;
  font-weight: bold !important;;
}
#dealEditSubTitle{
  width: 50%;
  float: left;
  padding-left: 5px;
  font-weight: bold;
  font-size: 12px;
}
#dealSubTitle{
  width: 30%;
  float: left;
  padding: 7px;
  font-weight: bold;
  font-size: 13px;
}
#dealSubTitleLong{
  width: 90%;
  float: left;
  padding: 7px;
  font-weight: bold;
  font-size: 13px;
}
#modifyList{
  border: 0.25px solid white;
  width: 24%;
  float: left;
  cursor: pointer;
  padding: 5px;
  margin-top: 5px;
  border-radius: 2px;
}
#modifyList:hover{
  background: rgba(255, 255, 255, 0.1)
}