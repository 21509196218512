.modalCreateTitle{
  color: rgb(255,215,0);
  font-size: 20px;
  padding-left: 5px;
  text-align: left;
}

.modalBody{
  width: 100%;
  float: left;
  font-size: 12px;
  margin-top: -15px;
}

#confirm_action{
  background: rgba(220,20,60, 1);
  border: 1px solid rgba(220,20,60, 1);
  color: white;
  width: 30%;
  font-size: 12px;
  margin-right:2%;
  border-radius: 3px;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: normal;
  float: left;
}
#message_delete{
  background: rgba(220,20,60, 0.9);
  border: 0.5px solid rgba(220,20,60, 0.9);
  color: white;
  width: 16%;
  font-size: 12px;
  margin-right:2%;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: normal;
  float: left;
}
#message_pause{
  color: white;
  border: 0.5px solid rgba(220,20,60, 1);
  background: rgba(255, 255, 255, 0.15);
  width: 16%;
  font-size: 12px;
  margin-right:2%;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: normal;
  float: left;
}
#message_delete:hover{
  background: rgba(220,20,60, 1);
  border: 0.5px solid rgba(220,20,60, 1);
}
#message_pause:hover{
  color: rgba(220,20,60, 1);;
  font-weight: bold;
  border: 0.5px solid rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, 1);
}
#action_cancel{
  width: 30%;
  font-size: 12px;
  border-radius: 2px;
  margin-right:2%;
  float: left;
  background: #00253D;
  border: 1px solid #00253D;
  color: white
}
#action_cancel:hover{
  background: white;
  border: 1px solid white;
  color: #00253D;
}

.small{
  width: 100px !important
}
.shiftedUp{
  margin-top: -30px !important
}
#action_signup{
  background: rgb(0,139,139);;
  border: 1px solid transparent;
  width: 30%;
  float: left;    
  text-align: center; 
  border-radius: 2px;
  font-size: 12px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right:2%;
  color: white
}
#action_signup:hover{
  background: white;
  border: 1px solid white;
  color: rgb(0,139,139);
}

#modal_highlight{
  padding-top:0px;
  width: 100%;
  float: left;
}
#modal_action{
  width: 100%;
  float: left;
  margin-top: 10px;
}

#modalEditContract{
  width: 95%;
  margin-top: 25px;
  margin-left: 5%;
  float: left;
}

.infoWrapper{
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 1px;
  margin-top: 1px;
  padding:0px;

}
.activeWrapper:hover{
  background: rgba(255, 255, 255, 0.10) !important;
  box-shadow: 0 0 1px rgba(255, 255,255, 0.5);
}
#infoLabel{
  width: 34%;
  float: left;
  padding:3px;
  padding-left: 1px;
  margin-bottom: 0px;
  margin-top: 0px;
  color: white;
  font-style: italic;
  font-weight: normal;
}

#infoData{
  width: 66%;
  float: left;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 0px;
  border-radius: 2px;
  margin-top: 0px;
  padding:3px;
  padding-left: 7px;
}
#infoDataMain{
  width: 66%;
  float: left;
  background: rgba(255, 255, 255, 0.95);
  color: #26455a;
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 0px;
  border-radius: 2px;
  margin-top: 0px;
  padding:3px;
  padding-left: 7px;
}
#infoDataTwoThird{
  width: 42%;
  margin-right: 1%;
  float: left;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.95);
  color: #26455a;
  font-size: 11px;
  border-radius: 2px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding:3px;
  padding-left: 7px;
}
#infoDataOneThird{
  width: 23%;
  float: left;
  background: rgba(255, 255, 255, 0.95);
  color: #191970;
  font-size: 11px;
  margin-bottom: 0px;
  border-radius: 2px;
  margin-top: 0px;
  padding:3px;
  padding-left: 7px;
}

#infoDataShortLeft{
  width: 32.5%;
  margin-right: 1%;
  float: left;
  border-radius: 2px;
  min-height: 22px;   background: rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: bold;
  font-size: 11px;
  padding:3px;
  padding-left: 7px;
  margin-bottom: 0px;
  margin-top: 0px;
}

#infoDataShort{
  width: 32.5%;
  float: left;   background: rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: bold;
  min-height: 22px;
  font-size: 11px;
  padding:3px;
  border-radius: 2px;
  padding-left: 7px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.inputLabel{
  width: 100%;
  float: left;
  padding:3px;
  color: white;
  font-weight: normal;
  margin-bottom: 1px;
  margin-top: 1px;  
}
#contractLabel{
  text-align: left;
  padding-top: 15px;
  font-weight: normal;
}
.detailsLabel{
  width: 30%;
  float: left;
  padding:3px;
  color: white;
  font-weight: normal;
  font-style: italic;
  margin-bottom: 1px;
  margin-top: 1px;
}
.detailsInput{
  border: 0.5px solid rgba(255, 255, 255, 0.05);
  border-radius: 2px;
  width: 70%;
  float: left; background: rgba(255, 255, 255, 0.05);
  height: 22px;
  color: white;
  font-weight: bold;
  font-size: 11px;
  padding:3px;
  padding-left: 10px;
  margin-bottom: 1px;
  margin-top: 1px;
}
.agentInput{
  border: 0.5px solid transparent;
  border-radius: 2px;
  width: 70%;
  float: left;
  background: white;
  height: 22px;
  color: rgb(0,139,139);;
  font-size: 11px;
  padding:3px;
  padding-left: 10px;
  margin-bottom: 1px;
  margin-top: 1px;  
}

.EnabledWarning{
 background: rgb(0,139,139) !important;
 color: white !important;
}
.LockedWarning{
  background:  rgb(255,215,0) !important;
}

#half{
	width: 50%;
  float: left;
  margin:0px;
}

#full{
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 0px;
  margin-top: -10px;
  padding:0px;
}
.full{
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 10px;
}
#spacer{
  height: 10px;
  width: 100%;
  float: left;
}
.noMargin{
  margin-top: 0px !important;
  margin-bottom: 0px !important
}
.loader{
  text-align: center;
  padding-top: 50px !important;
  font-size: 18px !important;
  font-style: italic !important;
  font-weight: normal !important
}

.genericLoader{
  width: 100%;
  float: left;
  text-align: center;
  padding-top: 50px !important;
  font-size: 18px !important;
  font-style: italic !important;
  font-weight: normal !important
}

.graphLoader{
  min-height: 350px;
  padding-top: 125px !important;
  font-size: 28px !important;
}

#formSectionTitle{
 	color: rgb(0,139,139);
	padding: 5px;
	 padding-left: 10px;
	border-bottom: 0.5px solid rgb(0,139,139);
  margin-bottom: 10px;
  font-weight: normal

}

#actionWrapper{
	width: 100%;
	padding-top: 30px;
	float: left;
}

#createButton{
	background: rgba(255,215,0, 0.9);
	color: rgb(0,37, 61);
  border: 0.5px solid rgb(255,215,0);
  border-radius: 3px;
  font-size: 13px;
	text-align: center;
	width: 150px;
	padding: 10px;
	cursor: pointer
}

#createButton:hover{
	background: rgba(255,215,0, 1);
}
#updateButton{
	background: rgba(0,139,139, 0.9);;
	color: white;
	border: 0.5px solid rgba(0,139,139, 0.9);;
  text-align: center;
  font-size: 12px;
	width: 150px;
	padding: 5px;
	cursor: pointer
}
#updateButton:hover{
	background: rgba(0,139,139, 1);;
}

#impactWrapper{
  width: 48%;
  float: left;
  text-align: left;
  margin-right: 1%;
  margin-right: 1%;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding:0px;

}

#impactSubLabel{
  width: 60%;
  float: left;
  margin-bottom: 15px;
  padding:5px;
}
#impactData{
  width: 40%;
  float: left;
  background: rgba(255, 255, 255, 0.15);;
  min-height: 22px;
  color: white;
  font-size: 11px;
  padding:5px;
  padding-left: 10px;
  margin-bottom: 15px;
  margin-top: 0px;
}

#creditApprove{
  background: rgb(0, 139, 139, 0.9);border-right: 1px solid #26455A;
  width: 48%;
  margin-left: 4%;
  min-height: 33px;
  padding-top: 7px;
  text-align: center;
  font-weight: normal;
  margin-top: -10px;
  float: left;
  color: white;
  cursor: pointer;
}
#creditReject{
  background: rgb(220,20,60, 0.9);border-left: 1px solid #26455A;
  width: 48%;
  font-weight: normal;
  text-align: center;
  min-height: 33px;
  margin-top: -10px;
  padding-top: 7px;
  float: left;
  color: white;
  cursor: pointer;
}
#creditApprove:hover{
  background: rgb(0, 139, 139, 1)
}
#creditReject:hover{
  background: rgba(220,20,60, 1)
}

.Selected{
  font-weight: bold !important;
  color: #00253D !important;

}


#infoDataLeft{
  width: 14%;
  margin-right: 1%;
  float: left;background: rgba(255, 255, 255, 0.1);
  height: 22px;
  color: white;
  border-radius: 2px;
  font-size: 11px;
  padding:3px;
  padding-left: 10px;
  margin-bottom: 1px;
  margin-top: 1px;
}

#infoDataRight{
  width: 51%;
  float: left;background: rgba(255, 255, 255, 0.1);
  height: 22px;
  color: white;
  border-radius: 2px;
  font-size: 11px;
  padding:3px;
  padding-left: 10px;
  margin-bottom: 1px;
  margin-top: 1px;
}
.detailsInputShort{
  border: 0.5px solid transparent;
  border-radius: 2px;
  width: 14%;
  margin-right: 1%;
  float: left;
  background: white;
  height: 22px;
  color: rgb(0,139,139);;
  font-size: 11px;
  padding:3px;
  padding-left: 10px;
  margin-bottom: 1px;
  margin-top: 1px;
}
.detailsInputLong{
	border: 0.5px solid white;
  width: 55%;
  float: left;
  background: white;
  height: 22px;
  color: rgb(0,139,139);;
  font-size: 11px;
  padding:3px;
  padding-left: 10px;
  margin-bottom: 1px;
  margin-top: 1px;
}

.detailsInputH{
	border: 0.5px solid rgba(255, 255, 255, 0.05);
  width: 34.5%;
  border-radius: 2px;
  float: left; background: rgba(255, 255, 255, 0.05);
  height: 22px;
  color: white;
  font-weight: bold;
  font-size: 11px;
  padding:3px;
  padding-left: 10px;
  margin-bottom: 1px;
  margin-top: 1px;
}