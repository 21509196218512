.activationItem{

  width: 100%; float: left;
  font-weight: normal;
  background: rgba(255, 255, 255, 0.1);
  border-top: 0.5px solid #00253D;
  border-radius: 2px;
  color: white;
  font-size: 10px;
  text-align: left; 
}
.activationsTitle{
  font-size: 11px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  text-align: left;
  width: 100%;
  float: left;
}
#activationSection{
  width: 100%;
  float: left;
}
#activationText{
  width: 10%;
  float: left;
  padding-left: 5px;
  padding: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  background: rgba(255, 255, 255, 0.1);
}
#activationAddOne{
  width: 10%;
  float: left;
  cursor: pointer;
  color: #FFD700;
  padding: 5px;
  background: rgba(255, 255, 255, 0.1);
}
.activationsInput{
  border: 0.5px solid white;
  border-radius: 3px;
  color: #00253D;
  font-size: 10px;
  margin-top: 0px;
  width: 70%;
  float: left;
}

#activationsLarge{
  width: 22%;
  float: left;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}

#activationsSmall{
  width: 10%;
  float: left;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}

#activationsMed{
  width: 15.25%;
  float: left;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}