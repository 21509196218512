#groupMessageWrapper{
  width: 97%;
  margin-left: 1.5%;
  float: left;
}
#groupMessageWrapperLeft{
  width: 25%;
  float: left;
  text-align: left;
}

#groupMessageWrapperRight{
  width: 73.5%;
  margin-left: 1.5%; margin-top: 10px;
  background: rgba(255, 255, 255, 0.05);
  padding: 10px;
  margin-top: 10px;
  float: left;
}
#groupMessageWrapperBottom{
  width: 100%;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.05);
  padding: 10px;
  float: left;
  text-align: left;
}

.successSent{
  margin-top: 12px !important;
}
#groupMessageLabel{
  width: 100%;
  float: left;
  margin: 0px;
  color: white;
  font-size: 12px;
  padding-left: 5px;
  padding-top: 5px;
}
#groupMessageTitle{
  width: 100%;
  float: left;
  color: white;
  font-size: 15px;
  text-align: left;
  padding-left: 5px;
  padding-top: 5px;
  margin-bottom: 15px;
}
#groupMessageCat{
  width: 33%;
  float: left;
  margin: 0px;
  font-weight: normal;
  color: white;
  font-size: 11px;
  font-style: italic;
  padding-left: 5px;
  padding-top: 5px;
  margin-bottom: 2px;
}
.groupMessageInput{
  width: 67%;
  float: left;
  margin: 0px;
  border: 0.5px solid transparent;
  color: rgb(0,37, 61);
  font-size: 11px;
  height: 150px;
  padding-left: 5px;
  padding-top: 5px;
}

.shaded{
  background: rgba(255, 255, 255, 0.35) !important;
  color: white !important;
}

.groupMessageInputS{
  width: 67%;
  float: left;  
  margin: 0px;
  margin-bottom: 2px;
  border: 0.5px solid transparent;
  background: white;
  height: 25px;
  color: rgb(0,37, 61);
  font-size: 11px;
  padding-left: 5px;
  padding-top: 5px;
}

#taskWrapper{
  margin-left: 1.5%;
  width: 19.5%;
  margin-top: 15px;
  float: left;
}

#selectAgentsSection{
  width: 100%;
  float: left;
  padding-top:15px;
  padding-bottom: 15px;
}

#selectedAgents{
  width: 100%;
  float: left;
  margin-top: 5px;
  font-size: 12px;
  padding-left: 15px;
}

#internalNoteWrapper{
  width: 100%;
  float: left;
}

#sendToSelectedAgents{
  width: 20%;
  float: left;
  margin-top: 15px;
  margin-bottom: 25px;
}

#sendToAgent{
  width: 100%;
  color: white !important;
  background: rgb(0,139,139) !important;
  border: 1px solid rgb(0,139,139);
}

#selectAgents{
  width: 100%;
  float: left;
  padding-left: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 300; 
}

@media screen and (max-width: 768px) {

  #groupMessageWrapper{
    width: 97%;
    margin-left: 1.5%;
  }
  #groupMessageWrapperLeft{
    width: 100%;
  }
  
  #groupMessageWrapperRight{
    width: 100%;
    margin-left: 0%; 
  }
}