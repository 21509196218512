
.templateDetailsInput{
	border: 0.5px solid white;
	width: 100%;
	margin-bottom: 10px;
	padding-left: 10px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}
.templateDetailsInputCore{
  border: 0.5px solid white;
  background: white;
  color: #00253D;
	width: 100%;
	margin-bottom: 10px;
	padding-left: 10px;
	padding-top: 7px;
	height: 30px;
	font-size: 12px;
	float: left;
}

.templateDetailsTextInput{
	border: 0.5px solid white;
	width: 100%;
	margin-bottom: 10px;
  padding-left: 10px;
  padding-top: 7px;
  height: 75px !important;
  margin-top: 0px;
  color: #00253D;
	height: 30px;
	font-size: 12px;
	float: left;
}


.templateCreate{
  background: rgba(255, 255, 255, 0.15);
  width: 100%;
  float: left;
  font-size: 12px;
  padding: 15px;
  margin-bottom: 15px;
}

#templateTitle{
  color: rgb(255,215,0);
  font-size: 20px;
  text-align: left;
  padding-bottom:15px;
}

#formSectionTitle{
 	color: rgb(0,139,139);
	padding: 5px;
	padding-left: 10px;
	border-bottom: 0.5px solid rgb(0,139,139);
	margin-bottom: 10px;

}


#createTemplate{
	background: rgb(255,215,0);
	padding:5px;
	margin-left:2px;
	width: 150px;
	margin-top: 2px;
	cursor: pointer
}

#newCreateTemplate{
	width: 100%;
	float: left;
}

#newTemplateLabel{
	width: 100%;
	float: left;
  text-align: left;
  font-weight: normal;
  font-style: italic;
	padding-bottom: 5px;
	padding-left: 5px;
	font-size: 12px;
}

#newTemplate{
	width: 100%;
	float: left;
}


.automationDetails{

}

.templateDetailsAutomated{
	border: 0.5px solid white;
	width: 100%;
	
	margin-bottom: 10px;
  padding-left: 10px;
  padding-top: 7px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}

.automationLabelLong{
	width: 50%;
	margin-bottom: 10px;
  padding-top: 7px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}
.majleft{
  margin-left: 5%;
}
.automationLabelShort{
	width: 20%;
	margin-bottom: 10px;
  padding-left: 10px;
  padding-top: 7px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}
.automationLabelMedium{
  width: 55%;
	margin-bottom: 10px;
  padding-left: 10px;
  padding-top: 7px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}
.detailsTemplateInput{
  border: 0.5px solid white;
	width: 45%;
	margin-bottom: 10px;
  padding-left: 10px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}
.detailsTemplateInputShort{
  border: 0.5px solid white;
	width: 25%;
	margin-bottom: 10px;
  padding-left: 10px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}

#messageVariables{
  width: 100%;
  float: left;
  font-size: 12px;
}
#messagePreview{
  width: 100%;
  float: left;
  font-style: italic;
  font-size: 11px;
}
#messageVariableTitle{
  background: rgba(255, 255, 255, 0.15);
  padding: 2px;
  padding-left: 5px;
  margin-top: 7px;
  font-style: italic;
  margin-bottom: 5px;  
  width: 100%;
  float: left;
}
#messageVariable{
  width: 50%;
  float: left;color: #FFD700;
  padding-bottom: 1px;
}

#messageVariableLabel{
  width: 50%;
  float: left;
  font-size: 11px;
  padding-bottom: 3px;
  text-align: right;
  font-weight: normal;
  font-style: italic
}