
.ratesTitle{

  width: 49%;
  margin-right: 50%;  float: left;
  font-size: 11px;
  font-weight: 200;
  color: white;
  font-style: italic;
}
  
.rateItem{
  width: 49%;
  margin-right: 50%;
  float: left;
  font-weight: normal;
  font-size: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  margin-bottom: 1px;
  color: white;
}
.rateItem:hover{
  background: rgba(255, 255, 255, 0.135);
}

.rateRate{
  width: 30%;
  padding: 5px;
  float: left
}

.rateDate{
  padding: 5px;
  width: 17.5%;
  text-align: center;
  float: left
}

.rateSet{

  width: 20%;
  padding: 5px;
  text-align: center;
  float: left
}
.ratePayments{

  width: 10%;
  padding: 5px;
  text-align: center;
  float: left
}


@media screen and (max-width: 768px) {

  .rateItem{
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 30px;
  } 
}