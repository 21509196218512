#eventList{
  width: 97%;
  margin-left: 1.5%;
  float:left;
  margin-top: 15px;
}
#eventListTitle{
  text-align: left;
  margin-left: 0.25%;
  padding-bottom: 5px;
}
#cEvent{
  width: 19.5%;
  margin-right: 0.5%;
  float: left;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.15);
  font-size: 11px;
  margin-bottom: 10px;
}

#cEventHeader{
  width: 45%;
  height: 45px;
  border-radius: 2px;
  float: left;
  background: rgba(255, 255, 255, 0.1)
}
#cEventType{
  font-size: 13px;
  color: rgb(0,139,139);
  float: top;
  height: 25px;
  padding: 0px;
  padding-top: 5px;
  font-weight: normal
}
#cEventDate{
  color: white;
  float: top;
  height: 20px;
  padding: 0px;
  font-size: 10px;
  font-weight: normal
}

#cEventMain{
  width: 55%;
  padding: 0px;
  padding-top: 5px;
  height: 45px;
  float: left; 
}

#cEventLabel{
  width: 100%;
  float: top;
  height: 20px;
  padding: 0px;
  font-size: 10px;
  font-weight: normal
}

.ewarning{
  color: rgb(255,215,0) !important;
}
.edanger{
  color: rgb(220,20,60) !important;
}
.eneutral{
  color: rgba(255,255,255, 0.3) !important;
}
.egreen{
  color: rgb(0,139,139) !important;
  font-weight: bold !important;
}

@media screen and (max-width: 768px) {

  #cEvent{
    width: 48%;
    margin-right: 1%;
    margin-left: 1%;
  }
}