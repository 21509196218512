
.entityLabel{
  width: 15%;
  float: left;
  font-weight: 200;
  padding: 5px;
}
#entitiesList{
  width: 23.5%;
  margin-left: 1.5%;
  border-radius: 3px;
  font-size: 12px;
  float: left;
}

.entityItem{
  width: 100%;
  float: left;
  font-weight: normal;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 2px;
  cursor: pointer;
  border-radius: 3px;
  color: white;
}

.entityItem:hover{
  background: rgba(255, 255, 255, 0.2) !important;
  color: rgb(255, 215, 0) !important;
}

#entityEditDetailDelete{
  color: red;
  text-align: center;
  width: 15%;
  margin-left: 85%;
  padding: 5px;
}

.activeEntity{
  background: white !important;
  color: #00253D !important;
}

#entityType{
  text-align: center;
  padding: 10px;
  font-weight: normal;
  font-size:16px;
  width: 10%;
  float: left;
}
#entityName{
  text-align: left;
  padding: 10px;
  font-weight: normal;
  font-size:16px;
  width: 90%;
  float: left;
}
#entityHeader{
  text-align: left;
  padding: 10px;
  font-weight: 200;
  font-size: 14px;
  width: 100%;
  float: left;
}

.entitiesTitle{

}

.entityAction{
  width: 10%;
  text-align: center  !important;
  color: white !important;
  cursor: pointer  !important;  
  padding: 10px;
  font-weight: normal;
  cursor: pointer;
  color: white;
  font-size:16px;
  float: left;
}
.entityAction:hover{
  color: #FFD700  !important
}

@media screen and (max-width: 768px) {


  .entityItem{
    width: 100%;
    font-weight: 300;
    font-size: 10px !important;
  }



}
