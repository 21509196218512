.productsTitle{
  font-style: italic
}

#pausedList{
  width: 100%;
  float: left;
  font-weight: normal;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.10);
  border-radius: 2px;
  color: #FFD700;
  text-align: left;
  font-size: 16px;
  font-style: italic;
  padding: 7px;
  padding-left: 15px;
}

#pausedListShort{
  width: 95%;
  float: left;
  font-weight: normal;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.10);
  border-radius: 2px;
  color: #FFD700;
  text-align: left;
  font-size: 16px;
  font-style: italic;
  padding: 7px;
  padding-left: 15px;
}


#pausedListAction{
  width: 5%;
  float: left;
  font-weight: normal;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  padding: 7px;
}
#pausedListAction:hover{
  color: #FFD700
}
#pausedListTitle{
  width: 25%;
  float: left;
}
.pausedListTitle{
  color: white;
  font-style: italic;
  text-align: center;
  padding-bottom: 0px !important;
  font-size: 11px;
}

.productItem{
  width: 100%;
  float: left;
  font-weight: 200;  
  font-size: 11px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 0.5px solid #00253D;
  border-radius: 2px;
  color: white;
}

.productItem:hover{
  background: rgba(255, 255, 255, 0.135);
}

#productsSmall{
  width: 5%;
  float: left;
  padding-bottom: 7px;
  text-align: center;
  padding-top: 7px;
}
#productsEntity{
  width: 12%;
  float: left;
  font-size: 11px;
  padding-top: 7px;
  padding-bottom: 3px;
}

.pausedListProduct{
  margin-left: 46% !important;
}
#productsName{
  width: 24%;
  float: left;
  padding-bottom: 7px;
  padding-top: 7px;
}
#productsCategory{
  
  width: 12%;
  float: left;
  padding-bottom: 7px;
  padding-top: 7px;
}

#productsParts{
  width: 6%;
  float: left;
  padding-bottom: 7px;
  padding-top: 7px;
}

#productsManufacturer{
  
  width: 22%;
  float: left;
  padding-bottom: 7px;
  padding-top: 7px;
}
#productsList{
  width: 65%;
  margin-left: 1.5%;
  margin-top: 5px;
  float: left;
  font-size: 12px;
}

.productDetails{
  width: 100%;
  float: left;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #00253D;
  border-left: 0.5px solid rgba(255, 255, 255, 0.15);
  border-right: 0.5px solid rgba(255, 255, 255, 0.15)
}
#productDetailLabel{
  width: 15%;
  float: left;
  text-align: left;
  padding: 2px;
  padding-left: 10px;
  font-style: italic
}

#productDetail{
  width: 85%;
  float: left;
  text-align: left;
  padding: 2px;
  padding-left: 15px;
  color: rgb(0, 140, 186);
}
#productEditLabel{
  width: 22.5%;
  float: left;
  text-align: left;
  padding: 2px;
  font-size: 12px;
  padding-left: 0px;
  padding-top: 5px;
  font-style: italic
}
#productEditDetail{
  width: 77.5%;
  float: left;
  padding-left: 15px;
  color: rgb(0, 140, 186);
}
.creatableL{
  width: 75% !important;
}
.creatableD{
  width: 10% !important;
}
.creatableS{
  width: 45%;
}
.scannableL{
  width: 50% !important
}

.scannableS{
  width: 25% !important
}

#productSection{
  width: 100%;
  float: left;
}

#productSubTitle{
  border-bottom: 1.5px solid white;
  width: 94%;
  color: #FFD700;
  margin-left: 1%;
  float: left;
  text-align: left;
  padding-bottom: 5px;
  font-size: 12px;
  padding-left: 2px;
  margin-bottom: 5px;
  margin-top: 5px;
}
#productSubAction{
  width: 5%;
  color:white;
  float: left;
  text-align: center;
  cursor: pointer;
  padding-bottom: 5px;
  font-size: 13px;
  padding-left: 2px;
  margin-bottom: 5px;
  margin-top: 5px; 
}
#productSubAction:hover{
  color: #FFD700;
}

#productNoParts{
  width: 75%;
  float: left;
  text-align: left;
  padding: 2px;
  padding-left: 10px;
  font-style: italic
}

@media screen and (max-width: 768px) {
  #productsList{
    width: 98%;
    margin-left: 1%;
    margin-top: 15px;
  }
  #pausedListTitle{
    width: 35%;
    float: left;
  }
  #productsName{
    width: 34%;
  }
  #productsSmall{
    width: 9%;
  }
  #productsCategory{
    width: 24%;
  }
  #productSubAction{
    display: none
  }
  #productDetailLabel{
    width: 25%;
  }
  
  #productDetail{
    width: 75%;
  }
}