.auxiliariesTitle{
  font-style: italic;
}
.pausedListAuxiliary{
  margin-left: 4%;
}

.auxiliaryItem{
  width: 100%;
  float: left;
  font-weight: 200;
  background: rgba(255, 255, 255, 0.1);
  font-size: 11px !important;
  border-bottom: 0.5px solid #00253D;
  border-radius: 2px;
  color: white;
}
.auxiliaryItem:hover{
  background: rgba(255, 255, 255, 0.135);
}
#auxiliaryReference{
  width: 15%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliaryName{
  width: 30%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliarySKU{
  width: 10%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliarySerial{
  width: 5%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliaryDescription{
  width: 37.5%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliaryEdit{
  width: 5%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliaryProduct{
  width: 35%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliariesList{
  width: 55%;
  margin-left: 1.5%;
  margin-top: 5px;
  font-size: 12px;
  float: left;
}

#auxiliaryExplaination{
  text-align: left;
  width: 75%;
  float: left;
  padding: 5px;
  padding-left: 15px;
  font-style: italic
}

#auxiliaryEditDetailDelete{
  color: red;
  cursor: pointer;
  text-align: right;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  float: right;
}
#auxiliaryEditDetailDelete:hover{
  background: red;
  border-radius: 2px;
  color: white;
}
.activeDelete{
  background: red !important;
  border-radius: 2px !important;
  color: white !important;
}

#aux{
  width: 75%;
  margin-right: 25%;
  float: left;
  text-align: left;
  font-weight: 200;
  padding-left: 10px;
}
#auxRef{
  width: 20%;
  float: left;
}
#auxName{
  width: 45%;
  float: left;
}
#auxSKU{
  width: 15%;
  float: left;
}
#auxSeri{
  width: 20%;
  float: left;
}

@media screen and (max-width: 768px) {
  .auxiliariesTitle{
    width: 100%;
    font-size: 11px !important;
    font-weight: 300;
  }
  #auxiliariesList{
    width: 96%;
    margin-left: 2%;
  }
  .auxiliaryItem{
    width: 100%;
    font-weight: 300;
    font-size: 10px !important;
  }
  #auxiliaryExplaination{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  
 
  #auxiliariesNumber{
    width: 30%;
  } 
  #auxiliariesProduct{
    width: 30%;
  }
  #aux{
    width: 100%;
  }
  #auxName{
    width: 45%;
  }
  #auxSKU{
    width: 20%;
  }
  #auxSeri{
    width: 25%;
  }

}
