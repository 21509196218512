#productsTitle{
  line-height: 45px;
  margin-top: 15px;
  display: block;
  height: auto;
  overflow: auto;
  text-align: left;
  padding-left: 15px;
  font-weight: normal;
  color: white;
  font-size:24px;
  width: 90%;
  margin-left:5%;
  border-radius: 15px;
  margin-bottom: 15px;
}
#productAction{
	position: absolute;
	width: 40px;
	text-align: center;
	color: rgb(255,215,0);
	padding-left: 20%;
    margin-top: -50px;
    font-size: 20px;
	cursor: pointer;
}
#productAction:hover{
	color: rgb(0,139,139);
}