#creditScore{
  margin-top: 15px;
  width: 94%;
  margin-left: 3%;
  float: left;
}
#creditScoreList{
  
  width: 100%;
  float: left;
}
#cat{
  width: 17.5%;
  float: left;
  text-align: center;
  font-weight: 200;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.20);
  padding: 5px;
  font-size: 11px;
}
#que{
  width: 42.5%;
  float: left;
  text-align: left;
  padding-left: 5px;
  padding-top: 5px;
  font-weight: 200;
  font-size: 12px;
}
#ans{
  width: 30%;
  float: left
}

#next{
  width: 9%;
  float: left;
  cursor: pointer;
  font-size: 11px;
  height: 30px;
  font-weight: bold;
  margin-left: 1%;
  border-radius: 2px;
  text-align: center;
  background: rgb(255,215,0);
  border: 0.5px solid transparent;
  padding: 5px;
  color: #00253D
}

#done{
  width: 9%;
  float: left;
  font-size: 11px;
  height: 30px;
  font-weight: bold;
  border-radius: 2px;
  margin-left: 1%;
  text-align: center;
  border: 0.5px solid transparent;
  background: #335164;
  padding-top: 7px;
  color: white
}

.modalInput{
  border: 0.5px solid white;
  height: 30px;
  border-radius: 2px;
  font-size: 12px;
  margin-bottom: 2px;
}
#questionnaireFill{
  width: 90%;
  margin-left: 5%;
  float: left   
}
#answerPicture{
  text-decoration: underline;
  cursor: pointer
}
#answerPicture:hover{
  color: white;
  text-decoration: none
}
#createCreditScore{
  border-bottom: 0.5px solid white;
  padding-bottom: 30px;
  margin-bottom: 15px;
  width: 100%;
  float: left  
}

#questionnaireChoice{
  width: 100%;
  margin-top:5px;
  margin-bottom: 5px;
  float: left   
}

#questionnaireChoiceItem{
  width: 49%;
  margin-right: 1%;
  margin-bottom: 10px;
  float: left;
  background: rgba(255, 255, 255, 0.30);
  padding:5px;
  font-size: 13px;
  border-radius: 2px;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
}
#questionnaireChoiceItem:hover{
  background: rgba(255, 255, 255, 0.20);
  color: #FFD700
}

.chosenQuestionnaire{
  background: white !important;
  color: #00253D !important;
}

#fullQuestion{
  width: 100%;
  float: left;
}

#creditResultsSubTitle{
  width: 95%;
  float: left;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
  padding-left: 10px;
  font-style: italic;
  text-align: left
}


#answerWrapper{
  width: 100%;
  font-weight: normal;
  padding-bottom: 2.5px;
  padding-top: 2.5px;
  border-radius: 2px;
  font-size: 11px;
  float: left;
  text-align: left;
}
#answerWrapper:hover{
  background: rgba(255, 255, 255, 0.15)
}

#answerCategory{
  width: 10%;
  background: rgba(255, 255, 255, 0.4);
  float: left;
  text-align: center;
}
#answerCategory{
  width: 17%;
  background: rgba(255, 255, 255, 0.3);
  font-weight: 200;
  font-size: 10px;
  border-radius: 2px;
  padding: 2px;
  float: left;
  text-align: center;
}
#answerQuestion{
  width: 50%;
  padding-left: 10px;;
  font-style: italic;
  float: left;
}
#answerAnswer{
  width: 28%;
  margin-right: 2%;
  padding-right: 5px;
  text-align: right;
  float: left;
}
.answerAnswer{
  color: #007FBA !important;
}
#aD{
  width: 3%;
  float: left;
  cursor: pointer;
}

#aC{
  width: 20%;
  margin-right: 2%;
  float: left;
  border: 1px solid white;
  padding: 5px;
  margin-bottom: 5px;
  text-align: center;
  cursor: pointer;
}

#aC:hover{
  background: rgba(255, 255, 255, 0.4)
}

#qCompleted{
  color: white
}

#creditScoreItem{
  width: 100%;
  margin-bottom: 5px;
  border-radius: 2px;
  float: left;
  background: rgba(255, 255, 255, 0.15);
  padding:5px;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  padding-left: 10px;
  cursor: pointer;
}

#creditScoreItem:hover{
  box-shadow: 0 0 5px rgba(255, 255,255,1);
}

#creditScoreItemDate{
  width: 17.5%;
  float: left;
  font-style: italic
}

#creditScoreItemName{
  width: 80%;
  float: left;
  font-style: italic
}

#qCompleted{
  color: white;
  border: 0.5px solid white;
  width: 100%;
  float: left;
  text-align: left;
  padding: 5px;
  padding-left: 15px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: normal
}