
#dash_menu{
  line-height: 35px;
  margin-top: 75px;
  display: block;
  height: auto;
  overflow: auto;
  font-size:14px;
  width: 97%;
  float: left;
  margin-left: 1.25%;
  margin-bottom: 15px;
}

#dash_menu_item{
  background: rgba(255, 255, 255, 0.15);
  width: 10%;
  margin-right:0px;
  text-align: left;
  padding-left:15px;
  float: left;
  color: white;
  cursor: pointer;
}
#dash_menu_item:hover{
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}

.active_dash_menu{
  color: #00253D !important;
  background: #FCC30B !important;
}


@media screen and (max-width: 768px) {
  #dash_menu{
    margin-top: 15px;
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 15px;
  }

  #dash_menu_item{
    width: 33%;
    padding-left:15px;
  }
}