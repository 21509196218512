

#questionnaireTitle{
  text-align: left;
  padding: 10px;
  font-weight: normal;
  color: white;
  font-size:16px;
  width: 62.5%;
  float: left;
}
#questionnaireEntity{
  text-align: left;
  padding: 10px;
  font-weight: normal;
  font-style: italic;
  color: white;
  font-size:16px;
  width: 15%;
  float: left;
}

#questionnaireWrapper{
  width: 75%;
  margin-right: 25%;
  margin-bottom: 15px;
  padding: 5px;
  float:left;
}
#questionnaireList{
  padding-top: 15px;
  width: 97%;
  margin-left: 1.5%;
  float: left;
}
#questionnaireItem{
  width: 65%;
  margin-right: 35%;
  float: left;
  font-weight: normal;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 2px;
  border-radius: 3px;
  color: white;
}
.activeQuestionnaire{
  background: rgba(255, 255, 255, 0.1) !important;

}

#questionnaireDetails{
  text-align: center;
  padding: 10px;
  font-weight: normal;
  cursor: pointer;
  color: white;
  font-size:16px;
  width: 7.5%;
  float: right;
}

#questionnaireAction{
  position: absolute;
  width: 40px;
  text-align: center;
  color: rgb(255,215,0);
  padding-left: 20%;
  margin-top: -50px;
  font-size: 20px;
  cursor: pointer;
}
#questionnaireAction:hover{
  color: rgb(0,139,139);
}

.questionnairesTitle{
  width: 100%;
  float: left;
  font-weight: normal;
  background: rgba(255, 255, 255, 0.95);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #00253D;
  padding: 5px;
}

#newQuestionnaireLabel{
  padding-bottom: 15px;
  padding-top: 15px;
}

#questionWrapper{
  width: 100%;
  float: left;
}
#questionDisplay{
  width: 90%;
  float: left;
}
#questionSelecter{
  width: 5%;
  float: left;
}

.option{
	font-size: 11px;
	padding: 3px;
  padding-left: 5px;
  border-radius: 2px;
  padding-top: 5px;  cursor: pointer;
  color: white !important;
  background: #1A3B51 !important;
  border: 0.5px solid #1A3B51 !important;
  float: left;
  width: 49%;
  font-style: italic;
	margin-bottom: 2px;
  margin-top: 2px;
	height: 25px;
}

#newDealExpl{
  font-weight: 200;
  width: 100%;
  float: left;
  padding-left: 10px;
  padding-top: 15px;
  padding-right: 35px;
  font-size: 12px;
  font-style: italic
}


@media screen and (max-width: 768px) {

  #questionnaireItem{
    width: 100%;
    margin-right: 0%;
  }

  #questionnaireWrapper{
    width: 98%; 
    margin-left: 1%;
    margin-right: 1%;
  }

}