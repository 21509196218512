.messageMenu{
  margin-top: 25px;
  margin-left: 1.5%;
  width: 10%;
  float: left;
}
.messageMain{ 
  margin-top: 10px;
  width: 88.5%;
  float: left;
}
.editMessageInput{
  width: 100%;
  border: 0.5px solid white;
  font-size: 12px;
  height: 75px;
  margin-top: -10px;
  color: #00253D
}
#messageMenuItem{
  width: 100%;
  float: left;
  height:90px;
  box-shadow: 0 0 10px rgba(1, 130,166,0.2);
  text-align: center;
  padding-top: 20px;
  font-size: 16px;
  color: white ;
  background: rgba(255, 255, 255, 0.25);
  font-weight: bold;
  cursor:pointer;
  margin-top:5px;
  padding-left:15px;
  padding-right:15px;
}

#messageMenuItem:hover{
  background: rgba(255, 255, 255, 0.5);
  color: #00253D
}

.activeMessageMenuItem{
  background:rgb(0,139,139) !important;
  color: white !important
}

.messageSelector{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
	border: 0.25px solid transparent;
  color: white;
  width: 20%;
  margin-right: 0.25%;
  margin-bottom: 5px;
  float: right;
  font-size: 11px;
  padding: 4px;
  height: 35px;
  text-align: left;
  font-weight: normal;
}
.messageDateSelector{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
	border: 0.25px solid transparent;
  color: white;
  font-size: 11px;
  padding: 4px;
  height: 35px;
  text-align: left;
  font-weight: normal;
}
.messageDateSelectorWrapper{
  width: 10%;
  float: right;
  margin-right: 1%;
  margin-left: 0.25%;
}
.statusSelector{
  width: 10%;
  float: right;
}