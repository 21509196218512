#itemLineWrapper{
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  float: left;
  font-size: 12px;
  padding: 7px;
  margin-top: 5px;
  margin-bottom: 5px;
}
#itemLine{

}
#itemLineDes{

}
#itemLineExpl{

}
#itemLineExplLabel{
  width: 50%;
  float: left;
  font-style: italic
}
#itemLineExplData{
  width: 50%;
  float: left;
  text-align: right;
  padding-right: 10px;
}
#itemLineP{

}
#itemLinePLabel{
  width: 50%;
  float: left;
  font-style: italic
}
#itemLinePData{
  width: 50%;
  float: left;
  text-align: right;
  padding-right: 10px;
}
#itemLinePSep{
  border-bottom: 1px solid white;
  margin-left: 50%;
  padding-top: 5px;
  width: 50%;
  float: left;
}
#itemLinePTotal{
  margin-left: 50%;
  width: 50%;
  float: left;
  padding-top: 5px;
  text-align: right;
  padding-right: 10px;
  color: rgba(255,215,0, 1);
}
.lastItemWrapper{
  width: 74%;
  background: rgba(255, 255, 255, 0.1);
  float: left;
  font-size: 12px;
  padding: 7px;
  margin-top: 5px;
  margin-bottom: 5px;
}
#lastItemLabel{
  width: 10%;
  float: left;
  font-style: italic
}
#lastItemDate{
  width: 25%;
  float: left;
}
#lastItemAmount{
  width: 30%;
  float: left;
  text-align: right; 
  padding-right: 10px;
}

#itemLineRemove{
  position: absolute;
  right: 5px;
  padding: 2px;
  border: 0.5px solid white;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
#itemLineRemove:active{
  color: rgba(255,215,0, 1);
  border: 0.5px solid rgba(255,215,0, 1);
  background: rgba(255, 255, 255, 0.1);
}
.itemLineRemoveActive{
  color: rgba(255,215,0, 1) !important;
  border: 0.5px solid rgba(255,215,0, 1) !important;
  background: rgba(255, 255, 255, 0.1) !important;
}