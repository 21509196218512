#top{
  background:transparent;
  pointer-events: none;
}

#topLeft{
  background: #00253D;
  pointer-events: auto 
}
.activeHeader{
  color: #Fdcc1e;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  #top{
    background:transparent;
    pointer-events: auto;
  }
}
