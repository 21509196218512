.auxiliaryPricingsTitle{
  font-style: italic;
  width: 100%;
  float: left;
}


.auxiliaryPricingItem{
  width: 100%;
  float: left;
  font-weight: 200;
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
  font-size: 11px !important;
  font-size: 11px !important;
  border-bottom: 0.5px solid #00253D;
  color: white;
}
.auxiliaryPricingItem:hover{
  background: rgba(255, 255, 255, 0.135);
}
#auxiliaryPricingReference{
  width: 8%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliaryPricingType{
  width: 6%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliaryPricingDeal{
  width: 18%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliaryPricingCost{
  width: 9%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

#auxiliaryPricingAux{
  width: 14%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliaryPricingName{
  width: 12%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliaryPricingEdit{
  width: 4%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliaryPricingProduct{
  width: 20%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#auxiliaryPricingsList{
  width: 85%;
  margin-left: 1.5%;
  margin-top: 5px;
  font-size: 12px;
  float: left;
}

#auxiliaryPricingExplaination{
  text-align: left;
  width: 75%;
  float: left;
  padding: 5px;
  padding-left: 15px;
  font-style: italic
}

#auxiliaryPricingEditDetailDelete{
  color: red;
  cursor: pointer;
  text-align: right;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  float: right;
}
#auxiliaryPricingEditDetailDelete:hover{
  background: red;
  border-radius: 2px;
  color: white;
}


@media screen and (max-width: 768px) {
  #auxiliaryPricingsList{
    width: 97%;
  }
  .auxiliaryPricingsTitle{
    width: 100%;
    font-size: 11px !important;
    font-weight: 300;
  }
  .auxiliaryPricingItem{
    width: 100%;
    font-weight: 300;
    font-size: 10px !important;
  }
 
  #auxiliaryPricingsNumber{
    width: 30%;
  } 
  #auxiliaryPricingsProduct{
    width: 30%;
  }

}
