

#login_zone{
  width: 300px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 150px;
  color: white;
  z-index: 10
}

#loginHeader{
  font-size: 18px;
  color: #00253D;
  padding: 25px;
  padding-bottom: 5px;
  background: rgba(255, 255, 255, 0.3);
  font-weight: normal;
}
#loginForm{
  background: rgba(255, 255, 255, 0.3);
  padding: 25px;
  padding-top: 5px;
}
#resetInstructions{
  background: rgba(255, 255, 255, 0.3);
  padding: 25px;
  padding-top: 5px;
  font-size: 14px;
}
#resetInstructionsLabel{
  font-style: italic;
  padding-bottom: 15px;
}
#forgotPwd{
  padding-left: 15px;
  font-size: 14px;
  margin-top: -10px;
  font-weight: normal;
  font-style: italic;
  cursor: pointer;
}
#forgotInstructions{
  font-style: italic
}
#forgotPwd:hover{
  color: #F7F00F
}
#donate_label{
  color: #F7F00F;
  font-size: 12px;
  font-style: italic
}

#login_input{
    border: 1px solid  white;
    border-radius: 3px;
    margin-bottom: 15px;
}

#loginIcon{
  background: white;
  height: 35px;
  color: #00253D;
  text-align: center; 
  cursor: pointer;
  padding-top: 6px;
  font-size: 18px;
  float: left;
  width: 15%;
}

#loginLine{
  float: left;
  width: 100%;
}

#register_space{
  width: 50%;
  margin-left: 25%;
  text-align: left;
  margin-bottom: 0px;
}
.signin_input{
  border-radius: 3px;
  border: 1px solid white;
  margin-top:0px;
  margin-bottom: 0px;
}
.designed_label{
  margin-top: 0px;
  margin-bottom: 0px;
  padding:0px;
}

#login_button{
  background: #00253D;;
  border: 1px solid #00253D;;
  color: #fff;
  padding-left:25px;
  margin-top: 5px;
  padding-right:25px;
  height: 40px;
  font-size:14px;
}
#login_button:hover{
  background: rgb(52, 235, 128);
  border: 1px solid rgb(52, 235, 128);
}

#loginForm{
  margin-bottom: 15px;
}
#loginError{
  font-size: 14px;
  text-align: left !important;
  color: rgb(197, 28, 28);
  background: rgba(197, 28, 28, 0.4);
  padding: 8px;
}


.carItem{
  width: 100%;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  z-index: -1;
}

.carMain{
  height: 100%;
  margin-top: -95px;
}

@media screen and (max-width: 768px) {

  .carItem{
  }

  .carMain{
    height: auto;
    margin-top: -95px;
  }

  .responsive {
    height: 600px !important;
  }

}