#convoItemWrapper{
  width: 100%;
  float: left;
  margin-bottom: 5px;
}

.fromAgent{
  width: 50% !important;
  float: left !important;
}

.fromAdmin{
  width: 50% !important;
  float: left !important;
  margin-left: 50% !important;
}

.TxtFromAgent{
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.20);
  padding: 5px;
}

.TxtFromAdmin{
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.10);
  padding: 5px;
}

#convoItemAuthor{
  font-style: italic;
  padding: 2px;
  width: 50%;
  float: left;
}
#convoItemDate{
  width: 50%;
  font-style: italic;
  float: left;
  padding: 2px;
  text-align: right;
}
#convoItemTxt{
  width: 100%;
  padding: 5px;
  padding-left: 10px;
  float: left;
}
#conversationWrapper{
  width: 98%;
  margin-left: 1%;
  margin-top: 10px;
  float: left;
  font-size: 12px;
}
#addToConversation{
  width: 100%;
  float: left;
  margin-top: -20px;
  margin-bottom: 10px;
}
#convoReply{ 
  padding: 5px;
  font-size: 12px;
  width: 15%;
  margin-left: 69%;
  color: white;
  background: rgb(0,139,139);;
  border: 1px solid transparent;
}
#convoSend{
  padding: 5px;
  font-size: 12px;
  margin-left: 85%;
  border-radius: 3px;
  width: 15%;
  color: white;
  background: rgb(0,139,139);;
  border: 1px solid transparent;
}
.convoInput{
  background: white;
  width: 50%;
  margin-top: 5px;
  border-radius: 5px;
  color: #00131F;
  border: 05px solid white;
  height: 60px;
  font-size: 12px;
  margin-left: 50%;
}
#convoClose{
  color: white;
  background: #1A3B51;
  border: 0.5px solid rgba(255, 255, 255, 0.10);
  border-radius: 3px;
  width: 5%;
  float:right;
  padding: 5px;
  margin-left: 1%;
}
#convoClose:hover{
  border: 0.5px solid white;
}
#convoTitle{
  width: 62%;
  float: left;  
}
#ticketAction{
  background: rgba(255, 255, 255, 0.15);
  width: 15%;
  float: left;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
  margin-left: 1%;
  font-size: 12px;
  color: #FFD700
}
#ticketAction:hover{
  background: #FFD700;
  color: #00253D
}

#closedOn{
  font-style: italic;
  font-size: 12px;
  padding-top: 5px;  width: 15%;
  float: left;
}

@media screen and (max-width: 768px) {
  .convoInput{
    width: 100%;
    margin-left: 0%;
  }
  #convoReply{ 
    width: 33%;
    margin-left: 33%
  }
  #convoSend{
    margin-left: 67%;
    width: 33%;
  }
  #convoClose{
    border: 1px solid white;
    width: 33%;
    margin-left: 1%;
  }
  .fromAgent{
    width: 90% !important;
    margin-bottom: 10px;
  }
  
  .fromAdmin{
    width: 90% !important;
    margin-left: 10% !important;
    margin-bottom: 10px;
  }

  #convoTitle{
    width: 50%;
  }
  #ticketAction{
    width: 20%;
    margin-left: 0.5%;
    font-size: 11px;
  }
}