#templateLargeWrapper{
    float:left;
    width: 98.5%;
}

#templateSmallWrapper{
    float:left;
    width: 20%;
}

#templateAddOne{
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 60px;
    padding-top:100px;
    padding-bottom:100px;
    font-size: 20px;
    margin-top: 30px;
    cursor: pointer;
}
#templateAddOne:hover{
  box-shadow: 0 0 10px rgba(1, 130,166,0.2);
}

#templatePaused{
  border: 0.5px solid red;
  color: red;
  padding: 3px;
  padding-left: 10px;
  margin-bottom: 5px;
  width: 25%;
  float: left;
  margin-right: 75%
}


.templatesTitle{
  font-size: 11px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  width: 100%;
  text-align: left;
  float: left;
}

.templateItem{
  width: 100%;
  float: left;
  text-align: left;
  font-weight: 200;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 1px;
  border-radius: 2px;
  color: white;
}

#templatesSmall{
  width: 8%;
  float: left;
  padding: 5px;
  text-align: center;
  padding-left: 10px;
}
#templatesLarge{
  width: 15%;
  float: left;
  padding: 5px;
  padding-left: 15px;
}
#templateWarning{
  font-size: 12px;
  font-style: italic;
  background: rgba(220,20,60, 1);
  color: white;
  width: 100%;
  float: left;
  padding: 5px;
}
#templatesExtraLarge{
  width: 49%;
  float: left;
  font-style: italic;
  padding: 5px;
}
#templatesMedium{
  
  width: 19%;
  float: left;
  font-size: 11px;
  padding: 5px;
  padding-left: 10px;
}
#templateShowDetails{
  text-align: left;
  padding: 10px;
  width: 100%;
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  float: left;
  font-weight: normal
}
#templateShowDetailsL{
  width: 25%;
  padding: 3px;
  float: left;
}
#templateShowDetailsD{
  width: 75%;
  float: left;
  padding: 3px;
  color: rgb(0, 140, 186);

}
#templatesExtraSmall{
  width: 3%;
  float: left;
  text-align: center;
  padding: 5px;
}
#templatesList{
  width: 98.5%;
  margin-top: 15px;
  float: left;
  font-size: 12px;
}

#templateAction{
	position: absolute;
	width: 15px;
	text-align: center;
	color: rgb(255,215,0);
	margin-left: 33%;
  margin-top: -35px;
  font-size: 20px;
	cursor: pointer;
}
#templateAction:hover{
	color: rgb(0,139,139);
}

@media screen and (max-width: 768px) {
  #templateAction{
    padding-left: 80%
  }
}