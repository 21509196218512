#agentBackground{
  background: rgb(0,37, 61);
  width: 100%;
  float: left;
  padding-top:60px;
  padding-bottom:30px;
  text-align: left;
  color: #fff;
  font-weight: bold;
}

#agentRow{
	width: 97%;
	margin-left: 1.5%;
	float: left
}
.agentDetailsLabel{
	width: 30%;
	float: left;
	font-size: 12px;
	padding-left: 10px;
	padding-top: 12px;
}
.agentDetailsInput{
	border: 0.5px solid white;
	border-radius: 2px;
	width: 70%;
	margin-bottom: 5px;
	padding-left: 10px;
	margin-top: 5px;
	height: 30px;
	font-size: 12px;
	float: left;
}

#halfGraph{
  width: 50%;
  float: left;
}

#agentForm{
	width: 95%;
	margin-left: 2.5%;
	float: left;
}

#formSectionTitle{
 	color: rgb(0,139,139);
	padding: 5px;
	width: 100%;
	float: left;
	 padding-left: 10px;
	border-bottom: 0.5px solid rgb(0,139,139);
	margin-bottom: 10px;
}

#actionWrapper{
	width: 100%;
	padding-top: 30px;
	float: left;
}

.agentTaskTitle{
	margin: 30px;
	margin-left: 15px;	
	margin-bottom: 0px;
}
.agentNakedTitle{
	margin: 30px;
	margin-bottom: 0px;
}
#agentProfile{
	width: 100%;
	background: rgba(255, 255, 255, 0.15);
  min-height: 350px;
  border-radius: 3px;
	font-size: 12px;
  padding: 15px;
  padding-top: 10px;
}
#agentAnalysis{
	background: rgba(255, 255, 255, 0.15);
	min-height: 350px;
  padding: 15px;
  border-radius: 3px;
  padding-right: 10px;
  padding-top: 5px;
}
#agentPerformance{
	background: rgba(255, 255, 255, 0.15);
  min-height: 350px;
  width: 100%;
  float: left;
  padding: 15px;
  padding-right: 10px;
  padding-top: 5px;
}
#agentSection{
	width: 50%;
	float: left;
}
#agentFigure{
  padding-left: 30px;
  color: rgba(255,215,0, 1);
}
#agentDefaultFigure{
  padding-left: 30px;
  color: red
}
#agentLargeSection{
	margin-left: 1.5%;
	margin-right: 1.5%;
	width: 97%;
	float: left;
}
#agentShortSection{
	width: 28.5%;
	margin-left: 1.5%;
	float: left;
}
#agentLongSection{
	width: 67%;
  margin-left: 1.5%;
  border-radius: 3px;
	float: left;
}
#agentSectionTitle{
	color: rgba(255,215,0, 1);
	font-size: 20px;
	margin-bottom: 10px;
	text-align: left;
}
#agentAnalysisLink{
  text-align: center;
  cursor: pointer;
  width: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
  float: left;
}
#agentAnalysisLink:hover{
  background: rgba(255, 255, 255, 0.1);
	color: rgba(255,215,0, 1);
}
#agentAnalysisTitle{
	color: rgba(255,215,0, 1);
	font-size: 20px;
  margin-bottom: 15px;
  padding-top: 5px;
  text-align: left;
  width: 40%;
  float: left;
}

#agentSubSectionTitle{
  background: rgba(255, 255, 255, 0.15);
  float: left;
  margin-top: 10px;
  text-align:left;
  padding-left: 15px;
  border-radius: 3px;
  padding-top: 7px;
  font-size: 16px;
  font-weight: normal;
  padding-bottom: 7px;
  color: white;
  cursor: pointer
}

#agentUP{
  width: 50%;
  float: left;
}
.white{
  text-align: right;
  padding-right: 15px;
}

.red{
  text-align: right;
  padding-right: 15px;
  color: red;
}

#warningTransferClients{
  font-size: 13px;
  width: 100%;
  float: left;
  margin-top: 5px;
  color: red; 
}
#transferclient{
  width: 30%;
  float: left;
  padding:3px;
  padding-top: 2px;
  color: white;
  font-weight: normal;
  font-size: 13px;
  margin-bottom: 1px;
  margin-top: 1px;
}

#agentReconcile{
  background: rgb(0, 139, 139);
  width: 7.5%;
  margin-left: 1%;
  text-align: center;
  margin-top: 3px;
  padding: 4px;
  font-size: 11px;
  float: left;
  color: white;
  cursor: pointer;
}
#agentReconcile:hover{
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}
#agentPaymentList{
  width: 91.5%;
  float: left;
}

.manager{
	width: 100% !important;
}

.subleft{
	width: 99% !important;
}

.subright{
	width: 99% !important;
	margin-left: 1% !important;
}

#agentSubSectionTitle:hover{
  background: rgba(255, 255, 255, 0.25)
}
.activeSubSection{
  background: rgba(255, 255, 255, 0.25) !important;
}
.activeSubHalfSection{
  color: rgb(255,215,0) !important;
}

#agentAction{
	width: 30%;
	float: left;
	text-align: center;
	margin-left: 1.5%;
	margin-right: 1.5%;
  margin-bottom: 10px;
  border-radius: 3px;
	font-size: 11px;
	background:#1A3B51;
	padding: 5px;
	cursor: pointer;
	color: white;
	border: 0.5px solid transparent
  }
  #agentActionZone{
	width: 100%;
	margin-top: 7.5px;
	float: left;
  }
  
#agentAction:hover{
	color: rgba(255,215,0, 1);
	background:rgba(255, 255, 255, 0.2);
}

.agentEditInput{
	font-size: 11px;
	padding:3px;
	padding-left: 5px;
	border: 0.5px solid white;
	background: white;
	width: 100%;
  margin-bottom: 2px;
  border-radius: 2px;
	height: 24px;
	color: #191970;
}

.agentEditInputShort{
	font-size: 11px;
	padding:3px;
	background: white;
	width: 32%;
	float: left;
	border: 1px solid white;
  border-radius: 2px;
	margin-bottom: 2px;
	height: 24px;
	color: #191970;
}
.agentEditInputHalf{
	font-size: 11px;
  padding: 3px;
  padding-left: 5px;
	background: white;
	width: 60%;
	float: left;
	border: 1px solid white;
  border-radius: 2px;
	margin-bottom: 2px;
	height: 24px;
	color: #191970;
}
.agentEditInputLabel{
	font-size: 11px;
  padding:3px;
  font-weight: 300;
	padding-top: 5px;
	padding-left: 0px;
  border-radius: 2px;
	float: left;
	width: 40%;
	margin-bottom: 2px;
	height: 24px;
	color: white;
}
.agentEditInputLong{
	font-size: 11px;
	padding:3px;
	padding-left: 10px;
  border-radius: 2px;
	float: left;
	background: white;
	border: 1px solid white;
	width: 66%;
	margin-left: 2%;
	margin-bottom: 2px;
	height: 24px;
	color: #191970;
}

.agentMessageInput{
	font-size: 11px;
	padding:3px;
	padding-left: 5px;
  background: white;
  border-radius: 2px;
	border: 0.5px solid white;
	width: 100%;
	margin-bottom: 2px;
	height: 150px;
	color: #191970;
}

#disabled{
	width: 100%;
	margin-top: 24px;
	font-size: 24px;
	float: left;
	color: rgb(220,20,60)
}

#agentGraphWrapper{
	padding: 0px;
	margin-top: -15px
}

#agentGraphTitle{
	font-size: 12px;
  padding-bottom: 5px;
  width: 100%;
  float: left;
	font-style: italic;
	font-weight: normal
}
.agentMarginTop{
  margin-top: 5px;
}

#agentGraphSection{
	width: 50%;
	float: left;  
}

#perfGraph{
  width: 100%;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
	float: left;
}
#perfGraphWrapper{
  width: 50%;
	float: left;
}
#perfGraphTitle{
  width: 100%;
  font-style: italic;
	float: left;
}
#agentViewSelect{
  width: 50%;
	float: left;
}

#performanceSelect{
  
  width: 50%;
	float: right;
}

#agentViewMetrics{
  width: 97%;
  margin-left: 3%;
  margin-top: 10px;
	float: left;
}

.clear{
 background: #26455A !important;
}
.agentOption{
  color: white;
  background: #26455A;
  font-style: italic;
  font-size: 11px;
}
.agentOptionHeader{
  
  color: white;
  background: #80929E;
  font-style: italic;
  font-size: 11px;
}
.agentOptionLight{
  color: white;
  background: #26455a;
  font-style: italic;
  font-size: 11px;
}
.sel{
  padding-top: 7px;
  padding-left: 7px;
  margin-left: 30%
}

.sel1{
  margin-left: 8%;
}
.marj{
  margin-right: 1% !important;
}
.adj{
  width: 39% !important;
}
.agentSelector{
  width: 24.5% !important;
  float: right !important;
  margin-left: 0.5% !important;  
}
.agentSelectorDate{
  width: 24.5%;
  margin-left: 0.5%;
  float: right; 
}

.allocateAgents{
  width: 10%;
  text-align: left;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: normal;
  padding-left: 15px;
  background: #314F63;
  margin-right: 80%;
  cursor: pointer;
  float: left;
}
.allocateAgents:hover{
  color: rgba(255,215,0, 1);
  background: #516A7B
}
.noManagedAgents{
  font-style: italic;
  padding-left: 5px;
  width: 100%;
  float: left;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: normal;
  font-size:  12px
}

#manageActionTitle{
  width: 100%;
  float: left;
  background: #314F63;
  font-weight: normal;
  padding: 5px;
  font-style: italic;
  margin-top: 15px;
  margin-bottom: 5px;
  padding-left: 15px;
}

.managerList{
  width: 100%;
  float: left;
  font-weight: normal !important;
  font-size: 12px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  padding: 5px;
  cursor: pointer;
}
.managerList:hover{
  background: rgba(255, 255, 255, 0.1)
}
#mListNumber{
  padding-left: 10px;
  width: 20%;
  float: left;
}
#mListFirstName{
  font-weight: 300;
  width: 25%;
  float: left;
}
.agentHalf{
  width: 50%;
  float: left
}
#mListLastName{
  font-weight: 300;
  width: 25%;
  float: left;
}
#mListRole{
  font-weight: 300;
  width: 20%;
  float: left;
}
#mListStatus{
  text-align: center;
  width: 10%;
  float: left;
}

#analysisColumn{
  width: 20%;
  float: left;
  font-weight: normal;
  font-size: 10px;
}

.analysisHeader{
  background: rgba(255, 255, 255, 0.1);
  padding-top: 5px;
  width: 98%;
  margin-left: 1%;
  margin-bottom: 1px;
  float: left;
  padding-bottom: 5px;
}

#analysisPoint{
   
  width: 100%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

#AgentSubTitle{
  width: 99%;
  float: left;
  color: rgba(255,215,0, 1);
  text-align: left;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  #agentSection{
		width: 100%;
  }
  .allocateAgents{
    width: 30%;
    margin-right: 70%;
  }
  #agentViewSelect{
    width: 80%;
    margin-top: -10px;
  }
  #agentAnalysisLink{
    width: 10%;margin-top: -10px;
  }
	#agentShortSection{
		width: 98%;
		margin-left: 1%;
		margin-right: 1%;
	}
	#agentLongSection{
		width: 98%;
		margin-left: 1%;
  }
  #agentAnalysisTitle{
    width: 100%;
  }
  
  #agentAnalysis{
    min-height: 100%;
    padding: 10px;
    padding-right: 10px;
    padding-top: 5px;
  }

  #agentAnalysis{
    min-height: 500px;
    margin-top: 15px;
  }

  #agentGraphSection{
    width: 100%;
  }
  #agentGraphWrapper{
    width: 100%;
  }

  .subleft{
    width: 100% !important;
  }
  
  .subright{
    width: 100% !important;
    margin-left: 0% !important;
  }

}


.com{
  margin-left: 0% !important;
  width: 100% !important;
}