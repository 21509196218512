
#searchMenu{
  width: 97%;
  float: left;
  margin-top: 25px;
  padding: 5px;
  margin-left:1.25%;
  margin-bottom: 15px;
}
#searchMenuItem{
  font-size: 15px;
  padding-top: 5px;
  font-weight: normal;
  width: 16.66%;
  float: left;
  height: 30px;
  cursor: pointer;background: rgba(255, 255, 255, 0.1)
}
.inactive{
  text-align: left !important;
  padding-top: 2px !important;
  background: rgba(255, 255, 255, 0.15) !important;
}
#searchOutcome{
  width: 100%;
  float: left;
}
#searchOutcomeNumbers{
  width: 100%;
  float: left;
  font-style: italic;
  font-size: 12px;
  text-align: right;
  padding-right: 15px;
  margin-top: -15px;
  font-weight: normal
}
#searchMenuItem:hover{  
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.15)
}

.activeMenu{
  background: #008B8B !important;
  color: white !important
}

#searchSection{
  width: 97%;
  margin-left:1.5%;
  float: left;
}
.activeSearchSection{
  background: rgba(255, 255, 255, 0.10) !important;
  width: 100%;
  float: left;
  padding: 5px;
  padding-top: 10px;  
  border-radius: 3px;
}
#searchSectionTitle{
  cursor: pointer;
  color: #00253D;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.05);
  padding:2.5px;
  margin:5px;
  color: white;
}
.activeSearchSectionTitle{
  cursor: pointer !important;
  color: #00253D !important;
  border-radius: 3px !important;
  background: rgb(0,139,139) !important;
}
.dateSearchZone{
  width: 16.2%;
  float: left;
  height: 22px;
  color: rgb(0,139,139);
  margin-bottom: 2px;
  padding-left:0px;
  margin-left: 0.2%;
  margin-right: 0.2%;
  margin-top: 1px;  
}

.dateSearchInput{
  width: 100%;
  float: left;
  background: rgba(255, 255, 255, 0.95);
  height: 22px;
  color: rgb(0,139,139);
  border: 0.5px solid transparent;
  border-radius: 2px;
  font-size: 11px;
  padding-left: 10px;
  margin-bottom: 2px;
  margin-top: 0px;  
}
.searchInput{
  width: 16.2%;
  float: left;
  background: rgba(255, 255, 255, 0.95);
  border: 0.5px solid transparent;
  height: 22px;
  border-radius: 2px;
  color: rgb(0,139,139);;
  font-size: 11px;
  padding:0px;
  padding-left: 10px;
  margin-bottom: 2px;
  margin-left: 0.2%;
  margin-right: 0.2%;
  margin-top: 1px;
}

#searchButton{
  float: left;
	width: 10%;
	text-align: center;
  background: rgb(255,215,0);
  border: 0.5px solid transparent;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 0.2%;
  margin-right: 0.3%;
  border-radius: 3px;
  color:rgb(0,37, 61);
	padding: 5px;
  font-size: 12px;
	cursor: pointer;
}

.downloadButton{
  width: 5% !important;
}

#searchButton:hover{
  background: rgba(255, 255, 255, 0.1);
  color: white
}

#searchZone{
  width: 100%;
  float: left;
}

#clientResults{
  width: 100%;
  float: left;
  padding-bottom: 15px;
}

#bulkSection{
  float: right;
  width: 5%;
  margin-left: 60%;
  text-align: center;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.05);
  margin-top: 10px;
  color:white;
	padding: 5px;
  font-size: 12px;
	cursor: pointer;
}
#resultHeader{
  margin-top: 5px;
  width: 100%;
  float: left;
  margin-bottom: 5px;
}

#bulkSectionClient{
  float: right;
  width: 5%;
  margin-right: 0.5%;
  padding: 7.5px;;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  margin-top: 10px;
  border-radius: 2px;
  color:white;
  font-size: 10px;
	cursor: pointer;
}

@media screen and (max-width: 768px) {

  #searchMenu{
    width: 98%;
    margin-top: -45px;
    padding: 5px;
    margin-left: 0%;
    margin-bottom: 15px;
  }
  #searchMenuItem{
    font-size: 11px;
    padding-top: 8px;
  }
  .searchInput{
    width: 47%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .dateSearchZone{
    width: 47%;
    margin-left: 1%;
    margin-right: 1%;
  }
  #searchButton{
    width: 47%;
    margin-left:1%;
    margin-right: 1%;
  }
   
}