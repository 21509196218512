.groupNavigator{
  width: 100%;
  float: left;
  margin-top: 0px;
  font-size: 11px !important;
  font-weight: bold;
  color: white;
}
  
#groupAction{
  width: 5%;
  margin-right:0px;
  text-align: center;
  font-style: italic;
  font-weight: normal;
  cursor: pointer;
  float: left;
  color: white;
}
#groupAction:hover{
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}
.groupContracts{
  background: rgba(255, 255, 255, 0.15);
  width: 66%;
  float: left;
  border-radius: 2px;
  font-size: 12px;
  padding: 15px;
}
.fullLength{
  width: 100%
}
#groupActionTitle{
  text-align: left;
  width: 100%;
  float: left;
  padding: 15px;
  padding-left: 5px;
  font-weight: 200;
  color: rgb(255,215,0);
  font-size: 14px;
}

#groupContractsOption{
  width: 49%;
  float: left;
  cursor: pointer;
  border-radius: 3px;
  padding-top: 10px;
  height: 35px;
  background: rgba(255, 255, 255, 0.05);
  color: white
}
#groupContractsOption:hover{
 background: rgba(255, 255, 255, 0.10);
 color: #FFD700
}
.activeGroupContractOption{
  background: rgba(255, 255, 255, 0.15) !important;
  font-weight: bold !important;
  color: #FFD700 !important
 }

.gCCO{
  margin-right: 2% !important;
}

.fullLineGroup{
  width: 100% !important;
}

#groupContractOption{
  width: 48%;
  float: left;
  color: white;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.05);
  padding: 7px;
  font-size: 12px;
  margin-bottom: 2px;
  border-radius: 3px;
  padding-left: 5px;
  font-weight: 200;
}
#groupContractOption:hover{
  color: #FFD700 !important;
  background: rgba(255, 255, 255, 0.10);
}
.selecteContract{
  color: #FFD700 !important;
  font-weight: bold !important;
  background: rgba(255, 255, 255, 0.10) !important;
}
#groupContractNumber{
  width: 25%;
  padding-left: 5px;
  float: left;
}
#groupContractDeal{
  width: 35%;
  float: left;
}
#groupContractName{
  width: 40%;
  float: left;
}
#createGroup{
  width: 49%;
  margin-top: 15px;
  border-radius: 3px;
  margin-right: 51%;
  font-size: 12px;
  float: left;
  background: #008B8B;
  border: 0.5px solid transparent;
  color: white
}

#groupSummary{
  background: rgba(255, 255, 255, 0.15);
  width: 90%;
  height: 330px;
  border-radius: 2px;
  font-size: 12px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}

#groupGraphTitle{
  font-size: 16px;
  font-weight: 200;
  width: 100%;
  float: left;
  color: white
}

#groupGraph{
  width: 100%;
  padding-top: 15px;
  float: left;
}

#groupAction{
  width: 32%;
  font-size: 12px;
  margin-right: 1%;
  font-weight: 200;
  font-style: normal;
  margin-bottom: 5px;
  float: left;
  border-radius: 3px;
  text-align: center;
  background: #00253D;
  padding: 5px;
  cursor: pointer;
}

#groupAction:hover{
  color: #FFD700;
  background: rgb(255, 255, 255, 0.1);
}
#groupModal{
  padding: 0px;
  margin: 0px;
  margin-top: -25px;
  min-height: 250px
}

.groupCreateLeft{
  padding-top: 5px !important;
  font-style: italic !important;
  width: 39% !important
}
