
#asset{
  background: rgb(0,37, 61);
  min-height: 1200px;
  width: 100%;
  float: left;
  padding-top:60px;
  padding-bottom:200px;
  color: #fff;
  font-weight: bold;
}

.assetNavigator{
  width: 98%;
  float: left;
  margin-top: 0px;
  font-size: 11px !important;
  font-weight: bold;
  color: white;
}

#assetActionSubT{
  font-size: 12px;
  padding-bottom: 5px;
  font-weight: normal
}
.assetSelector{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
	border: 0.25px solid transparent;
  color: white;
  border-radius: 2px;
  width: 99%;
  margin-left: 1%;
  float: left;
  font-size: 11px;
  padding: 4px;
  height: 35px;
  text-align: left;
  font-weight: normal;
}
#assetSelectorWrapper{
  margin-left: 2%;
  width: 14%;
  float: left;
}

#assetSubMenuSpacer{
  min-width: 19%;
  float: left;
}
#assetSummary{
  background: rgba(255, 255, 255, 0.15);
  width: 90%;
  border-radius: 2px;
  height: 330px;
  font-size: 12px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}

#assetTitle{
  width: 90%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  float:left;
  margin-bottom: 25px;
  padding-top: 15px;
  text-align: left;
  color: rgb(255,215,0);
  font-size: 20px;

}


#assetNumber{
  background: rgb(0,139,139);
  width: 30%;
  float:left;  padding: 15px;
  text-align: left;
  padding-left: 15px;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#instruction{
  width: 100%;
  float: left;
}
#assetActionInstruction{
  width: 80%;
  float: left;
  text-align: left;
  margin-bottom: 5px;
  font-size: 13px;
  padding: 5px;
  padding-left: 15px;
  font-weight: 200 !important;  
  font-style: italic
}
#assetActionInstructionItem{
  width: 17%;
  margin-left: 1%;
  float: left;
  text-align: center;
  margin-bottom: 5px;
  border: 0.5px solid white;
  font-size: 13px;
  font-weight: 200;  
  padding: 5px;
}
#InstructionLabel{
  width: 15%;
  float: left;
  font-size: 13px;
  background: rgba(255, 255, 255, 0.2);
  padding: 5px;
  padding-left: 10px;
  margin-bottom: 5px;
  text-align: left;
  font-weight: normal;
  font-style: italic
}
#designedDropZone{
    border: 0.5px dotted white;
    background: rgba(255, 255, 255, 0.2);
    height: 90px;
    padding-top: 35px;
    width: 50%;
    float: left;
    margin-top: 30px;
}

#uploadOutcome{
  width: 100%;
  float: left;
}
#uploadSucess{
  width: 50%;
  padding: 5px;
  margin-top: 30px;
  margin-bottom: 15px;
  border: 0.5px solid white;
  color: white
}

#uploadError{
  border: 0.5px solid red;
  width: 50%;
  margin-right: 50%;
  padding: 5px;
  margin-top: 30px;
  margin-bottom: 15px;
  color: red
}
#uploadErrorDetails{
  width: 50%;
  padding: 5px;
  margin-top: 10px;
  color: white;
  font-style: italic;
  font-size: 12px;
  text-align: left;
  font-weight: normal 
}
#editAsset{
  cursor: pointer;  
  background: rgb(0,139,139);
  width: 70%;  
  text-align: right;
  color: white;
  padding: 15px;
  padding-right: 15px;
  float:left;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

#assetPicture{
  width: 100%;
  float: left;
  padding-top: 20px;
  font-size: 70px;
  text-align: center;
}

#assetTransfers{
  width: 66%;
  float: left;
}

#assetAction{
  width: 20%;
  float: left;
  text-align: center;
  margin-left: 1.5%;
  margin-bottom: 10px;
  margin-right: 1.5%;
  font-size: 11px;
  background:rgba(255, 255, 255, 0.2);
  padding: 5px;
  cursor: pointer;
  color: white;
  border: 0.5px solid transparent
}
#assetActionZone{
  width: 100%;
  float: left;
  padding-left: 30px;
  padding-top: 2px;
}



#assetOption{
  width: 24%;
  margin-right: 1%;
  font-weight: normal;
  margin-bottom: 5px;
  float: left;
  border-radius: 2px;
  text-align: center;
  background: #1A3B51;
  padding: 5px;
  cursor: pointer;
}

#assetOption:hover{
  color: #FFD700;
  background: rgb(255, 255, 255, 0.1);
}

#assetActionTitle{
  width: 100%;
  float: left;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

#assetActionSubTitle{
  width: 100%;
  float: left;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 10px;
}

#fullImage{
  width: 100%;
  float: left
}
#halfImage{
  width: 50%;
  float: left
}
#linkedImageLink{
  font-size: 11px;
  cursor: pointer;
  width: 100%;
  float: left;
  border-radius: 3px;
  padding: 5px;
  background: rgba(255, 255, 255, 0.1)
}
#linkedImageLink:hover{
  box-shadow: 0 0 5px rgba(255, 255,255,1);
}

#repossedUnitTitle{
  text-align: left;
  font-size: 14px;
  font-weight: 200;
  width: 100%;
  float: left;
  padding-bottom: 15px;
}

#assetEditSection{
  width: 100%;
  float: left;
  margin-top: 10px;
}

#replacementRes{
  width: 100%;
  float: left;
  margin-top: 10px;
  font-weight: 200;
  padding: 10px;
  padding-left: 5px
}
.replacementResRed{
  color: red;
  font-weight: bold !important;
}
.replacementResPayment{
  width: 100%;
  float: left;
  margin-top: -15px;
  font-weight: bold;
  padding: 10px;
  padding-left: 5px
}
#contractXSLabel{
  width: 50%;
  float: left;
  font-weight: 200;
  padding: 5px;
  padding-top: 10px;
}
#replacementWrapper{
  width: 100%;
  float: left;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  #assetSummary{
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  #assetTransfers{
    width: 98%;
    padding-left: 0px;
    margin-left: 1%;
  }
  #assetOption{
    width: 48%;
    margin-left: 1%;
  }

}