.contractImg{
  width: 100%;
  font-style: italic;
  object-fit: contain
}

.clientImg{
  height: 150px;
  object-fit: contain
}

.creditImg{
  width: 100%;
  object-fit: contain
}

.clientPlaceholder{
  width: 100%;
  float: left;
  border: 0.5px solid white;
  color: white;
  font-weight: 300;
  padding-top: 60px;
  text-align: center;
  margin-bottom: 5px;
  height: 150px;
}

#pictureLink{
  cursor: pointer;
}