.groupPricingsTitle{
  width: 100%;
  float: left;
  font-weight: 200;
  font-style: italic;
  font-size: 11px;
  text-align: left !important;
  padding: 7px;
  color: white;
}

#groupPricingName{
  width: 35%;
  float: left
}
#groupPricingSize{
  width: 10%;
  text-align: center;
  float: left
}
#groupPricingProduct{
  width: 25%;
  text-align: center;
  float: left
}

.groupPricingItem{
  width: 100%;
  float: left;
  font-weight: normal;padding: 7px;
  text-align: left !important;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  margin-bottom: 1px;
  font-size: 11px;
  color: white;
}
.groupPricingItem:hover{
  background: rgba(255, 255, 255, 0.135);
}