.paymentNavigator{
  width: 100%;
  float: left;
  margin-top: 0px;
  font-size: 11px !important;
  font-weight: bold;
  color: white;
}
  
#paymentAction{
  width: 5%;
  margin-right:0px;
  text-align: center;
  font-style: italic;
  font-weight: normal;
  cursor: pointer;
  float: left;
  color: white;
}
.high{
  height: 90px !important;
}
#reconcile{
  background: rgb(0, 139, 139);
  width: 12.5%;
  margin-left: 7.5%;
  text-align: center;
  border-radius: 3px;
  font-size: 12px;
  float: left;
  color: white;
  cursor: pointer;
}
#reconcile:hover{
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}

#paymentAction:hover{
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}

.paymentInput{
	font-size: 11px;
  padding: 3px;
  border-radius: 2px;
	padding-left: 5px;
	border: 0.5px solid white;
  background: white;
  float: left;
	width: 65%;
	margin-bottom: 2px;
  margin-top: 2px;
	height: 24px;
	color: #191970;
}
.paymentPreInput{
	font-size: 11px;
  padding: 3px;
  border-radius: 2px;
	padding-left: 5px;
  background: rgba(255, 255, 255, 0.2);
  float: left;
	width: 65%;
	margin-bottom: 2px;
  margin-top: 2px;
	height: 24px;
	color: white;
}

.paymentInputLabel{
	font-size: 11px;
	padding:3px;
	padding-left: 5px;
  width: 35%;
  font-style: italic;
  margin-top: 2px;
	margin-bottom: 2px;
  float: left;
	height: 24px;
	color: white;
}

#fullPayment{
  width: 100%;
  float: left;
}


@media screen and (max-width: 768px) {
  #reconcile{
    width: 40%;
    margin-left: 15%;
  }
}