.notesTitle{
  font-size: 11px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  width: 100%;
  text-align: left;
  float: left;
}

.noteItem{

  width: 100%;
  margin-left:0%;  float: left;
  font-weight: 200;
  background: rgba(255, 255, 255, 0.1);  border-top: 0.5px solid #00253D;
  border-radius: 2px;
  color: white;
  font-size: 11px;
  text-align: left;
}
.notRead{
  background: rgba(255, 255, 255, 0.15) !important;
  cursor: pointer;
}
#noteSection{
  width: 100%;
  float: left;
}
#noteText{
  width: 30%;
  float: left;
  margin-top:5px;
  margin-left: 1%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding-left: 5px;
  cursor: pointer;
  padding: 5px;
  background: rgba(255, 255, 255, 0.1);
}
#noteAddOne{
  width: 10%;
  float: left;
  margin-top:5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
  color: #FFD700;
  text-align: center;
  padding: 5px;
  background: rgba(255, 255, 255, 0.1);
}
#noteFilter{
  width: 30%;
  margin-left: 29%;
  border-radius: 3px;
  float: left;
  cursor: pointer;
  color: #FFD700;
  padding: 5px;
  background: rgba(255, 255, 255, 0.1);
}
.notesInput{
  border: 0.5px solid white;
  border-radius: 3px;
  color: #00253D;
  font-size: 11px;
  margin-top: 0px;
  width: 70%;
  float: left;
}

#noteSave{
  width: 10%;
  margin-left: 2%;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  float: left;
  background: #FFD700;
  border-radius: 2px;
  cursor:pointer;
}

#notesSmall{
  width: 8%;
  float: left;
  padding: 5px;
  font-size: 11px;
}
#notesExtraSmall{
  width: 6%;
  float: left;
  padding: 5px;
  padding-left: 10px;
  font-size: 11px; 
}
#notesDate{
  width: 6%;
  float: left;
  padding: 5px;
  padding-left: 10px;
  font-size: 11px; 
  font-style: italic
}
#notesSmall2{
  width: 9%;
  float: left;
  padding: 5px;
  font-style: italic;
  font-size: 11px;
}
#notesExtra{
  width: 17.5%;
  float: left;
  padding: 5px;
  font-size: 11px;
}
#notesDest{
  width: 10%;
  float: left;
  padding: 5px;
  font-style: italic;
  font-size: 11px;
}
#notesLarge{
  width: 48.5%;
  float: left;
  padding: 5px;
  font-size: 11px;
}
#NoMessages{
  text-align: left;
  padding-top: 45px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  font-style: italic
}
#notesMedium{  
  padding: 5px;
  width: 62%;
  font-size: 11px;
  float: left
}
#notesAuthor{
  width: 14%;
  float: left;
  padding: 5px;
  font-style: italic;
  font-size: 11px;
}
#notesType{
  width: 11%;
  float: left;
  padding: 5px;
  font-style: italic;
  font-size: 11px;
}

#notesTiny{
  width: 4%;
  float: left;
  text-align: center;
  padding: 5px;
  font-size: 11px;
}

#notesList{
  width: 100%;
  float: left;
}

#allNotes{
  width: 97%;
  padding-top: 5px;
  margin-left: 1.5%;
  float: left;
}

@media screen and (max-width: 768px) {
  
  #notesSmall{
    width: 15%;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 10px
  }
  
  #notesExtra{
    width: 29%;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 10px
  }
  #notesExtraSmall{
    width: 15%;
    padding-left: 3px;
    padding-right: 0px;
    font-size: 10px
  }
  
  #notesDest{
    width: 27%;
    padding-left: 2px;
    padding-right: 0px;
    font-size: 10px
  }
  #notesTiny{
    width: 7%;
    padding-left: 2px;
    padding-right: 0px;
    font-size: 10px
  }
  #allNotes{
    width: 98%;
    padding-top: 15px;
    margin-left: 1%;
  }
}