#graphSection{
  width: 50%;
  float: left;  
}
#graphSectionThird{
  width: 33.33%;
  float: left;  
}
#graphTitle{
  text-align: left;
  padding-left: 15px;  
  color: #FFD700;
  text-align: left;
  font-size: 16px;
  font-style: italic;
  width: 70%;
  float: left;
}
#graphTitleData{
  color: white;
  font-size: 14px;
  font-style: italic;
  text-align: right;
  padding-right: 20px;
  padding-top: 2px;
  width: 30%;
  float: left;
  font-weight: normal
}
#graphTitleCumul{
  color: rgb(255,215,0);
  font-size: 20px;
  text-align: left;
  padding-left: 15px;
  font-weight: normal;
  width: 80%;
  float: left;
}
#graphCumul{
  color: rgb(255,215,0);
  font-size: 15px;
  text-align: right;
  padding-top: 5px;
  cursor: pointer;
  font-weight: normal;
  width: 15%;
  float: left;
}
#graphCumul:hover{
  color: white;
}
#graphWrapper{
  background: rgba(255, 255, 255, 0.15);
  width: 95%;
  float: left;
  min-height: 250px;
  border-radius: 3px;
  padding: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 15px;
  margin-top: 5px;
}
.graphFilter{
  
  margin-top: -100px;
}

.graphSelector{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid transparent;
  border-radius: 2px;
	height: 28px;
  color: white;
  margin-top: 5px;
  width: 24%;
  margin-left: 0.5%;
  float: left;
  font-size: 10px;
  padding: 5px;
  text-align: left;
  font-weight: normal;
}
#graphlock{
  text-align: center;
  padding-top: 3px;
}
#graphlockSpan{
  padding-left: 5px;
  padding-right: 5px;
}
.graphSelectorDate{
  width: 24%;
  margin-left: 0.5%;
  float: left; 
}

#agentSelectSection{
  width: 50%;
  margin-left: 50%;
  margin-top: -75px;
  float: left;
}
#contractSelectorSpacer{
  width: 12.5%;
  float: left;
}

@media screen and (max-width: 768px) {
  #graphSection{
    width: 100%;  
  }
  #graphWrapper{
    padding-top: 15px;
    padding-bottom: 15px;
    width: 95%;
    float: left;
    margin: 2.5%;
  }
  .graphFilter{
    width: 100%;
    float: left;
    margin-top: -30px;
    margin-bottom: 10px;
  }

  #agentSelectSection{
    width: 100%;
    margin-left: 0%;
    margin-top: 0px;
    float: left;
  }

  #graphSectionThird{
    width: 100%
  }

}

