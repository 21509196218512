#assetMvt{
  width: 95%;
  float: left;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 2px;
  font-size: 11px;
  margin-bottom: 5px;
}

#mvtHeader{
  width: 30%;
  line-height: 35px;
  text-align: center;
  float: left;
  background: rgb(0,139,139);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  min-height: 35px;
}
#mvtDate{
  font-size: 11px;
  font-style: italic;
  width: 40%;
  float: left;
}
#mvtType{
  font-size: 12px;
  font-weight: bold;
  line-height: 35px;
  width: 60%;
  float: left;
}

#assetQuestion{
  padding-bottom: 10px;
  font-style: italic;
}



#mvt{
  width: 70%;
  float: left;
}

#mvtFrom{
  width: 80%;
  float: left;
  padding-top: 2px;
  min-height: 35px;
}

#mvtTo{
  width: 80%;
  float: left;
  padding-top: 2px;
  min-height: 35px;
}

#mvtTitleFrom{
  color: rgb(255,215,0);  
  width: 20%;
  padding-left: 10px;
  text-align: left;
  font-size: 11px;
  min-height: 35px;
  line-height: 35px;
  float: left;
}

#mvtTitleTo{
  color: rgb(255,215,0);  
  width: 20%;
  min-height: 35px;
  line-height: 35px;
  padding-left: 10px;
  text-align: left;
  font-size: 11px;
  float: left;
}
#mvtTitleContract{
  color: rgb(255,215,0);  
  width: 40%;
  min-height: 35px;
  line-height: 35px;
  padding-left: 10px;
  text-align: left;
  font-size: 11px;
  float: left;
}
#mvtContract{
  width: 60%;
  float: left;
  line-height: 35px;
  min-height: 35px;
}

#mvtName{
  width: 100%;
  float: left;
}
#mvtRole{
  width: 50%;
  float: left;
  font-weight: normal;
  font-style: italic
}
#mvtDouble{
  width: 100%;
  float: left;
  padding: 3px;
  padding-top: 7px;
}
#mvtClient{
  width: 100%;
  float: left;
  font-weight: normal;
  font-style: italic
}
#mvtNumber{
  width: 50%;
  float: left;
  font-weight: normal;
  font-style: italic;
  padding-left:2px;
  cursor: pointer;
}

#mvtWrapper{
  cursor: pointer;
  width: 50%;
  float: left;
}
#mvtWrapper:hover{
  background: rgba(255, 255, 255, 0.1) !important
}


#yes{
  width: 20%;
  float: left;
  cursor: pointer;
  padding: 5px;
  font-size: 12px;
  text-align: center;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 15px;
}

#yes:hover{
  width: 20%;
  float: left;
}

.activeOption{
  background: rgb(255,215,0) !important; 
}

#assetFaultyTitle{
  width: 100%;
  margin-top: -10px;
  float: left;
}

#drop{
  width: 100%;
  float: left;
  margin-top: 30px;
  padding-bottom: 15px;
}

#preview{
  width: 100%;
  float: left;
  text-align: left;
  font-weight: normal;
  font-style: italic;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
}

@media screen and (max-width: 768px) {

  #assetMvt{
    width: 98%;
    margin-right: 1%;
  }
}