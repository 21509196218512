

.itemNavigator{

  width: 24%;
  margin-left: 1%;  float: left;
  font-size: 10px !important;
  font-weight: bold;
  color: white;
}
.deploy{
  width: 46% !important;
}

#subItemsList{
  width: 24%;
  margin-left: 1%;
  margin-top: 5px;
  float: left;
}
.itemSelector{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
	border: 0.25px solid transparent;
  color: white;
  width: 48%;
  margin-right: 2%;
  float: left;
  font-size: 11px;
  border-radius: 3px;
  padding: 4px;
  height: 35px;
  text-align: left;
  font-weight: normal;
}
#itemSelectorWrapper{
  width: 28%;
  margin-left: 1%;
  float: left;
}

.itemTitle{
  width: 100%;
  float: left;
  font-size: 10px !important;
  font-style: italic;
  border-top-right-radius: 3px;
  color: white;
}


.itemItem{
  width: 100%;
  float: left;
  cursor: pointer;
  font-weight: 200;
  background: rgba(255, 255, 255, 0.1);
  font-size: 11px !important;
  border-radius: 2px;
  border-bottom:0.5px #00253D solid;
  color: white;
}
#itemDesLabel{
  width: 30%;
  float: left;
  text-align: left;
  padding-left: 10px;
}
#itemDesData{
  width: 70%;
  float: left;
  padding-left: 10px;
  text-align: left;
  color:rgb(0, 140, 186)
}
#itemHeader{
  width: 98%;
  margin-right: 1%;
  float: left;
  padding-top: 3px;
  font-size: 11px !important;
  padding-bottom: 2px;
  border-bottom: 1px solid white;
  color: #FFD700;
}
.itemMonth{
  width: 15%;
  margin-right: 1%;
  float: left;
  padding-top: 3px;
  font-size: 11px !important;
  padding-bottom: 2px;
  border-bottom: 1px solid white;
  color: #FFD700;
}
.itemTotal{
  width: 7%;
  float: left;
  padding-top: 3px;
  font-size: 11px !important;
  padding-bottom: 2px;
  border-bottom: 1px solid white;
  color: #FFD700;
}
.itemLeft{
  margin-left: 45%
}
#itemWarehouse{
  width: 30%;
  margin-left: 45%;
  margin-right: 1%;
  float: left;
  padding-top: 3px;
  font-size: 11px !important;
  padding-bottom: 2px;
  border-bottom: 1px solid white;
  color: #FFD700;
}
#itemWithAgents{
  width: 22%;
  margin-left: 1%;
  margin-right: 1%;
  float: left;
  padding-top: 3px;
  font-size: 11px !important;
  padding-bottom: 2px;
  border-bottom: 1px solid white;
  color: #FFD700;
}
#itemAux{
  width: 44%;
  float: left;
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;  
}
#itemAuxiliary{
  width: 30%;
  float: left;
  text-align: left;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;  
}
#itemTotal{
  width: 8%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#itemLargeTotal{
  width: 18%;
  margin-left: 0.5%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

.itemTop{
  margin-bottom: 5px;
  width: 18%;
}
#itemAll{
  width: 8%;
  float: left;
  padding: 5px;
  padding-right: 10px;
  text-align: right;
}
#itemDate{
  width: 30%;
  float: left;
  padding-top: 5px;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 5px;
}
#itemDeployDate{
  width: 20%;
  float: left;
  padding-top: 5px;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 5px;
}
#itemAgent{
  width: 80%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  text-align: left;
}

#itemFrom{
  width: 50%;
  float: left;
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
#itemDeploy{
  width: 23.33%;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left;
}

#itemDeployQty{
  width: 10%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#itemQty{
  width: 20%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#partAst{
  text-align: left;
  font-size: 11px;
  padding: 10px;
  width: 100%;
  float: left;
  font-weight: 200
}
#itemsList{
  width: 47%;
  margin-left: 1.5%;
  margin-top: 5px;
  float: left;
}
#deploysList{
  width: 47%;
  margin-left: 1.5%;
  margin-top: 5px;
  float: left;
}
.lightList{
  width: 99%;
  margin-top: 0px;
  float: left;
}

.addItemLabel{
  width: 20%;
  float: left;
  text-align: left;
  padding-top: 7px;
  padding-left: 10px;
  font-weight: 200
}
.addItemInput{
	border: 0.5px solid white;
	width: 80%;
	margin-bottom: 16px;
	padding-left: 5px;
	padding-top: 6px;
	border-radius: 2px;
	margin-top: 0px;
	color: #191970 !important;
	height: 30px;
	font-size: 12px;
	float: left;
}

.transferItemLabel{
  width: 30%;
  float: left;
  text-align: left;
  padding-left: 10px;
  margin-bottom: 5px;
  height: 30px;
  line-height: 30px;
  font-weight: 200
}
.transferItemInput{
	border: 0.5px solid white;
	width: 70%;
	padding-left: 5px;
	padding-top: 6px;
  margin-bottom: 5px;
	border-radius: 2px;
	margin-top: 0px;
	color: #191970 !important;
	height: 30px;
	font-size: 12px;
	float: left;
}
.transferItemInputed{
  width: 70%;
  float: left;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  text-align: left;
  height: 30px;
  margin-bottom: 5px;
  line-height: 30px;
  font-weight: bold;
  padding-left: 10px;
}

.transferNote{
  width: 70%;
  border-radius: 3px;
  padding: 5px;
  padding-left: 10px;
  float: left;
}

.addItemShort{
  width: 18%;
}
.addItemMedium{
  width: 30%;
}
.addItemMargin{
  width: 18%;
}

.addLabelShort{
  width: 13%;
  text-align: center;
  padding-left: 0px;
}
.longLabel{
  width: 20% !important;
}

#transferWarning{
  color: red;
  padding: 10px;
  width: 100%;
  float: left;
}

@media screen and (max-width: 768px) {
  .itemsTitle{
    width: 100%;
    font-size: 11px !important;
    font-weight: 300;
  }
  .itemItem{
    width: 100%;
    font-weight: 300;
    font-size: 10px !important;
  }
  #itemsList{
    width: 98%;
    margin-left: 1%;
    margin-top: 15px;
  }
  #itemsNumber{
    width: 30%;
  } 
  #itemsProduct{
    width: 30%;
  }
  #subItemsList{
    width: 98%;
    margin-left: 2%;
  }

}
