
#usersAdd{
	width: 50%;
	float: left;
	padding-left: 15px;
}
.userDetailsInput{
	border: 0.5px solid white;
	border-radius: 3px;
	width: 98%;
	margin-left: 1%;
	margin-bottom: 10px;
	padding-left: 10px;
	margin-top: 0px;
	height: 30px;
	font-size: 12px;
	float: left;
}

.userCreate{
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  width: 100%;
  float: left;
  font-size: 12px;
  padding: 15px;
  padding-right: 30px;
}

#userTitle{
  color: rgb(255,215,0);
  font-size: 20px;
  text-align: left;
  margin-bottom: 15px;  
  margin-left: 15px;  
}

#formSectionTitle{
 	color: rgb(0,139,139);
	padding: 5px;
	padding-left: 10px;
	border-bottom: 0.5px solid rgb(0,139,139);
	margin-bottom: 10px;

}

#createUser{
	background: rgb(255,215,0);
	padding:5px;
	margin-left:2px;
	width: 25%;
	float: left;
	margin-top: 2px;
	border-radius: 3px;
	cursor: pointer
}

#usersExtraSmall2{
	width: 100%;
	float: left;
	padding-left: 15px;
	margin-bottom: 15px;
}
.signin_error{
	color: red !important;
	margin-bottom: 0px;
}
#permissionW{
	width: 100%;
	float: left;
	font-size: 10px;
	margin-top: 2px;
	margin-bottom: 3px;
	border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
	padding-bottom: 5px
}
#permissionT{
	background: rgba(255, 255, 255, 0.1);
	padding: 5px;
	padding-left: 10px;
	border-radius: 3px;
	margin-bottom: 5px;
	margin-top: 15px;
	margin-bottom: 3px;
	font-size: 12px;
	width: 100%;
	float: left;
}
#permissionQ{
	width: 75%;
	padding: 2px;
	padding-left: 10px;
	float: left
}
#permissionOption{
	width: 12%;
	margin-left: 0.5%;
	text-align: center;
	border-radius: 2px;
	cursor: pointer;
	padding: 2px;
	float: left
}
.selected{
	background: white !important;
	color: #00253D !important
}
.unselected{
	background: rgba(255, 255, 255, 0.1) !important;
	color: white !important
}
.shifted{
	margin-top: -15px !important
}

.userInput{
	border: 0.5px solid white;
  width: 28%;
  margin-right: 2%;
  margin-bottom: 10px;
  padding: 0px;
	padding-left: 5px;
	border-radius: 2px;
	margin-top: 0px;
	color: #191970 !important;
	height: 30px;
	font-size: 12px;
	float: left;
}
.userInputLong{
	border: 0.5px solid white;
  width: 78%;
  margin-right: 2%;
	margin-bottom: 10px;
	padding-left: 5px;
	padding-top: 6px;
	border-radius: 2px;
	margin-top: 0px;
	color: #191970 !important;
	height: 30px;
	font-size: 12px;
	float: left;
}

.userLabel{
	width: 20%;
  margin-bottom: 10px;
  font-weight: 200;
	padding-left: 5px;
  padding-top: 6px;
  font-style: italic;
	border-radius: 2px;
	margin-top: 0px;
	color: white !important;
	height: 30px;
	font-size: 12px;
	float: left;
}

.userLabelLong{
	width: 50%;
  margin-bottom: 10px;
  font-weight: 200;
	padding-left: 5px;
  padding-top: 6px;
  font-style: italic;
	border-radius: 2px;
	margin-top: 0px;
	color: white !important;
	height: 30px;
	font-size: 12px;
	float: left;
}
.redText{
  color: red !important;
  font-style: normal !important
}