.agentInvoiceInputLabel{
  margin-top: 10px;
  font-weight: normal;
  font-style: italic;
  width: 25%;
  float: left;
  font-size: 12px;
  margin-bottom: 5px;
}

.agentInvoiceInput{
  border: 0.5px solid white !important;
  height: 35px !important;  
  padding-left: 2px;
  margin-bottom: 5px;  
  width: 69%;
  float: left;
  font-size: 12px;
}

.agentInvoiceInputLong{
  border: 0.5px solid white !important;
  background: rgba(255, 255, 255, 0.1);
  height: 100px !important;  
  margin-bottom: 5px;  
  color: white !important;
  text-align: left !important;
  padding-left: 5px;
  width: 74%;
  margin-right: 1%;
  padding-top: 5px;
  float: left;
  font-size: 12px;    
}
#mappedItems{
  width: 74%;
  float: left;
}
.invoiceDateInput{
  width: 100%;
  float: left;
  color: rgba(255,215,0, 1) !important;
  background: rgba(255, 255, 255, 0.1) !important;
  height: 35px;
  border: 0.5px solid white;
  font-size: 11px;
  padding:3px;
  padding-left: 10px;
  margin-bottom: 1px;
  margin-top: 0px;  
  }
.agentInvoiceInputMedium{
  color: rgba(255,215,0, 1) !important;
  background: rgba(255, 255, 255, 0.1) !important;    
  height: 35px !important;  
  margin-bottom: 10px;  
  text-align: left !important;
  padding-left: 5px;
  width: 36.5%;
  margin-right: 1%;
  padding-top: 10px;
  float: left;
  font-size: 12px;
}

.itemGrandTotal{
  color: rgba(255,215,0, 1) !important;
  background: rgba(255, 255, 255, 0.1) !important;    
  height: 35px !important;  
  margin-bottom: 10px;  
  margin-left: 37.5%;
  text-align: left !important;
  padding-left: 5px;
  width: 36.5%;
  margin-right: 1%;
  padding-top: 10px;
  float: left;
  font-size: 12px; 
}

.halfInvoice{
  width: 36.5% !important;
  margin-right: 1% !important;
  margin-bottom: 10px !important;
  float: left;    
}

.agentInvoiceInputTitle{
  margin-top: 10px;
  font-weight: normal;
  width: 100%;
  float: left;
  border-bottom: 2px solid white;
  padding-bottom: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}
.agentInvoiceInputExpl{
  font-weight: normal;
  font-style: italic;
  font-size: 13px
}

.dateInvoice{
  width: 36.5%;
  float: left;
  height: 35px;
  color: rgb(0,139,139);
  margin-bottom: 10px;
  padding-left: 0px;
  margin-right: 1%;
  margin-top: 5px;  
}

.addInvoices{
  width: 20%;
  text-align: left;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: normal;
  padding-left: 15px;
  background: #314F63;
  margin-left: 1%;
  cursor: pointer;
  float: left;
}
.addInvoices:hover{
  color: rgba(255,215,0, 1);
  background: #516A7B
}
