.agentsTitle{
  font-size: 11px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  width: 98%;
  float: left;
}

.agentItem{
  width: 98%;
  float: left;
  font-weight: 200;
  background: rgba(255, 255, 255, 0.1);  border-top: 0.5px solid #00253D;
  border-radius: 2px;
  color: white;
  font-size: 11px;
}
.agentItem:hover{
  background: rgba(255, 255, 255, 0.135);
}

.edit{
  cursor:pointer;
}
.edit:hover{
  background:rgba(255, 255, 255, 0.1);
  color:rgba(255,215,0, 1);
}

#agentsSmall{
  width: 5.5%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#agentsSync{
  width: 6.5%;
  float: left;
  padding-top: 5px;
  font-style: italic;
  padding-bottom: 5px;
}
#agentsId{
  width: 6%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#agentsEntity{
  width: 9%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#agentsMedium{
  width: 9%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#agentsLarge{ 
  width: 20.5%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#agentsExtraSmall{
  width: 3.5%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

#agentsList{
  width: 97%;
  margin-left: 1.5%;
  margin-top: 30px;
  float: left;
}

@media screen and (max-width: 768px) {

  #agentsList{
    width: 99%;
    margin-left: 1%;
    margin-top: 15px;
  }
  #agentsMedium{
    width: 20%;
    font-size: 10px;
  }
  #agentsSmall{
    width: 11.25%;
    font-size: 10px;
  }
  #agentsExtraSmall{
    width: 6%;
  }
  
}