.targetItem{
  width: 99%;
  float: left;
  text-align: left;
  padding: 2.5px;
  margin-bottom: 10px;
  font-weight: normal;
  background: rgba(255, 255, 255, 0.1);
  color: #00253D;
  font-size: 11px;
  padding-top: 5px;
}

.targetItemTitle{
  font-size: 15px;
  margin-bottom: 2px;
  margin-left: 0.5%;
  padding-left: 5px;
  width: 50%;
  float: left;
  color: rgba(255,215,0, 1);
  padding-bottom: 5px;
}
.targetDelete{
  font-size: 10px;
  padding: 2px;
  margin-top: 3px;
  text-align: center;
  cursor: pointer;
  background: rgb(220,20,60, 0.9);
  color: white;
  width: 10%;
  margin-left: 0.5%;
  float: right;
}
.targetEdit{
  font-size: 10px;
  padding: 1.5px;
  margin-top: 3px;
  text-align: center;
  cursor: pointer;
  border: 0.5px solid rgb(220,20,60, 0.9);
  color: white;
  margin-left: 0.5%;
  width: 10%;
  float: right;
}
.targetItemStatus{
  
  font-size: 10px;
  margin-left: 0.5%;
  padding: 2px;
  font-style: italic;
  padding-right: 5px;
  width: 28%;
  float: right;
  text-align: right;
  color: white;
  margin-top: 3px;
  margin-bottom: 7px;
}
.activeTarget{
  background: rgb(0,139,139);
  width: 10% !important;
  font-style: normal;
  text-align: center !important;
  margin-left: 18% !important;
  margin-right: 0.5%;
}
.targetItemDetailsL{
  font-size: 12px;
  margin-left: 0.5%;
  width: 90%;
  float: left;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
#freq{
  font-style: italic;
  color: white;
}
.targetWrapper{
  width: 32.33%;
  margin-right: 0.5%;
  margin-left: 0.5%;
  font-size: 11px;
  background: #00253D;
  color: white;
  margin-bottom: 5px;
  padding: 5px;
  float: left;
}

.targetItemLabel{
  font-style: italic
}
.targetItemData{
  font-weight: normal;
	color: rgb(0, 140, 186)
}

@media screen and (max-width: 768px) {

  
  
}