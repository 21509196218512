.background{
  width: 100%;
  min-height: 1000px;
  float: left;
  background: #00253D;
}


#home_background{
  min-height: 1200px;
  background: #00253D;
  width: 100%;
  float: left;
  padding-top:60px;
  padding-bottom:200px;
  text-align: center;
  color: #fff;
  font-weight: bold;
}

#adminBackground{
  background: #00253D;
  width: 95%;
  float: left;
  margin-top: 75px;
  padding-bottom:200px;
  min-height: 800px;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
.homeMenu{
  width: 5%;
  float: left;
  padding-top:60px;
  text-align: center;
  color: #fff;
  font-weight: bold;
}

#home_menu{
  line-height: 45px;
  margin-top: 15px;
  font-size:18px;
  width: 100%;
  margin-left:0%;
}
#home_main{
  padding-left: 0px; 
}

.home_menu_item{
  background: rgba(255, 255, 255, 0.25);
  width: 100%;
  margin-right:2px;
  float: left;
  color: white;
  cursor: pointer;
}
#home_item{
  padding-left:15px;
  padding-right:15px;
  width: 100%;
  margin-right:5px;
  float: left;
  color: white;
  cursor: pointer;
  text-align: center;
}
#home_item:hover{
  border: white 1px solid;
  text-align: center;
}
.home_menu_item:hover{
  color: #00253D;
  background: rgba(255, 255, 255, 0.5)
}

.active_home_menu{
  color: white !important;
  background: rgb(0,139,139) !important;
}

#listTitle{
  width: 100%;
  float: left;
  font-weight: normal;
  border-radius: 2px;
  color: #FFD700;
  text-align: left;
  font-size: 16px;
  font-style: italic;
  padding: 7px;
  padding-left: 10px;
}


#welcomeUser{
  text-align: left;
  margin-left: 5%;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}
#loader{
  width: 95%;
  float: left;
  color: white
}
.loaderZoneHeader{  
  width: 25%;
  float: left;
  margin-top: 35px;
}
.loaderZone{
  width: 50%;
  float: left;
}

#pageTitle{
  color: white;
  position: absolute;
  left: 6.5%;
  top: 15px; 
  padding-top: 5px;
  font-size: 24px;
}
#returnButton{
  font-size: 16px;
  padding-left: 45px;
  padding-top: 0px;
  display: inline-block;
  cursor: pointer;
}
#returnButton:hover{
  color: #FDCC2E
}
#dashTitle{
  color: white;
  position: absolute;
  left: 6.25%;
  top: 20px;
  font-size: 24px;
}

#successfulSent{
  margin-bottom: 25px;
  margin-top: 10px;
  margin-left: 1.5%;
  width: 50%;
  float: left;
  border: 0.5px solid white;
  border-radius: 2px;
  padding: 5px;
  padding-left: 15px;
  text-align: left;
}

#successfulCreation{
  margin-top:15px;
  float: left;
  border: 1px solid white;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
#unSuccessfulCreation{
  margin-top:15px;
  margin-bottom: 15px;
  color: rgba(220,20,60, 1);
  width: 50%;
  float: left;
  border: 1px solid rgba(220,20,60, 1);
  padding: 5px;
  padding-left: 15px;
}

.long{
  width: 100% !important;
  float: left;
}

.subTitle{
  background: rgba(255, 255, 255, 0.175);
  padding: 5px;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  font-style: italic
}

#newMessages{
 color: rgba(220,20,60, 1);
 width: 98%;
 float: left
}

#NbNewMessages{
  font-size: 10px;
  color: rgba(220,20,60, 1);
  width: 1%;
  margin-left: -15px;
  text-align: left;
  float: left
}


.active{
  cursor:pointer;
}
.active:hover{
  background:rgba(255, 255, 255, 0.07);
  border-radius: 2px;
}

.dealSelector{
  width: 50% !important;
}

.navButton{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.02);
  width: 2.5%;
  padding: 5px;
  float: left
}
.navButton:hover{
  background:#314F63 !important;
  color: #FFD700;
  border-radius: 2px;
}
.navButtonLarge{
  width: 5%;
}
.navLegend{
  margin-left: 67.5%;
  width: 10%;
  font-style: italic;
  font-weight: normal;
  padding-top: 5px;
  border-radius: 2px;
  float: left;
}
.itemNavButton{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.02);
  width: 5%;
  padding: 3.5px;
  padding-left: 5px;
  padding-right: 5px;
  float: left
}
.itemLegend{
  margin-left: 22.5%;
  width: 25%;
  font-style: italic;
  font-weight: normal;
  padding-top: 3.5px;
  border-radius: 2px;
  float: left;
}
.itemPage{
  margin-right: 2.5%;
  padding: 3.5px;
  background: rgba(255, 255, 255, 0.2);
  width: 30%;
  border-radius: 2px;
  float: left;
  color: #FFD700  
}
.navPage{
  margin-right: 2.5%;
  padding: 5px;
  background: rgba(255, 255, 255, 0.2);
  width: 10%;
  border-radius: 2px;
  float: left;
  color: #FFD700
}
.topMenu{
  border-top-right-radius: 3px;
}
.spacedMenu{
  margin-top: 15px;
}
.bottomMenu{
  border-bottom-right-radius: 3px;
}
.leftMenu{
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.rightMenu{
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
#landingMenu{
  margin-top: 75px;
  font-size:14px;
  width: 97%;
  float: left;
  margin-left: 1.5%;
  margin-bottom: 0px;
}
#landingMenuItem{
  background: rgba(255, 255, 255, 0.15);
  width: 15%;
  margin-right: 1.5%;
  text-align: center;
  float: left;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
#landingMenuItemIcon{
  padding-top: 15px;
  font-size: 25px;
}
#landingMenuText{
  padding-top: 5px;
  padding-bottom: 15px;
  font-weight: 300
}

#landingMenuItem:hover{
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}

.landingMenuItemActive{
  color: #00253D !important;
  background: rgb(255,215,0) !important;
}

#individualSearchMenu{ 
  margin-top: 75px;
  font-size:14px;
  width: 97%;
  float: left;
  margin-left: 1.5%;
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .homeMenu{
    width: 100%;  
    padding-top: 45px;
  }

  #home_menu{
    width: 98%;
    margin-left: 2%;
  }
  .home_menu_item{
    width: 13.5%;
    border-radius: 3px;
  }
  #home_item{
    width: 12.5%;
  }
  .onlyLarge{
    display: none
  } 
  #pageTitle{
    left: 2%;
    top: 10px; 
    font-size: 22px;
  }

  .navButton{
    width: 5%;
  }
  
  .navLegend{
    margin-left: 10%;
    width: 40%;
    text-align: right;
    padding-right: 10px;
  }
  .navPage{
    margin-right: 1%;
    width: 29%;
  }
  #adminBackground{
    width: 100%;
    margin-top: 25px;
    padding-bottom:50px;
  }

  #landingMenu{
    margin-top: 0px;
    font-size:14px;
    width: 97.5%;
    margin-left: 2.5%;
    margin-bottom: 0px;
  }
  #landingMenuItem{
    width: 47.5%;
    margin-right: 2.5%;
    margin-bottom: 10px;
  }
  #landingMenuItemIcon{
    padding-top: 25px;
  }
  #landingMenuText{
    padding-bottom: 25px;
  }

  .topMenu{
    border-top-right-radius: 3px;
  }
  .spacedMenu{
    margin-left: 15px;
    margin-top: 0px;
  }
  .bottomMenu{
    border-bottom-right-radius: 3px;
  }
  .leftMenu{
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .rightMenu{
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
}