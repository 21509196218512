#clientEventList{
  width: 94%;
  float: left;
  margin-left: 3%;
}


.clientEventsTitle{
  text-align: left;
  width: 87%;
  float: left;
  margin-left: 3%;
  margin-top: 30px;
  font-weight: normal;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.10);
  border-radius: 2px;
  color: #FFD700;
  font-size: 16px;
  font-style: italic;
  padding: 5px;
  margin-bottom: 5px;
  padding-left: 15px;
}
.negMarginTop{
  margin-top: 5px;
}
.actionNegMarginTop{
  margin-top: 7px;
}
.clientEvent{
  width: 100%;
  float: left;
  font-size: 11px;
  background: rgba(255, 255, 255, 0.075);
  border-radius: 2px;
  padding-bottom: 5px;
  padding: 2px;
  padding-left: 4px;
  margin-bottom: 5px;
  text-align: left;
}

.leadEvent{
  background: white !important;
  color: #00253D !important;
}
#clientEventsDate{
  width: 17.5%;
  font-size: 11px;
  font-weight: 200;
  padding: 5px;
  float: left;
}
#clientEventsTitle{
  width: 27.5%;
  padding: 5px;  font-size: 11px;
  float: left;
}
#clientEventsText{
  width: 55%;
  padding: 5px;
  font-weight: 200;
  font-size: 11px;
  float: left;
}

.activeEvent{
  cursor: pointer;
}

.activeEvent:hover{
  background: rgba(255, 255, 255, 0.15) !important;
}