
#auxiliaryPricing{
  background: rgb(0,37, 61);
  min-height: 1200px;
  width: 100%;
  float: left;
  padding-top:60px;
  padding-bottom:200px;
  color: #fff;
  font-weight: bold;
}

.itemPricingInput{
	border: 0.5px solid white;
  width: 28%;
  margin-right: 2%;
	margin-bottom: 10px;
	padding-left: 5px;
	padding-top: 6px;
	border-radius: 2px;
	margin-top: 0px;
	color: #191970 !important;
	height: 30px;
	font-size: 12px;
	float: left;
}
.itemPricingInputLong{
	border: 0.5px solid white;
  width: 78%;
  margin-right: 2%;
	margin-bottom: 10px;
	padding-left: 5px;
	padding-top: 6px;
	border-radius: 2px;
	margin-top: 0px;
	color: #191970 !important;
	height: 30px;
	font-size: 12px;
	float: left;
}
.highInput{
  height: 70px;;
}
.itemPricingLabal{
	width: 20%;
	margin-bottom: 10px;
	padding-left: 5px;
	padding-top: 6px;
	border-radius: 2px;
	margin-top: 0px;
	color: white !important;
	height: 30px;
	font-size: 12px;
	float: left;
}
