
#entity{
  background: rgb(0,37, 61);
  min-height: 1200px;
  width: 100%;
  float: left;
  padding-top:60px;
  padding-bottom:200px;
  color: #fff;
  font-weight: bold;
}

