.usersTitle{
  font-size: 11px;
  font-weight: 200;
  color: white;
  font-style: italic;
  width: 100%;
  float: left;
}

.userItem{
  width: 100%;
  float: left;
  background: rgba(255, 255, 255, 0.1);
  font-size: 11px;  border-top: 0.5px solid #00253D;  
  font-weight: 200;
  border-radius: 2px;
  color: white;
}

#usersLarge{
  width: 18%;
  float: left;
  padding-bottom: 5px;
  padding-top: 5px;
}

#usersMedium{
  width: 9.75%;
  float: left;
  padding-bottom: 5px;
  padding-top: 5px;
}

#usersSmall{
  width: 4%;
  float: left;
  padding-bottom: 5px;
  padding-top: 5px;
}

#usersList{
  width: 97%;
  margin-left: 1.5%;
  margin-top: 0px;
  float: left;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  #usersList{
    width: 98%;
    margin-left: 1%;
    margin-top: -10px;
  }
  #usersMedium{
    width: 20%;
  }
  #usersLarge{
    width: 40%;
  }
}