
.productDetailsInput{
	border: 0.5px solid white;
	width: 98%;
	margin-left: 1%;
	margin-bottom: 10px;
	padding-left: 5px;
	padding-top: 6px;
	border-radius: 2px;
	margin-top: 0px;
	color: #191970 !important;
	height: 30px;
	font-size: 12px;
	float: left;
}

#productAddFieldMedium{
  width: 28%;
  float: left;
}
#productAddField{
  width: 24%;
  float: left;
}
#productAddFieldLong{
  width: 44%;
  float: left;
}
#productAddFieldVeryLong{
  width: 72%;
  float: left;
}
#productStatus{
  width: 78%;
  padding-top: 10px;
	font-size: 12px;
	font-style: italic;
	padding-left: 5px;
	float: left;
}
#productStatusEdit{
	width: 22%;
  margin-top: 5px;
	float: left;
}
#pDetails{
  width: 24%;
	padding-top: 5px;
  float: left;
}
#pLabel{
	width: 76%;
	float: left;
	padding-top: 3px;
	padding-bottom: 10px;
	font-weight: normal;
	text-align: left;
	font-size: 12px;
	font-style: italic;
	padding-left: 5px;
}
#productData{
  width: 16%;
  margin-right: 1%;
	padding-top: 5px;
	padding-bottom: 10px;
  float: left;
}
#productLabel{
	width: 33%;
	float: left;
	padding-top: 10px;
	padding-bottom: 10px;
	font-weight: normal;
	text-align: left;
	font-size: 12px;
	font-style: italic;
	padding-left: 5px;
}
#productTitle{
  color: rgb(255,215,0);
  font-size: 20px;
  text-align: left;
  padding-bottom:15px;
  margin-bottom: 0px;  
}
.productFull{
	border: 0.5px solid transparent;
	width: 99.5%;
	margin-bottom: 10px;
	color: #191970;
  border-radius: 2px;
	padding-left: 5px;
	margin-top: 0px;
	margin-left: 0.25%;
	height: 60px;
	font-size: 12px;
	float: left;	
}
#formSectionTitle{
 	color: rgb(0,139,139);
	padding: 5px;
	padding-left: 10px;
	border-bottom: 0.5px solid rgb(0,139,139);
	margin-bottom: 10px;

}

#createProduct{
  background: rgb(255,215,0);
  border: 0.5px solid transparent;
	padding:5px;
  margin-left:2px;
  border-radius: 3px;
	width: 150px;
	margin-top: 15px;
	cursor: pointer
}

#productsExtraSmall2{
	width: 100%;
	float: left;
	text-align: left;
}
.productSet{
  background: rgba(255, 255, 255, 0.15) !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
}

#addProductTitle{
  color: white;
  font-size: 14px;
  padding-left: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 2px solid white
}

#productWarning{
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  width: 100%;
  float: left;
  color: red;
  text-align: left;
  padding-left: 0px;
}
#productWarningLower{
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  margin-top: 5px;
  width: 100%;
  float: left;
  color: red;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 0px;
}

.longLabel{
  width: 65% !important;
}
.shortDetail{
  width: 35% !important;
}
