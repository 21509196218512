.marker{
  background: #043544;
  width: 25px;
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  color: rgb(251, 251, 251)
}
.marker-light{
  border: #043544 1px solid;
  width: 25px;
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  color: #043544
}
.marker-home{
  background: #E3B731;
  width: 25px;
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  color: rgb(251, 251, 251)
}

#hitMapWrapper{
  width: 100%;
  float: left;
}

#assetMapZone{

  width: 95%;
  margin-left: 2.5%;
  float: left;
  border: 10px solid #26455A;
  border-radius: 2px;
  padding: 10px;
}
#hitMapWrapperTitle{
  text-align: left;
  padding: 5px;
  padding-left: 15px;
  color: #E3B731;
}
#assetMap{
  height: 75vh;
}
#assetListMap{
  height: 125vh;
}

#assetMapList{
  width: 100%;
  float: left;
  border: 10px solid #26455A;
  border-radius: 2px;
  padding: 10px;
}  
#fullMap{
  width: 97%;
  margin-left: 1.25%;
}

#assetMapPending{
  width: 100%;
  float: left;
  background: #26455A;
  padding-top: 150px;
  border-radius: 2px;
  font-size: 30px;
  min-height: 500px
}
  