#clientBackground{
  
  background: #00253D;
  width: 95%;
  float: left;
  padding-bottom:200px;
  min-height: 800px;
  text-align: center;
  color: #fff;
}

#clientProfile{
  background: rgba(255, 255, 255, 0.15);
  width: 94%;
  height: 330px;
  border-radius: 3px;
  font-size: 12px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}
.chooseAgent{
  margin-bottom: 25px !important;
  padding-top: 5px !important;
  font-size: 14px !important;
  width: 100%;
  color: rgb(255,215,0) !important;  
  float: left;
}
.aligned{
  margin-left: 5% !important;
}
#clientAdditionalInfo{
  background: rgba(255, 255, 255, 0.15);
  width: 94%;
  margin-left: 3%;
  border-radius: 3px;
  float: left;
  margin-top: 5px;
  font-size: 12px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

#clientProfileWrapper{
    width: 50%;
    float: left;
}

#clientContractsWrapper{
    width: 50%;
    float: left;

}
#clientContractsTitle{
  width: 98%;
  float: left;
  font-weight: normal;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.10);
  border-radius: 2px;
  color: #FFD700;
  text-align: left;
  font-size: 16px;
  font-style: italic;
  padding: 5px;
  margin-bottom: 5px;
  padding-top: 3px;
  padding-left: 15px;
}

#clientCreditsTitle{
  width: 93%;
  float: left;
  font-weight: normal;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.10);
  border-radius: 2px;
  color: #FFD700;
  text-align: left;
  font-size: 16px;
  font-style: italic;
  padding: 5px;
  margin-bottom: 5px;
  padding-top: 3px;
  padding-left: 15px;
}

#creditResultsCreate{
  width: 7%;
  float: left;
  font-weight: normal;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.10);
  border-radius: 2px;
  color: white;
  text-align: center;
  font-size: 14.5px;
  font-style: italic;
  padding: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

#creditResultsCreate:hover{
  box-shadow: 0 0 3px rgba(255, 255,255, 0.8);
}

#clientPictureWrapper{
    width: 100%;
    float: left;
    margin-bottom: 5px;
}
#clientPicture{
  width: 50%;
  float: left;
  text-align: left;
  margin-top: -5px;
  margin-bottom: 5px;
  height: 150px;
}
#clientCredit{
  width: 50%;
  float: left;
}

#clientCreditTitle{
  width: 50%;
  min-height: 22px;
  float: left;
  text-align: left;
  padding-left: 10px;
  font-weight: normal;
  font-size: 11px;
  font-style: italic;
}

#clientCreditScore{
  width: 50%;
  float: left;
  font-weight: normal;
  font-size: 11px;  
  text-align: right;
  min-height: 22px;
  color: white
}

#contractAdder{
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 50px;
  width: 48%;
  margin-right: 2%;
  float: left;
  height: 150px;
  cursor: pointer;
}
#contractAdder:hover{
  box-shadow: 0 0 3px rgba(255, 255,255,1);
}

#clientAction{
  width: 3%;
  margin-right:0px;
  padding-left:15px;
  float: left;
  cursor: pointer;
  text-align: center;
	color: rgb(255,215,0);
	margin-bottom: 0px;
	cursor: pointer;
}
#clientAction:hover{
  color: rgb(0,139,139);
}

.quickSearch{
  width: 10%;
  margin-left: 2.5%;
  float: left;
  color: white;
  text-align: center;
	margin-bottom: 0px;
	cursor: pointer;
}
.quickSearchInput{
  background: rgba(255, 255, 255, 0.2);
  color: white;
	border: 0.5px solid transparent;
  width: 87%;
  margin-top: 6px;
  float: left;
  font-size: 10.5px;
  padding: 5px;
  height: 25px;
  border-radius: 2px;
  text-align: left;
  font-weight: normal;
}

.searchAction{
  width: 3%;
  margin-right:0px;
  padding-left:15px;
  float: left;
  font-size: 14px;
  color: white;
  cursor: pointer;
  text-align: center;
	margin-bottom: 0px;
	cursor: pointer;
}
.searchAction:hover{
	color: #FFD700
}

#adminAction{
  color: white;
  position: absolute;
  left: 1.5%;
  top: 20px; 
  font-size: 24px;
  color: rgb(255,215,0);
	cursor: pointer;
}
#adminAction:hover{
	color: rgb(0,139,139);
}



#clientTitle{
  width: 25%; 
  margin-left: 0%;
  float:left;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: normal;
  padding-top: 15px;

}

#searchActionLine{
  width: 90%;
  margin-left: 2.5%;
  margin-top: 30px;
  margin-bottom: 15px;
  float: left
}
#clientActionLine{
  width: 90%;
  margin-left: 2.5%;
  margin-top: 25px;
  margin-bottom: 25px;
  float: left
}
#returnClient{
  cursor: pointer;  
  color: white;
  font-size: 18px;
  width: 4%;
  margin-left: 30%;
  float: left;
  z-index: 1;
}

#editClient{
  cursor: pointer;  
  color: white;
  font-size: 18px;
  width: 4%;
  float: left;
  z-index: 1;  
}
#returnClient:hover{
	color: rgb(255,215,0);
}
#editClient:hover{
	color: rgb(255,215,0);
}

#clientActionWrapper{
  width: 90%;
  text-align: left;
  margin-left: 3%;
  margin-top: 7.5px;
  float: left;
}

#contractActionWrapper{
  width: 90%;
  text-align: left;
  margin-left: 1.5%;
  margin-top: 30px;
  float: left;
}

.dateInput{
    height: 20px;
    margin-top: -2.5px;
    background: rgba(255, 255, 255, 0.01);
    color: white;
    font-size: 12px;
    padding-left: 0px;
    border:0.5px solid transparent
}

#clientSubMenu{ 
  line-height: 35px;
  margin-top: 75px;
  font-size:14px;
  width: 97%;
  float: left;
  margin-left: 1.5%;
  margin-bottom: 0px;
}

#clientSubMenu_item{
  background: rgba(255, 255, 255, 0.15);
  width: 10%;
  margin-right:0px;
  text-align: left;
  padding-left:10px;
  float: left;
  color: white;
  cursor: pointer;
}
#contractAnalyticsSubTitle{
  background: rgba(255, 255, 255, 0.15);
  width: 40%;
  margin-right:0px;
  text-align: left;
  padding-left:15px;
  font-style: italic;
  font-weight: normal;
  float: left;
  color: white;
}
.contractBack{
  width: 5%;
  margin-right:0px;
  text-align: center;
  font-style: italic;
  font-weight: normal;
  cursor: pointer;
  float: left;
  color: white;
}
.contractBackActive{
  width: 5%;
  margin-right:0px;
  text-align: center;
  font-style: italic;
  font-weight: normal;
  cursor: pointer;
  float: left;
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}
.contractBack:hover{
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}

#clientSubMenu_item:hover{
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}

.active_clientSubMenu{
  color: #00253D !important;
  background: rgb(255,215,0) !important;
}
#clientSubMenuSpacer{
  min-width: 24.5%;
  float: left;
}
#contractSubMenuSpacer{
  min-width: 27.5%; 
  float: left;
}


.clientNavigator{
  width: 100%;
  float: left;
  font-size: 11px !important;
  font-weight: bold;
  color: white;
}

#accept{
  background: rgb(0, 139, 139);
  width: 7.5%;
  margin-left: 5%;
  margin-right:0%;
  text-align: center;
  border-radius: 3px;
  font-size: 12px;
  float: left;
  color: white;
  cursor: pointer;
}
#accept:hover{
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}
#refuse{
  background: rgb(220,20,60);
  width: 7.25%;
  margin-right:0%;
  margin-left: 0.25%;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  float: left;
  color: white;
  cursor: pointer;
}
#refuse:hover{
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}

.active_clientRightSubMenu{
  color: #00253D !important;
  background: rgb(255,215,0) !important;
}

.creditInput{
  background: rgba(255, 255, 255, 0.05);
  border: 0.5px solid transparent;
  border-radius: 3px;
  color: white;
  margin-top: -5px;
  width: 40%;
  float: right;
  text-align: right;
  height: 24px;
  font-size: 11px

}

.cwarning{
  background: rgb(255,215,0) !important;
  color: #00253D !important;
  font-weight: bold !important;
}
.cdanger{
  background: rgb(220,20,60) !important;
  font-weight: bold !important;
  color: white !important;

}
.cneutral{
  background: rgba(255,255,255, 0.3) !important;
  color: white !important;
  font-weight: bold !important;

}
.cgreen{
  background: rgb(0,139,139) !important;
  color: white !important;
  font-weight: bold !important;
}

@media screen and (max-width: 768px) {
  #contractAnalyticsSubTitle{
    width: 90%;
    margin-top: -15px;
  }
  
  #adminAction{
    display: none
  }
  .contractBack{
    position: absolute;
    right: 2.5%;
    width: 10%;
    margin-top: -70px;
  }

  .contractBackActive{
    position: absolute;
    right: 2.5%;
    width: 10%;
    margin-top: -70px;
  }
  .cBL{
    right: 12.5%;
    width: 10%;
  }
  #clientBackground{
    width: 100%;
    margin-top: 15px;
    float: left;
  }
  #clientProfile{
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  #clientSubMenu{
    margin-top: 0px;
    width: 98%;
    margin-left: 2%;
  }
  
  #clientSubMenu_item{
    width: 22.5%;
    padding-left: 0px;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
  }
  #clientProfileWrapper{
    width: 100%;
  }
  
  #clientContractsWrapper{
    width: 100%;

  }
  #contractSubMenuSpacer{
    min-width: 0%;
  }
  #contractAdder{
    width: 45%;
    margin-left: 2.5%;
  }

  #clientSubMenuSpacer{
    min-width: 0%;
  }
  #accept{
    width: 30%;
    margin-right: 0%;
    margin-left: 11%;
    margin-top: 15px;
  }
  #refuse{
    background: rgb(220,20,60);
    width: 30%;
    margin-right: 11%;
    margin-left: 10%;
    margin-top: 15px;
  }

  #clientActionLine{

    display: none;
  }


}

#iW{
  width: 100%;
  float: left;
  text-align: left;
  margin-bottom: 1px;
  margin-top: 1px;
  padding:0px;

}

#iL{
  width: 34%;
  float: left;
  padding:3px;
  text-align: left;
  padding-left: 1px;
  padding-right: 0px;
  color: white;
  font-size: 11px;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 1px;
  margin-top: 1px;
}

.iD{
  float: left;
  background: rgba(255, 255, 255, 0.05);
  font-weight: bold;
  min-height: 22px;
  text-align: left; 
  font-size: 11px;
  border-radius: 2px;
  padding: 3px;
  padding-left: 7px;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 66%;
}

.countryCode{
  float: left;
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-weight: bold;  
  height: 22px;
  text-align: left;
  font-size: 11px;
  padding:3px;
  padding-left: 7px;
  padding-top:4px;
  border: 0px solid white;
  margin-right: 0.5%;
  width: 13.5%;
  border-radius: 2px;
  margin-bottom: 1px;
  margin-top: 1px;
}

.phone1{
  float: left; background: rgba(255, 255, 255, 0.05);
  color: white;
  font-weight: bold;
  height: 22px;
  text-align: left;
  border-radius: 2px;
  width: 25.25%;
  font-size: 11px;
  border: 0px solid white;
  padding:3px;
  padding-top:4px;
  margin-left: 0.5%;
  margin-right: 0.5%;
  padding-left: 7px;
  margin-bottom: 1px;
  margin-top: 1px;
}

.phone2{
  float: left; 
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-weight: bold;
  height: 22px;
  border: 0px solid white;
  border-radius: 2px;
  text-align: left;
  width: 25.25%;
  margin-left: 0.5%;
  font-size: 11px;
  padding:3px;
  padding-top:4px;
  padding-left: 7px;
  margin-bottom: 1px;
  margin-top: 1px;
}

#clientNumber{
  width: 25%;
  float: left;
  background: rgba(255, 255, 255, 0.95);
  color: #00253D;
  font-size: 11px;
  margin-bottom: 5px;
  border-radius: 2px;
  font-weight: bold;
  text-align: center;
  margin-top: 0px;
  padding:3px;
}

.clientNumberLabel{
  width: 25%;
  float: left;
  padding: 3px;
  padding-left: 10px;
  text-align: left;
  font-size: 11px;
  margin-bottom: 5px;
  margin-top: 0px;
  color: white;
  font-style: italic;
  font-weight: normal;
}

.I{ width: 27.25% !important; }

.H{ width: 32.50%; }

.L{ 
  margin-right: 1%;
}

.approveSelected{
	background: white !important;
	color: #00253D !important
}
.approveUnselected{
	background: rgba(255, 255, 255, 0.1) !important;
	color: white !important
}
#approveClientsModal{
  width: 100%;
  float: left;
  margin-top: -15px;
  margin-bottom: 15px;
}
#approveContractsQuestion{
  width: 68%;
  float: left;
  padding-top: 3px;
  font-style: italic;
  font-size: 12px;
}
#approveContracts{
  width: 15%;
  text-align: center;
  padding: 3px;
  font-size: 12px;
  margin-left: 1%;
  cursor: pointer;
  float: left
}

#approveContracts:hover{
  background: rgba(255, 255, 255, 0.05) !important;  
	color: rgb(255,215,0) !important;
}

.refusalNote{
  background: rgba(255, 255, 255, 0.05);
  color: white; 
  width: 100%;
  float: left;
  height: 100px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  padding: 5px;
  font-size: 12px;
}

#creditDecisionNote{
  width: 95%;
  padding: 5px;
  margin-left: 2.5%;
  float: left;
  margin-top: 10px;
  font-size: 12px;
  color: white;
  text-align: left;
  font-weight: normal;
}