.paymentsTitle{
  font-size: 11px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  width: 100%;
  float: left;
}

.paymentItem{
  width: 100%;
  float: left;
  font-weight: 200;
  font-size: 10px;
  background: rgba(255, 255, 255, 0.1);  border-top: 0.5px solid #00253D;
  border-radius: 2px;
  color: white;
}
.paymentItem:hover{
  background: rgba(255, 255, 255, 0.135);
}

.half{
  width: 31.5% !important;
  cursor: pointer;
  color: white !important;
  background: #1A3B51 !important;
  border: 0.5px solid #1A3B51 !important;
  font-style: italic
}
.marginleft{
  margin-left: 2% !important
}
#activePaymentOption{
  background: white !important;
  color: #00253D !important;
  font-weight: bold !important;
  font-style: normal
}
#paymentsContract{
  width: 10%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsReason{
  width: 15%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsTypeC{
  width: 11%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsRef{
  width: 10.5%;
  float: left;
  padding: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
}

#paymentsMessage{
  width: 7.5%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}

#paymentsLarge{
  width: 23.25%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#deletePayment{
  color: white;
  border: #DC143C 1px solid;
  text-align: center;
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  float: right;
  width: 4%;
}
#deletePayment:hover{
  color: white;
  background: #DC143C;
}
#paymentsReconciled{
  width: 7%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsNotReconciled{ 
  width: 7%;
  float: left;
  background: rgba(220,20,60, 1);
  cursor: pointer;
  font-style: italic;
  font-size: 11px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.selectedPayment{
  color: white !important;
  font-size: 10px !important;
  background: rgb(0,139,139) !important;
}

#paymentsMS{
  width: 19%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsAmount{
  width: 8%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsType{
  width: 5%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsXS{
  width: 3%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsXSS{
  width: 4.75%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsStatus{
  width: 7%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsStatusS{
  width: 11%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsMedium{
  width: 17%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsDate{
  width: 15%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsOrigin{
  width: 12%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsTransaction{
  width: 15%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#paymentsList{
  width: 97%;
  margin-left: 1.5%;
  margin-top: 5px;
  margin-right: 20%;
  float: left;
  font-size: 11px;
}

.forContract{
  width: 100% !important;
  margin-left: 0% !important;
  margin-top: 0px !important;
  float: left;
  font-size: 11px;
}

#paymentDetails{
  width: 100%;
  float: left;
  font-size: 11px;
  text-align: left;
  border-left: 0.5px solid rgba(255, 255, 255, 0.1);
  border-right: 0.5px solid rgba(255, 255, 255, 0.1);
  padding-top:5px;
}

#paymentTransactionLabel{
  width: 25%;
  float: left
}

#paymentTransaction{
  width: 75%;
  float: left;
  color: rgb(0, 140, 186)
}

#paymentCode{
  width: 65%;
  float: left;
  padding-top: 3px;
  padding-left: 3px;
  padding-bottom: 3px; 
  color: rgb(0, 140, 186)
}
#paymentCodeN{
  width: 85%;
  float: left;
  padding-top: 3px;
  padding-left: 5px;
  padding-bottom: 3px; 
  color: rgb(0, 140, 186) 
}
#paymentCodeLabelN{
  width: 15%;
  padding-left: 1px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: normal;
  font-style: italic;
  float: left
}
#paymentCodeLabel{
  width: 35%;
  padding-left: 1px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: normal;
  font-style: italic;
  float: left
}
#paymentCodeM{
  width: 77.5%;
  float: left;
  padding-top: 3px;
  padding-left: 3px;
  padding-bottom: 3px; 
  color: rgb(0, 140, 186)
}

#paymentCodeLabelM{
  width: 20%;
  padding-left: 1px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: normal;
  font-style: italic;
  float: left
}
#paymentCodeWrapper{
  width: 100%;
  float: left;
}
#paymentDetailsP{
  width: 40%;
  padding-left: 5px;
  padding: 5px;
  padding-right: 5px;
  float: left;
}
#paymentDetailsM{
  width: 60%;  
  padding: 5px;
  float: left;
}
#paymentDetailsN{
  width: 100%;  
  padding: 5px;
  float: left;
}


.paymentReversal{
  border: 0.5px solid rgb(220,20,60, 1);
  color: white;
  padding: 5px;
  cursor: pointer;
  float: left;
  width: 100px;
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 300
}

.cancel{
  background: rgb(220,20,60, 0.8);
  border: 0.5px solid rgb(220,20,60, 1);
  color: white;
  margin-left: 15px
}

.paymentReversal:hover{
  background: rgba(220,20,60, 1);

}
#reversalDetails{
  width: 100%;
  float: left;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 5px;
  margin-top: 5px;
}

#paymentRecapWrapper{
 width: 100%;
 float: left;
 margin-top: -15px;
 margin-bottom: 5px;
}
#paymentRecapTitle{
  font-style: italic;
  border-bottom: 1px solid white;
  margin-bottom: 3px;
  width: 75%;
  float: left;
  font-size: 14px;
  margin-right: 25%;
}
#paymentRecapLabel{
  font-style: italic;
  margin-top: 3px;
  background: rgba(255, 255, 255, 0.1);
  width: 25%;
  font-size: 11px;
  border-radius: 2px;
  padding: 5px;
  float: left;
}
#paymentRecapData{
  width: 50%;
  margin-right: 25%;
  text-align: right;
  margin-top: 3px;
  font-size: 11px;
  padding: 5px;
  float: left;
}

#paymentRecapList{
  width: 100%;
  margin-top: 10px;
  float: left;
}

#paymentRecapAgentName{
  width: 29%;
  margin-left: 1%;
  border-radius: 2px;
  text-align: center;
  float: left;
  padding: 5px;  
  font-weight: normal;
  font-size: 11px;
}
#paymentRecapAgentId{
  width: 19%;
  margin-left: 1%;
  text-align: center;
  float: left;
  padding: 5px;
  font-weight: normal;
  font-size: 11px;
}
#paymentRecapSpacer{
  font-style: italic;
  width: 25%;
  padding: 5px;
  font-size: 11px;
  float: left;
}
#paymentRecapAgentOwed{
  width: 24%;
  margin-left: 1%;
  text-align: right;
  font-style: italic;
  float: left;
  padding: 5px;
  font-weight: normal;
  font-size: 11px;
}
.agentHeader{
  font-style: italic !important;
  background: rgba(255, 255, 255, 0.1);
  text-align: center !important;
}
.paymentRecapNote{
  width: 74%;
  float: left;
  margin-top: 3px;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.01);
  color: white;
  font-size: 11px;
  padding: 5px;
  margin-left: 1%;
  height: 50px;
}
.paymentHigh{
  height: 50px;
}
@media screen and (max-width: 768px) {
  #paymentDetailsP{
    width: 100%;  
  }
  #paymentDetailsM{
    width: 100%;  
  }
  #paymentCodeLabel{
    width: 25%;  
  }
  #paymentCodeLabelM{
    width: 25%;  
  }
  #paymentCodeM{
    width: 75%;
  }

  #paymentsList{
    width: 98%;
    margin-left: 1%;
    margin-top: 15px;
    margin-right: 1%;
  }
  #paymentsReconciled{
    width: 17.5%;
  }
  #paymentsNotReconciled{
    width: 17.5%;
  }
  #paymentsTypeC{
    width: 20%;
  }
  #paymentsLarge{
    width: 50%;
  }
  #paymentsMedium{
    width: 20%;
  }
  #paymentsS{
    width: 15%;
  }
  #paymentsContract{
    width: 15%;
  }

  #paymentsDate{
    width: 20%;
  }
  #paymentsAmount{
    width: 20%;
  }
  #paymentsRef{
    padding-top: 0px;
    text-align: left;
    padding-left: 10px;
    width: 55%;
  }
  #paymentsTransaction{
    width: 45%;
    text-align: left;
    padding-left: 10px;
    
  }
  #paymentsMessage{
    padding-top: 0px;
    width: 15%;
  }
  #paymentsXS{
    width: 10%;
    padding-top: 0px;
  }

}

.alignLeft{
  text-align: left !important;
}
