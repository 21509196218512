
.rateDetailsInput{
	border: 0.5px solid white;
	background: white;
	color: #1A3B51;
	width: 25%;
  padding-top: 6px;
	padding-left: 5px;
	margin-top: 0px;
	margin-bottom: 10px;
	height: 30px;
	font-size: 12px;
	float: left;
}

.rateCreate{
  background: rgba(255, 255, 255, 0.15);
  width: 100%;
  float: left;
  font-size: 12px;
  padding: 15px;
  margin-bottom: 15px;
  margin-top: -15px;
}
.rateInput{
	font-size: 11px;
	padding: 3px;
  padding-left: 5px;
  padding-top: 5px;  cursor: pointer;
  color: white !important;
  background: #1A3B51 !important;
  border: 0.5px solid #1A3B51 !important;
  float: left;
	width: 25%;
	margin-bottom: 2px;
  margin-top: 2px;
	height: 25px;
}

#rateTitle{
  color: rgb(255,215,0);
  font-size: 20px;
  padding-left: 15px;
  text-align: left;
}

#newRateLabel{
  width: 25%;
  float: left;
  text-align: left;
  font-size: 14px;
	margin-bottom: 10px;
  padding-top: 5px;
  font-weight: bold
}

#newRateLabelRight{
  width: 20%;
  margin-right: 30%;
	margin-bottom: 15px;
  float: left;
  text-align: center;
  font-size: 14px;
  padding-top: 5px;
  font-weight: bold
}