.contractsTitle{
  font-size: 11px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  width: 100%;
  float: left;
}
#deleteWarning{
  color: red;
  padding-bottom: 10px;
}
.contractItem{
  width: 100%;
  float: left;
  font-weight: 200;
  background: rgba(255, 255, 255, 0.1);  border-top: 0.5px solid #00253D;
  border-radius: 2px;
  color: white;
  font-size: 11px;
}
.contractItem:hover{
  background: rgba(255, 255, 255, 0.135);
}
#dealWarning{
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  width: 100%;
  float: left;
  color: red;
  margin-top: 10px;
  text-align: left;
  padding: 15px; 
  padding-left: 0px;
}
#dealWarningLower{
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  width: 100%;
  float: left;
  color: red;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 0px;
}

.contractsSmall{
  width: 10%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.contractsNumber{
  width: 8%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.contractsDeal{
  width: 12%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.contractsEntity{
  width: 8%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

.contractsDate{
  width: 8%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

.contractsMedium{
  width: 13%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.contractsExtraSmall{
  width: 9%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.contractsSuperSmall{
  width: 6.8%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#contractsList{
  width: 97%;
  margin-left: 1.5%;
  margin-top: 5px;
  float: left;
}

#contractsSmallLight{
  width: 17%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#contractsMedLight{
  width: 24%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
#contractsExtraSmallLight{
  width: 8%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

.contractLabel{
  width: 100%;
  float: left;
  padding:3px;
  color: white;
  font-weight: normal;
  margin-bottom: 10px;
  margin-top: 10px;  
}

#contractEditWrapper{
  width: 100%;
  float: left;
  margin-top: 15px;
  margin-bottom: 10px;
}


@media screen and (max-width: 768px) {
  #contractsList{
    width: 98%;
    margin-left: 1%;
    margin-top: 15px;
  }
  .contractsSmall{
    width: 20%;
    font-weight: 200;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 10px;
  }
  .contractsNumber{
    width: 20%;
    font-weight: 200;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 10px;
  }
  .contractsDeal{
    width: 20%;
    font-weight: 200;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 10px;
  }
  .contractsMedium{
    width: 25%;
    font-weight: 200;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 10px;
  }
  .contractsExtraSmall{
    width: 10%;
    font-weight: 200;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 10px;
  }  
  .contractsSuperSmall{
    width: 13.5%;
    font-weight: 200;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 10px;
  }

  .contractsDate{
    width: 14%;
    float: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }

}