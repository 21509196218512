#main_footer {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: white;
  color: 00253D;

}


#lab_social_icon_footer {
  margin-top:35px;
}

#lab_social_icon_footer a {
  color: #fff;
  padding: 10px;
}

#referral_zone{
  cursor:pointer;
}

#contact_button {
  background:#fff  ;
  color: #00253D ;
  text-align: center;
  border-radius: 1px;
  border: 1px #fff solid;
  width: 60%;
  margin: 10px;
}

#link_button{
  color: #fff ;
  background: transparent ;
  border: 1px transparent solid;
  text-align: left;
  width: 100%;
  height: 25px; 
  cursor:pointer;
}
#link_button:hover{
  text-decoration: underline;
}


#contact_button:hover {
  background:rgba(252,203,57, 1)  ;
  border: 1px rgba(252,203,57, 1) solid;
  color: #fff
}

#lab_social_icon_footer .social:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}

#lab_social_icon_footer .social {
  -webkit-transform: scale(0.95);
  /* Browser Variations: */
  
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
/*
    Multicoloured Hover Variations
*/

#lab_social_icon_footer #social-fb:hover {
  color: #3B5998;
}

#lab_social_icon_footer #social-tw:hover {
  color: #4099FF;
}

#lab_social_icon_footer #social-gp:hover {
  color: #d34836;
}

#lab_social_icon_footer #social-em:hover {
  color: #f39c12;
}