#payrollsList{
  width: 97%;
  margin-left: 1.5%;
  margin-top: 5px;
  font-size: 12px;
  float: left;
}
  
.payrollItem{
  width: 100%;
  float: left;
  font-weight: normal;
  font-size: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-top: 0.5px solid #00253D;
  border-radius: 2px;
  color: white;
}
.payrollItem:hover{
  background: rgba(255, 255, 255, 0.15);
}

#payrollNumber{
  width: 8%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#payrollName{
  width: 10%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#payrollAmount{
  width: 10%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#payrollDate{
  width: 9%;
  float: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#payrollComment{
  width: 47%;
  float: left;
  text-align: left;
  padding-top: 7px;
  padding-bottom: 7px;
}
#payrollAction{
  width: 2%;
  float: right;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 7px;
}
#payrollPaid{
  width: 5%;
  float: right;
  cursor: pointer;
  padding-top: 7px;
  background: rgb(0,139,139);;
  padding-bottom: 7px;
}
#payrollSelectorWrapper{
  width: 50%; 
  float: right;
}
.payrollDateSelectorWrapper{
  width: 20%;
  float: right;
}
.payrollSelector{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
	border: 0.25px solid rgba(255, 255, 255, 0.2);;
  color: white;
  width: 35%;
  float: right;
  font-size: 11px;
  padding: 4px;
  height: 35px;
  text-align: left;
  font-weight: normal;
}
#payrollsTitle{
  width: 100%;
  float: left;
  margin-top: 7px;
  font-size: 11px;
  font-weight: bold;
  border-top: 0.5px solid #00253D;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background: rgba(255, 255, 255, 0.95);
  color: #00253D;
}

.payrollTotal{
  margin-left: 5%;
  width: 30%;
  margin-right: 5%;
  font-style: italic;
  font-weight: normal;
  padding-top: 5px;
  border-radius: 2px;
  float: left;  
}
.payrollLegend{
  margin-left: 27.5%;
  width: 10%;
  font-style: italic;
  font-weight: normal;
  padding-top: 5px;
  border-radius: 2px;
  float: left;
}
.payrollTotalLabel{

  width: 50%;
  text-align: left;
  padding-left: 10px;
  float: left;  
}
.payrollTotalDate{
  width: 50%;
  float: left;  
}
#payrollDownload{
  margin-left: 1%;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  float: left;
  color: white;
  cursor: pointer;
}
#payrollDownload:hover{

  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}
#markAsPaid{
  background: rgb(0, 139, 139);
  margin-left: 5%;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  float: left;
  color: white;
  cursor: pointer;
}
#markAsPaid:hover{
  color: rgb(255,215,0);
  background: rgba(255, 255, 255, 0.25)
}