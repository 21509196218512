.clientsTitle{

  font-size: 11px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  width: 100%;
  float: left;
}

.clientItem{
  width: 100%;
  float: left;
  font-weight: 300;
  background: rgba(255, 255, 255, 0.1);
  border-top: 0.5px solid #00253D;
  border-radius: 2px;
  color: white;
  font-size: 11px;
}
.clientItem:hover{
  background: rgba(255, 255, 255, 0.135);
}
.clientsSmall{
  width: 10%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.clientsMedium{
  width: 13%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.clientsEntity{
  width: 11%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;  
}
.clientsExtraSmall{
  width: 7%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.clientsTiny{
  width: 2%;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
}
.clientsList{
  width: 97%;
  margin-left: 1.5%;
  float: left;
}

@media screen and (max-width: 768px) {
  .clientsList{
    width: 98%;
    margin-left: 1%;
    margin-top: 15px
  }

  .clientsSmall{
    width: 20%;
    font-weight: 200;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 10px;
  }
  .clientsMedium{
    width: 24%;
    font-weight: 200;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 10px;
  }

  .clientsTiny{
    width: 5%;
    padding-left: 0px;
    text-align: center;
  }

}
