
.eventsTitle{
  width: 100%;
  float: left;
  font-size: 11px;
  font-weight: bold;
  border-top: 0.5px solid #00253D;
  background: rgba(255, 255, 255, 1);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  color: #00253D;
}

.eventItem{
  width: 100%;
  float: left;
  font-weight: normal;
  border-radius: 2px;
  font-size: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
  color: white;
}
.eventItem:hover{
  background: rgba(255, 255, 255, 0.135);
}

.eventDate{
  width: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left
}

.eventType{
  width: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left
}

.eventUdl{
  width: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left
}

.eventInfo{
  width: 20%;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left
}
.eventHolder{
  width: 15%;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left
}
.eventAuthor{
  width: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left
}
.eventNote{
  width: 20%;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left
}

@media screen and (max-width: 768px) {

  .eventItem{
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 30px;
  } 
}