#dealsTitle{
  line-height: 45px;
  margin-top: 15px;
  display: block;
  height: auto;
  overflow: auto;
  text-align: left;
  padding-left: 15px;
  font-weight: normal;
  color: white;
  font-size:24px;
  width: 90%;
  margin-left:5%;
  border-radius: 15px;
  margin-bottom: 15px;
}

#dealAction{
	position: absolute;
	width: 40px;
	text-align: center;
	color: rgb(255,215,0);
	padding-left: 20%;
  margin-top: -50px;
  font-size: 20px;
	cursor: pointer;
}
#dealAction:hover{
  color: rgb(0,139,139);
}
#dealSubTitle{
  border-bottom: 1.5px solid white;
  width: 100%;
  color: #FFD700;
  float: left;
  text-align: left;
  padding-bottom: 3px;
  font-size: 12px;
  padding-left: 2px;
  margin-bottom: 8px;
  margin-top: 5px;
}